import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, map, tap, retry } from 'rxjs/operators';
import { MatPaginator, MatSort } from '@angular/material';

import { environment } from '../../../../environments/environment';

import { dw_captura_pagoModel } from './dw_captura_pago.model';

@Injectable({ providedIn: 'root' })
export class dw_captura_pagoService {
  private dw_captura_pagoUrl = '';  // URL to web api
  private dw_formaPagoUrl = '';  // URL to web api

  constructor(private http: HttpClient) {
    this.dw_captura_pagoUrl = `${environment.dataServiceUrl}/Cartera/dw_captura_pago`;
    this.dw_formaPagoUrl = `${environment.dataServiceUrl}/Cartera/PagoCartera`;
  }

  getFormaPago(){
    const sUrl = `${this.dw_formaPagoUrl}`;

    return this.http.get<dw_captura_pagoModel>(sUrl).pipe(
      retry(3),
      tap(() => this.log('fetched dw_captura_pago')),
      catchError((error) => this.handleError('getdw_captura_pago', error))
    );
  }

  getdw_captura_pago(row: dw_captura_pagoModel): Observable<dw_captura_pagoModel> {
    const sUrl = `${this.dw_captura_pagoUrl}`;

    return this.http.get<dw_captura_pagoModel>(sUrl).pipe(
      retry(3),
      tap(() => this.log('fetched dw_captura_pago')),
      catchError((error) => this.handleError('getdw_captura_pago', error))
    );
  }

  getdw_captura_pagoAll(): Observable<any> {
    return this.http.get<any>(this.dw_captura_pagoUrl, {}).pipe(
      retry(3),
      tap(row => this.log('fetched dw_captura_pago')),
      catchError((error) => this.handleError('getdw_captura_pagoList', error))
    );
  }

  getdw_captura_pagoList(
    filter: {
      value: any,
      condition: string,
      column: string
    },
    paginator: MatPaginator,
    sort: MatSort,
    data: any): Observable<any> {

    let params = new HttpParams();
    if (filter.condition != '') {
      params = params.append('filtroValor', filter.value);
      params = params.append('filtroCondicion', filter.condition);
      params = params.append('filtroColumna', filter.column);
      params = params.append('filtroRelacion', 'and');
    }
    params = params.append('filtroValor', data.nroCredito);
    params = params.append('filtroCondicion', '=');
    params = params.append('filtroColumna', 'nro_credito');

    params = params.append('filtroValor', '1');
    params = params.append('filtroCondicion', '=');
    params = params.append('filtroColumna', 'cod_emp' );
    params = params.append('filtroRelacion', 'and');
    
    params = params.append('paginaTamano', paginator.pageSize.toString());
    params = params.append('paginaNumero', (paginator.pageIndex + 1).toString());
    params = params.append('ordenColumna', sort.active || 'fecha_pago');
    //params = params.append('ordenColumna', sort.active || 'nro_pagos');
    params = params.append('ordenTipo', sort.direction || 'desc');


    const sUrl = `${this.dw_captura_pagoUrl}/Pagina`;

    return this.http.get<any>(sUrl, {params}).pipe(
      retry(3),
      tap(row => this.log('fetched dw_captura_pago')),
      catchError((error) => this.handleError('getdw_captura_pagoList', error))
    );
  }

  adddw_captura_pago(row: dw_captura_pagoModel): Observable<dw_captura_pagoModel> {
    return this.http.post<dw_captura_pagoModel>(this.dw_captura_pagoUrl, [dw_captura_pagoModel.clone(row)]).pipe(
      retry(3),
      tap((rrow: dw_captura_pagoModel) => this.log(`added dw_captura_pago w/ id=`)),
      catchError((error) => this.handleError('adddw_captura_pago', error))
    );
  }

  updatedw_captura_pago(row: dw_captura_pagoModel, original: dw_captura_pagoModel): Observable<dw_captura_pagoModel> {
    return this.http.put<dw_captura_pagoModel>(this.dw_captura_pagoUrl, dw_captura_pagoModel.clone(row)).pipe(
      retry(3),
      tap(_ => this.log(`update dw_captura_pago id=`)),
      catchError((error) => this.handleError('updatedw_captura_pago', error))
    );
  }

  savedw_captura_pago(row: dw_captura_pagoModel, original: dw_captura_pagoModel): Observable<dw_captura_pagoModel> {
    if (row._estado === 'N') {
      return this.adddw_captura_pago(row);
    } else {
      return this.updatedw_captura_pago(row, original);
    }
  }

  deletedw_captura_pago(row: dw_captura_pagoModel): Observable<dw_captura_pagoModel> {
    const sUrl = `${this.dw_captura_pagoUrl}`;

    return this.http.delete(sUrl).pipe(
      retry(3),
      tap(_ => this.log(`filter dw_captura_pago id=`)),
      catchError((error) => this.handleError('deletedw_captura_pago', error))
    );
  }

  saveRowsdw_captura_pago(rows: Array<dw_captura_pagoModel>): Observable<any> {
    const _rows = rows.map((row) => dw_captura_pagoModel.clone(row));
    return this.http.post<any>(this.dw_captura_pagoUrl, _rows).pipe(
      retry(3),
      tap((rrows: dw_captura_pagoModel) => this.log(`pasted rows in dw_captura_pago `)),
      catchError((error) => this.handleError('adddw_captura_pago', error))
    );
  }

  filterDddwPagoCarteraNuevo(val: string): Observable<any> {
    const sUrl = `${environment.dataServiceUrl}/Cartera/dddw_pago_cartera_nuevo/Page`;

    const params: any = {
      filtroValor: val || '',
      filtroCondicion: 'like',
      filtroColumna: 'descripcion',
      paginaTamano: 10,
      paginaNumero: 1,
      ordenColumna: 'descripcion',
      ordenTipo: 'asc'
    };

    return this.http.get<any>(sUrl, { params: params }).pipe(
      retry(3),
      tap(_ => this.log(`filter dddw_pago_cartera_nuevo id=${val}`)),
      catchError((error) => this.handleError('filterdddw_pago_cartera_nuevo', error))
    );
  }

  getByIdDddwPagoCarteraNuevo(val: any): Observable<any> {
    const sUrl = `${environment.dataServiceUrl}/Cartera/dddw_pago_cartera_nuevo/${val}`;

    return this.http.get<any>(sUrl).pipe(
      retry(3),
      tap(_ => this.log(`getByIdDddwPagoCarteraNuevo id=${val}`)),
      catchError((error) => this.handleError('getByIdDddwPagoCarteraNuevo', error))
    );
  }

  getAllDddwPagoCarteraNuevo() {
    const sUrl = `${environment.dataServiceUrl}/Cartera/dddw_pago_cartera_nuevo/`;

    const params: any = {};

    return this.http.get<any>(sUrl, { params: params }).pipe(
      retry(3),
      tap(_ => this.log(`GetAll DddwPagoCarteraNuevo`)),
      catchError((error) => this.handleError('GetAllDddwPagoCarteraNuevo', error))
    );

  }


  filterDwDddwFacturacion(val: string): Observable<any> {
    const sUrl = `${environment.dataServiceUrl}/Cartera/dw_dddw_facturacion/Page`;

    const params: any = {
      filtroValor: val || '',
      filtroCondicion: 'like',
      filtroColumna: 'descripcion',
      paginaTamano: 10,
      paginaNumero: 1,
      ordenColumna: 'descripcion',
      ordenTipo: 'asc'
    };

    return this.http.get<any>(sUrl, { params: params }).pipe(
      retry(3),
      tap(_ => this.log(`filter dw_dddw_facturacion id=${val}`)),
      catchError((error) => this.handleError('filterdw_dddw_facturacion', error))
    );
  }

  getByIdDwDddwFacturacion(val: any): Observable<any> {
    const sUrl = `${environment.dataServiceUrl}/Cartera/dw_dddw_facturacion/${val}`;

    return this.http.get<any>(sUrl).pipe(
      retry(3),
      tap(_ => this.log(`getByIdDwDddwFacturacion id=${val}`)),
      catchError((error) => this.handleError('getByIdDwDddwFacturacion', error))
    );
  }

  getAllDwDddwFacturacion() {
    const sUrl = `${environment.dataServiceUrl}/Cartera/dw_dddw_facturacion/`;

    const params: any = {};

    return this.http.get<any>(sUrl, { params: params }).pipe(
      retry(3),
      tap(_ => this.log(`GetAll DwDddwFacturacion`)),
      catchError((error) => this.handleError('GetAllDwDddwFacturacion', error))
    );

  }


  private handleError(operation = 'operation', result?: any) {

    // TODO: send the error to remote logging infrastructure
    console.error(result.error); // log to console instead

    // TODO: better job of transforming error for user consumption
    this.log(`${operation} failed: ${result.message}`);

    // Let the app keep running by returning an empty result.
    return of(result);
  }

  /** Log a dw_captura_pagoService message with the MessageService */
  private log(message: string) {
    // this.messageService.add(`dw_captura_pagoService: ${message}`);
    console.log(`dw_captura_pagoService: ${message}`);
  }

}
