import { ControlComponent } from './control.component'

export class MenuComponent extends ControlComponent {
    public TypeOf: string = "menu";
    shortcut: number;
    hide(): any {
        throw new Error("Method not implemented.");
    }
    menuimage: string;
    popmenu(arg0: any, arg1: any): any {
        throw new Error("Method not implemented.");
    }

    public item: MenuComponent[];
    public microhelp: string = "";
    public checked: boolean = false;
    //public text: string = "";
    public toolbaritemname: string = "";
    public toolbaritemorder: number = 0;
    public toolbaritemspace: number = 0;
    public toolbaritemtext: string = "";
    public toolbaritemvisible: boolean = true;

    constructor() {
        super();
        this.className = 'menu';
    }
}
