import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, ValidationErrors } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar, MatBottomSheet, MatDialog } from '@angular/material';
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { of } from 'rxjs';
import { delay, startWith, switchMap } from 'rxjs/operators';
import { AwsComponent } from 'src/app/components/aws.component';
import { TipoDocumentoPdfVisual } from 'src/app/components/tipodocumentopdf.visual';
import { DocumentoDesembolsoService } from './documentodesembolso.service';

declare var appComponent: any;
@Component({
  templateUrl: './viewdoctos.dialog.html',
  providers: [DocumentoDesembolsoService]
})
export class DocumentosDownDialog {

  @BlockUI() blockUI: NgBlockUI;

  selectedAsignarAnalista: [];
  originalAsignarAnalista: [];

  filteredArchivos: Array<any> = [];

  documentosForm: FormGroup;

  _proc: boolean = false;
  _status: boolean = false;
  _control: boolean = true;
  resultError: string = null;

  codigoCarpeta: number;
  lineaCredito: number;
  tipoTramite: any;
  proceso: string;
  urlRuta: string;
  despachos: boolean = false;

  constructor(private builder: FormBuilder,
    private awsComponent: AwsComponent,
    private _snackBar: MatSnackBar,
    public dialog: MatDialog,
    private DocumentosService: DocumentoDesembolsoService,
    public dialogRef: MatDialogRef<DocumentosDownDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) {

    this.codigoCarpeta = data.codigoCarpeta;
    this.lineaCredito = data.lineaCredito;
    this.despachos = data.despachos;
  }

  ngOnInit() {
    this.documentosForm = this.builder.group({
      'secuenciaArchivo': ['', Validators.required],
      'numeroCarpeta': [this.codigoCarpeta],
      'rutaAws': ['', Validators.required],
      'codigoEmpresa': [Number(appComponent.usuario.mpr), Validators.required],
      'lineaCredito': [this.lineaCredito, Validators.required],
      'archivo_dddwArchivo': ['', Validators.required],
    }, {
      validators: (formGroup: FormGroup): ValidationErrors | null => {
        const data = formGroup.getRawValue();
        let validationErrors = {
        };
        return validationErrors;
      }
    });

    this.documentosForm.valueChanges.subscribe((data) => {
      this.documentosForm.patchValue({
      }, { emitEvent: false, onlySelf: true });
    });

    this.documentosForm.controls.archivo_dddwArchivo.valueChanges
      .pipe(
        startWith(''),
        switchMap((data) => {
          if (this._control || data === '') {
            this._control = false;
            return of([]);
          } else {
            return this.DocumentosService.getDocumentoTramiteList(data, this.codigoCarpeta, this.lineaCredito, Number(appComponent.usuario.mpr));
          };
        })
      ).subscribe((data) => data.rows ? this.filteredArchivos = data.rows : this.filteredArchivos = []);
  }

  onDownload(row: any) {
    let archivo;
    let secuenciaArchivo;
    let urlOutput;

    if (row.secuenciaDocumento != undefined) {
      secuenciaArchivo = row.codigoEmpresa + "" + row.lineaCredito + "" + "" + row.secuenciaDocumento;
    } else {
      secuenciaArchivo = row.codigoEmpresa + "" + row.lineaCredito + "" + "" + row.secuenciaArchivo ;
    };

    // if (row.rutaAws.includes('output')) {
    //   this.proceso = `dialogo-procesos.generacion.portal-agentes.pdf.output.${row.numeroCarpeta}`;

    // } else if (row.rutaAws.includes('firma')) {
    //   this.proceso = `dialogo-procesos.portal-agentes.firmas.input.${secuenciaArchivo}`;

    // } else {
    //   this.proceso = `dialogo-procesos.portal-agentes.documento-captura.input.${secuenciaArchivo}`;
    // }
    let datos = row.rutaAws.split('/');
    archivo = "/"+datos[datos.length - 1];

    let proc: any = `dialogo-procesos/${row.rutaAws}`;
    proc = proc.replace(archivo, "");
    proc = proc.replaceAll("/", ".");



    this.blockUI.start("Descargando..."); // Start blocking
    this.awsComponent.getUrlDescarga(proc, archivo).subscribe((data: any) => {
      this._status = !!data.error;
      this.resultError = null;

      if (!this._status && data.url !== undefined && data.url !== null) {
        this._status = !!data.error;
        this.resultError = null;

        this.awsComponent.download_file(data.url).subscribe((file: Blob) => {
          var url = window.URL.createObjectURL(file);
          var anchor = document.createElement("a");
          anchor.download = row.rutaAws;
          anchor.href = url;
          anchor.click();
          this.blockUI.stop();
        });

      } else {
        this.blockUI.stop();
        this.resultError = data.message.mensaje || data.message.error || data.message;
        this.openNotificationDanger(this.resultError)
      };
    });
  }

  openNotificationDanger(message: string, action?: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
      panelClass: 'dangerSnackBar',
    });
  }

  onSelectArchivo_DddwArchivo(opt: any) {
    this.documentosForm.patchValue({
      secuenciaArchivo: opt.secuenciaDocumento,
      archivo_dddwArchivo: opt.nombreDocumento,
      rutaAws: opt.id_ruta_aws
    });
  }

  onDescargarExpediente() {
    this.blockUI.start("Generando..."); // Start blocking

    this.DocumentosService.downloadExpediente(this.codigoCarpeta).subscribe(data => {
      this._status = !!data.error;
      this.resultError = null;
      if (!this._status) {
        if (data.idRutaAws != "") {
          this.blockUI.stop();
          this.verExpedientillo(data.idRutaAws);
        }
      } else {
        this.blockUI.stop();
        this.resultError = data.message.mensaje;
        this.openNotificationDanger(this.resultError)
      }
    })
  }

  verExpedientillo(idRuta: any) {
    const dialogRef = this.dialog.open(TipoDocumentoPdfVisual, {
      data: {
        selectedTipoDocumento: {
          idRutaAws: idRuta,
          secuencia: this.codigoCarpeta
        }
      }
    });
  }

}
