import { Component, ComponentFactoryResolver, Injector } from '@angular/core';

import { DataWindowComponent } from './datawindow.component';

@Component({
    // tslint:disable-next-line: component-selector
    selector: 'datawindowchild',
    templateUrl: './datawindowchild.component.html'
})
export class DataWindowChildComponent extends DataWindowComponent {
    TypeOf = 'datawindowchild';
    constructor(cfr: ComponentFactoryResolver) {
        super(cfr);
    }
}
