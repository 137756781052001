import { Component, ViewChild, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { ControlComponent } from './control.component';

export const EDITMASK_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => EditMaskComponent),
    multi: true
};

@Component({
    selector: 'editmask',
    template: '<input #edmask style="width: 100%; height: 100%" (change)="onChange"/>',
    providers: [
        EDITMASK_VALUE_ACCESSOR
    ]
})
export class EditMaskComponent extends ControlComponent implements ControlValueAccessor {
    //public text: string = "";
    public TypeOf: string = "editmask";

    propagateChange = (_: any) => { };
    propagateTouched = () => { };

    @ViewChild('edmask', {static: true}) edmask: any; 

    onChange() {
        this.propagateChange(this.edmask.nativeElement.value);
    }

    writeValue(obj: any): void {
        this.edmask.nativeElement.value = obj;
    }
    registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }
    registerOnTouched(fn: any): void {
        this.propagateTouched = fn;
    }
    setDisabledState?(isDisabled: boolean): void {
        this.edmask.nativeElement.disabled = isDisabled;
    }
    
}
