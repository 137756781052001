import { Component, AfterViewInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { TableService } from './table.service';
import Utils from '../../services/utilidades';
import { MatSort } from '@angular/material';

declare var CONDITIONS_LIST: any;
declare var CONDITIONS_LIST_NUMBER: any;

@Component({
  selector: 'table-component',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.css'],
  providers: [TableService]
})
export class TableComponent implements AfterViewInit {

  @Output() onApplyFilter = new EventEmitter<any>();
  @Output() onClearColumn = new EventEmitter<any>();
  @Output() onSelect = new EventEmitter<any>();
  @Output() onSelectAndEdit = new EventEmitter<any>();
  @Output() matSortEvent = new EventEmitter<any>();
  @Output() cambioCheckAll = new EventEmitter<any>();
  @Output() cambioCheck = new EventEmitter<any>();
  @Input() set setRows(val: any) {
    this.rows = [...val];
  }
  @Input() columns: any[];
  @Input() tableParams: any;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  utils = Utils;
  public conditionsList = CONDITIONS_LIST;
  public conditionsListNumber = CONDITIONS_LIST_NUMBER;
  public searchValue: any = {};
  public searchCondition: any = {};
  public _pageSize = 10;
  originals: any[] = [];
  selectedRow: any;
  selectedIndex = 0;
  originalRow: any;
  displayedColumns;
  rows: any;
  filter = {
    column: '',
    condition: '',
    value: ''
  };
  resultsLength = 0;
  isLoadingResults = false;
  isRateLimitReached = false;
  _proc = false;
  _status = false;
  resultError: string = null;
  check = {
    value: false
  };

  constructor(private tableService: TableService) { }

  ngAfterViewInit() {
    this.displayedColumns = this.columns.map(c => c.columnDef);
  }
  onSelectFunc(event: Event, row: any, index: number) {
    this.selectedRow = row;
    this.selectedIndex = index;
    const rst = { 'event': event, 'row': row, 'index': index };
    this.onSelect.emit(rst);
  }
  onSelectAndEditFunc(event, row: any, index: number) {
    this.selectedRow = row;
    this.selectedIndex = index;
    const rst = { 'event': event, 'row': row, 'index': index };
    this.onSelectAndEdit.emit(rst);
  }
  onApplyFilterFunc(e: Event, columnDB: string, columnModel: string) {
    const rst = { 'event': e, 'columnDB': columnDB, 'condition': this.searchCondition[columnModel], 'value': this.searchValue[columnDB] };
    this.onApplyFilter.emit(rst);
  }
  onClearColumnFunc(columnModel: string) {
    this.onClearColumn.emit(columnModel);
  }

  sortChange() {
    const result = this.columns.filter(rows => rows.columnDef === this.sort.active);
    const newSort = new MatSort;
    newSort.active = result[0].columnDefSnake;
    newSort.direction = this.sort.direction;
    this.matSortEvent.emit(newSort);
  }

  cambioCheckFunc(row) {
    this.cambioCheck.emit(row);
  }

  cambioCheckAllFunc() {
    this.cambioCheckAll.emit(this.check.value);
  }

  onCuotaVencida(row) {
    const Today = new Date();
    const fecCobro = new Date(row.fechaCobro);
    if (row.saldoCapital > 0 && fecCobro < Today) {
      return true;
    } else {
      return false;
    }
  }

}
