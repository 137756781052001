import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatDialog, MatSnackBar, MatBottomSheet } from '@angular/material';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

import { ConfirmarQuebrantoService } from './confirmarquebranto.service';

@Component({
    selector: 'confirmar-quebranto',
    templateUrl: './confirmarquebranto.html',
    styleUrls: ['./confirmarquebranto.css'],
    providers: [ConfirmarQuebrantoService]
})
export class ConfirmarQuebranto {

    @BlockUI() blockUI: NgBlockUI;

    info: string;
    resultado: string;
    _proc = false;

    constructor(public dialog: MatDialog,
                private route: ActivatedRoute,
                private _snackBar: MatSnackBar,
                private servicio: ConfirmarQuebrantoService) {
        
        this.resultado = "...";
    }

    ngOnInit() {
        this.resultado = "Realizando Accion para la tasa Especial...";
        this.route.queryParamMap.subscribe((params: any) => {
            this.info = params.params.info;
            sessionStorage.setItem('token', this.info);
            this.servicio.confirmar(this.info).subscribe((data: any) => {
                if (data === true) {
                    this.resultado = "Proceso Finalizado Correctamente.";
                } else {
                    this.resultado = "Proceso Realizado Anteriormente";
                };
            });
        });
    }

    openNotificationDanger(message: string, action?: string) {
        this._snackBar.open(message, action, {
            duration: 2000,
            panelClass: 'dangerSnackBar',
        });
    }
}
