import { GestionAvanceProcesosReactiveDialog } from './components/gestionavanceprocesosreactive/gestionavanceprocesosreactive.dialog';
import { DetallesPagosDialog } from './components/detallespagos/detallespagos.dialog';
import { ProcesosMultiplesReloadedTable } from './components/procesosmultiplesreloaded/procesosmultiplesreloaded.table';
import { ProcesosMultiplesReloadedDialog } from './components/procesosmultiplesreloaded/procesosmultiplesreloaded.dialog';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from './app.shared.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { LoginComponent } from './login/login.component';
import { BlockUIModule } from 'ng-block-ui';
import { CurrencyPipe } from '@angular/common';
import { CambiarClaveDialog } from './login/cambiarclave/cambiarclave.dialog';
import { LoginDialog } from './login/logindialog/logindialog.dialog';
import { SideNavMenuComponent } from './nav-menu/side-nav/side-nav-menu.component';
import { MatDialogRef, MAT_DIALOG_DATA, MatPaginatorIntl } from '@angular/material';
import { NotificacionDialog } from './mensajeria/gr-gestion/notificacion/notificacion.dialog';
import { notificationSocketComponent } from './components/notification-socket/notification-socket.component';
import { MAT_DATE_LOCALE } from '@angular/material';
import { RecuperarAccesoDialog } from './login/recuperaracceso/recuperaracceso.dialog';
import { RecaptchaModule } from 'ng-recaptcha';
import { RecuperarAccesoComponent } from './mantenimiento/gr-autorizacion/recuperaracceso/recuperaracceso-component';
import { ConfirmarQuebranto } from './workflow/gr-procesos/confirmarquebranto/confirmarquebranto-component';
import { MatPaginatorI18nService } from './components/paginator.i18n.provider';
import { ProcesosAutomaticosTable } from './nav-menu/procesosautomaticos/procesosautomaticos.table';
import { GestionAvanceProcesosTable } from './components/gestionavanceprocesos/gestionavanceprocesos.table';
import { GestionAvanceProcesosDialog } from './components/gestionavanceprocesos/gestionavanceprocesos.dialog';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { GestionAvanceProcesosMultiplesTable } from './components/gestionavanceprocesosmultiples/gestionavanceprocesosmultiples.table';
import { ChartsModule } from 'ng2-charts';
import { ConfirmarAcuerdoPago } from './cobranza/gr-gestion/confirmaracuerdopago/confirmaracuerdopago-component';
import { DetallesPagosTable } from './components/detallespagos/detallespagos.table';
import { ValidaOtpComponent } from './workflow/gr-administracion/validaotp/validaotp.component';
import { MfaDialog } from './login/mfa/mfa.dialog';
import { GestionAvanceProcesosReactiveTable } from './components/gestionavanceprocesosreactive/gestionavanceprocesosreactive.table';
import { MiInformacionDialog } from './nav-menu/miinformacion/miinformacion.dialog';


// import { MatVideoModule } from 'mat-video';

@NgModule({
  declarations: [
    AppComponent,   
    NavMenuComponent,
    LoginComponent,
    CambiarClaveDialog,
    LoginDialog,
    SideNavMenuComponent,
    NotificacionDialog,
    notificationSocketComponent,
    RecuperarAccesoDialog,
    RecuperarAccesoComponent,
    ConfirmarQuebranto,
    ProcesosAutomaticosTable,
    GestionAvanceProcesosTable,
    GestionAvanceProcesosDialog,
    GestionAvanceProcesosMultiplesTable,
    ProcesosMultiplesReloadedDialog,
    ProcesosMultiplesReloadedTable,
    DetallesPagosTable,
    DetallesPagosDialog,
    ConfirmarAcuerdoPago,
    ValidaOtpComponent,
    GestionAvanceProcesosReactiveTable,
    GestionAvanceProcesosReactiveDialog,
    MfaDialog,
    MiInformacionDialog
  ],
  entryComponents: [
    MiInformacionDialog,
    MfaDialog,
    CambiarClaveDialog,
    LoginDialog,
    NotificacionDialog,
    notificationSocketComponent,
    RecuperarAccesoDialog,
    ConfirmarQuebranto,
    ConfirmarAcuerdoPago,
    ProcesosAutomaticosTable,
    GestionAvanceProcesosTable,
    GestionAvanceProcesosDialog,
    GestionAvanceProcesosMultiplesTable,
    ProcesosMultiplesReloadedDialog,
    ProcesosMultiplesReloadedTable,
    DetallesPagosTable,
    DetallesPagosDialog,
    ValidaOtpComponent,
    GestionAvanceProcesosReactiveTable,
    GestionAvanceProcesosReactiveDialog
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    NgCircleProgressModule.forRoot({}),
    BlockUIModule.forRoot(),
    BrowserAnimationsModule,
    // MatVideoModule,
    HttpClientModule,
    AppRoutingModule,
    RecaptchaModule,
    ChartsModule,
    SharedModule.forRoot()
  ],
  bootstrap: [AppComponent],
  providers: [
    CurrencyPipe,
    SideNavMenuComponent,
    notificationSocketComponent,
    { provide: MAT_DATE_LOCALE, useValue: 'es-ES' },
    { provide: MatDialogRef, useValue: {} },
    { provide: MAT_DIALOG_DATA, useValue: [] },
    { provide: MatPaginatorIntl, useClass: MatPaginatorI18nService },
  ]
})
export class AppModule {
}
