import { Component } from '@angular/core';
import { ControlComponent } from './control.component';

@Component({
    selector: 'radiobutton',
    template: './radiobutton.component.html'
})
export class RadioButtonComponent extends ControlComponent {
    public TypeOf: string = "radiobutton";
    //text: string;
    checked: boolean;

}
