import {decla_insolvenciaPagosModel} from './decla_insolvencia_pagos.model';
import {decla_insolvenciaCuotasModel} from './decla_insolvencia_cuotas.model';
import {decla_insolvenciadescuentModel} from './decla_insolvencia_descuent.model';

export class simulacion_decla_insolvenciaModel {
  public ldbNroCredito: number;
  public fechaCancelacion: Date;
  public fechaFallecido: Date;
  public indPagoOrg: Number;
  public lsMotivo: string;
  public cuotas: Array<decla_insolvenciaCuotasModel>;
  public pago: Array<decla_insolvenciaPagosModel>;
  public descuentos:Array<decla_insolvenciadescuentModel>;
  public _secuencia: number;
  public _estado: string = 'N';
  public _id: string;
  public _v: number;
  public _relaciones: any;
  _proc: boolean = false;
  _status: boolean = false;
  resultError: string = null;

  constructor(json: any = null) {
    this._relaciones = {};
    if (json !== null) {
      this.ldbNroCredito = json.ldbNroCredito;
      this.fechaCancelacion = json.fechaCancelacion;
      this.fechaFallecido = json.fechaFallecido;
      this.indPagoOrg = json.tipoDecla;
      this.lsMotivo = json.lsMotivo;
      this.cuotas = json.cuotas;
      this.pago = json.pago;
      this.descuentos = json.descuentos;
    }
  }

  static clone(row: simulacion_decla_insolvenciaModel): simulacion_decla_insolvenciaModel {
    const rowCloned = Object.assign({}, row);
    delete rowCloned._secuencia;
    delete rowCloned._estado;
    delete rowCloned._id;
    delete rowCloned._v;
    delete rowCloned._relaciones;
    return rowCloned;
  }

  toClipboard(): string {
    let result = '';
    result += `\t${this.ldbNroCredito}`;
    result += `\t${this.fechaCancelacion}`;
    result += `\t${this.fechaFallecido}`;
    result += `\t${this.indPagoOrg}`;
    result += `\t${this.lsMotivo}`;
    return result.substring(1);
  }

  fromClipboard(value: string): simulacion_decla_insolvenciaModel {
    const result = value.split('\t');
    this.ldbNroCredito = Number(<any>(result[0]));
    this.fechaCancelacion = <Date>(<any>(result[1]));
    this.indPagoOrg = Number(<any>(result[2]));
    this.lsMotivo = String(<any>(result[3]));
    this.fechaFallecido = <Date>(<any>(result[4]));
    return this;
  }


}
