import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class NotificationComunicationService {
    private subject = new Subject<any>();
    alertNewChange() {
        this.subject.next(true);
    }
    getChanges(): Observable<any> {
        return this.subject.asObservable();
    }
}