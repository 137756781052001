import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, retry, tap} from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
const httpOptions = {
  observe: null,
  params: null,
  headers: new HttpHeaders({
    'Content-Type': 'application/json'  ,
    'username': '',
    'passord': '',
    'passwordnew': ''
  })
};
@Injectable({ providedIn: 'root' })
export class RecuperarAccesoService {
    private url = '';  // URL to web api
    constructor(private http: HttpClient) {
        this.url = `${environment.dataServiceUrl}/Identity/Autenticacion/Recuperacion/Finalizar`;
    }
    cambioClave(row: any): Observable<HttpResponse<any>> {
      httpOptions.headers = new HttpHeaders({
        'Content-Type': 'application/json'  ,
        'subject': row.subject ? row.subject : '',
        'password': row.passwordnew ? row.passwordnew : '',
        'passwordnew': row.confirmpasswordnew ? row.confirmpasswordnew : ''
      });
      httpOptions.observe = 'response';
      return this.http.post<any>(this.url, {}, httpOptions).pipe(
          retry(3),
          tap((resp: HttpResponse<any>) => {
            this.log(`login w/ id=${resp.body.username}`);
            return resp;
        }),
          catchError((error) => this.handleError('login', error))
      );
    }
    private handleError(operation = 'operation', result?: any) {

        // TODO: send the error to remote logging infrastructure
        console.error(result.error); // log to console instead

        // TODO: better job of transforming error for user consumption
        this.log(`${operation} failed: ${result.message}`);

        // Let the app keep running by returning an empty result.
        return of(result);
    }
    /** Log a dw_wfc_carpeta_maestro_detalleService message with the MessageService */
    private log(message: string) {
        // this.messageService.add(`dw_wfc_carpeta_maestro_detalleService: ${message}`);
        console.log(`recuperarAccesoService: ${message}`);
    }

}
