import { Component, Inject, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatBottomSheet } from '@angular/material';
import { AlertasConfirmComponent } from 'src/app/components/alertasConfirm.component';
import { AwsComponent } from 'src/app/components/aws.component';
import { DocumentoRegeneradoCarteraService } from './documentoregeneradocartera.service';
//import { TipoDocumentoOriginacionModel } from './tipodocumentooriginacion.model';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/internal/operators/map';
import { DocumentoRegeneradoCarteraModel } from './documentoregeneradocartera.model';

@Component({
	templateUrl: './tipodocumentoregeneradocartera.visual.html',
	styleUrls: ['./documentoregeneradocartera.table.css'],
})
export class DocumentoRegeneradoCarteraVisual {
	//selectedTipoDocumento: TipoDocumentoOriginacionModel;
	tipoImpresion: number;
	tipoProceso: number;

	selectedTipoDocumento = new DocumentoRegeneradoCarteraModel();

	impresionDocumentosForm: FormGroup;
	isLoadingResults = true;
	rutaAws: string;

	_proc: boolean = false;
	_status: boolean = false;
	resultError: string = null;

	datos: any = {};

	@ViewChild('pdfViewerAutoLoad', { static: false }) pdfViewerAutoLoad;

	constructor(private awsComponent: AwsComponent,
		private http: HttpClient,
		private _bottomSheet: MatBottomSheet,
		private DocumentoRegeneradoCarteraService: DocumentoRegeneradoCarteraService,
		public dialogRef: MatDialogRef<DocumentoRegeneradoCarteraVisual>,
		@Inject(MAT_DIALOG_DATA) public data: any) {
		this.datos = data.selected;
	}

	ngOnInit() {


          let proc: any = `dialogo-procesos/${this.datos.rutaAws}`;
          let archivo = `/${this.datos.nombreAws}`;
          proc = proc.replace(archivo, "");
          proc = proc.replaceAll("/", ".");
          this.isLoadingResults = true;
					this.awsComponent.downloadFile(proc, archivo)
						.subscribe((data: any) => {
							this.downloadFile(data.url).subscribe((resp: any) => {
								this.pdfViewerAutoLoad.pdfSrc = resp;
								this.pdfViewerAutoLoad.refresh();
								this.isLoadingResults = false;
							});
						});



	}

	private downloadFile(url: string): any {
		return this.http.get(url, { responseType: 'blob' })
			.pipe(
				map((result: any) => {
					return result;
				})
			)
	}
}
