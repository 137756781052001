import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, tap, retry } from 'rxjs/operators';

import { environment } from '../environments/environment';

@Injectable({ providedIn: 'root' })
export class AppService {
    private identityRolUrl = '';  // URL to web api
    private estructuraUrl = '';  // URL to web api

    constructor(private http: HttpClient) {
        this.identityRolUrl = `${environment.dataServiceUrl}/Identity/Rol`;
        this.estructuraUrl = `${environment.dataServiceUrl}/Parametros/Asesor`;
    }


    getByIdIdentityRol( val: any): Observable<any> {
        const sUrl = `${this.identityRolUrl}/${val}`;
        return this.http.get<any>(sUrl).pipe(
            tap(_ => this.log(`getByIdDddwTidentityRol id=${val}`)),
            catchError((error) => this.handleError('getByIdDddwTIdentityRol', error))
        );
    }

    getEstructura(codAsesor: number){
        const sUrl = `${this.estructuraUrl}/Estructura/${codAsesor}`

        return this.http.get<any>(sUrl, {}).pipe(
          tap(() => this.log('getByIdEstructura')),
          catchError((error) => this.handleError('getByIdEstructura', error))
        );      
    }

    getAsesor(codAsesor: number){
        const sUrl = `${this.estructuraUrl}/${codAsesor}`

        return this.http.get<any>(sUrl).pipe(
          tap(() => this.log('getByIdNombreAsesor')),
          catchError((error) => this.handleError('getByIdNombreAsesor', error))
        );      
    }
    
    private handleError(operation = 'operation', result?: any) {

          // TODO: send the error to remote logging infrastructure
          console.error(result.error); // log to console instead

          // TODO: better job of transforming error for user consumption
          this.log(`${operation} failed: ${result.message}`);

          // Let the app keep running by returning an empty result.
          return of(result);
    }

    /** Log a FormaPagoGiroService message with the MessageService */
    private log(message: string) {
        // this.messageService.add(`FormaPagoGiroService: ${message}`);
        console.log(`FormaPagoGiroService: ${message}`);
    }

}
