import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as jwt_decode from 'jwt-decode';

import { ValidaOtpService } from './validaotp.service';
import NotificacionComponent from 'src/app/components/notificacion.component';

@Component({
    selector: 'valida-otp',
    templateUrl: './validaotp.component.html',
    styleUrls: ['./validaotp.component.css']
})
export class ValidaOtpComponent implements OnInit{
  token: string;
  tipoValida: String;
  tipoArray: string[];
  validaOtpForm: FormGroup;
  info: any;
  noValidado: boolean = false;

  _proc = false;
  recovered = false;
  mensajeRecuperado = '.';

  constructor(private builder: FormBuilder,
              private route: ActivatedRoute,
              private router: Router,
              private notificacion: NotificacionComponent,
              private validaOtpService: ValidaOtpService) {}

  ngOnInit() {
    this.validaOtpForm = this.builder.group({
      'numero1': ['', [Validators.required, Validators.pattern('^[0-9]{1}$')]],
      'numero2': ['', [Validators.required, Validators.pattern('^[0-9]{1}$')]],
      'numero3': ['', [Validators.required, Validators.pattern('^[0-9]{1}$')]],
      'numero4': ['', [Validators.required, Validators.pattern('^[0-9]{1}$')]],
      'numero5': ['', [Validators.required, Validators.pattern('^[0-9]{1}$')]],
      'numero6': ['', [Validators.required, Validators.pattern('^[0-9]{1}$')]],
    });

    this.validaOtpForm.controls.numero1.valueChanges.subscribe((data: string) => {
      this.validaOtpForm.controls.numero1.reset(this.obtenerDato(data), { emitEvent: false });
    });

    this.validaOtpForm.controls.numero2.valueChanges.subscribe((data: string) => {
      this.validaOtpForm.controls.numero2.reset(this.obtenerDato(data), { emitEvent: false });
    });

    this.validaOtpForm.controls.numero3.valueChanges.subscribe((data: string) => {
      this.validaOtpForm.controls.numero3.reset(this.obtenerDato(data), { emitEvent: false });
    });

    this.validaOtpForm.controls.numero4.valueChanges.subscribe((data: string) => {
      this.validaOtpForm.controls.numero4.reset(this.obtenerDato(data), { emitEvent: false });
    });

    this.validaOtpForm.controls.numero5.valueChanges.subscribe((data: string) => {
      this.validaOtpForm.controls.numero5.reset(this.obtenerDato(data), { emitEvent: false });
    });

    this.validaOtpForm.controls.numero6.valueChanges.subscribe((data: string) => {
      this.validaOtpForm.controls.numero6.reset(this.obtenerDato(data), { emitEvent: false });
    });

    this.route.queryParamMap.subscribe((params: any) => {
      this.token = params.params.info;
      sessionStorage.setItem('token', this.token);
      this.info = jwt_decode(this.token);
      this.tipoValida = this.info.sub;
      this.tipoArray =  this.tipoValida.split("|")
      this.tipoValida = this.tipoArray[1];
      this.validaOtpForm.patchValue({ subject: this.info.sub });
    });
  }

  onSubmit() {
    this._proc = true;
    this.recovered = false;
    this.mensajeRecuperado = '...';
    const data = this.validaOtpForm.value;
    const codigo = `${data.numero1}${data.numero2}${data.numero3}${data.numero4}${data.numero5}${data.numero6}`;
    this.validaOtpService.confirmar(this.token,codigo, this.tipoValida).subscribe((data: any) => {
      this._proc = false;
      this.validaOtpForm.reset();
      switch (true) {
        case data.status === 500:
          this.notificacion.infoNotificacion('Validación Caducada - Solicitar el código nuevamante', 8000);
          break;
        case data.status === 409:
          this.notificacion.infoNotificacion(data.message.mensaje, 8000);
          break;
        case data.status === 200:
          if(data.body.idMensaje == 1){
            this.recovered = true;
            this.mensajeRecuperado = data.body.Mensaje;
            this.notificacion.infoNotificacion('Validación Exitosa', 8000);
          }else if(data.body.idMensaje == 2){
            this.notificacion.dangerNotificacion('Código erroneo Intente nuevamente', 4000);
          }else{
            this.notificacion.dangerNotificacion('Límite de intentos, Se anulará la solicitud', 8000);
            this.recovered = true;
            this.noValidado = true;
          this.mensajeRecuperado = data.body.Mensaje;
          }
          break;
        default:
          const message = data.message || data.body.mensaje || 'Error en verificación';
          this.notificacion.dangerNotificacion(message);
          break;
      };
    });
  }

  ingresar() {
    this.router.navigateByUrl('');
  }

  obtenerDato(texto: string): string {
    return texto.substring(texto.length - 1, texto.length);
  }
}
