import { Component, ViewChildren, QueryList, TemplateRef, Input } from '@angular/core';
import { ControlComponent } from './control.component';
import { DataWindowComponent } from './datawindow.component';

//import { AppSettings } from '../../../app/components/app/app.settings';
//import { AppSettingsService } from '../../../app/components/app/app.settings.service';
//import { MessageService } from '../../../app/message.service';
//import { HttpRequestComponent } from './httprequest.component';
import { TransactionComponent } from './transaction.component';
import { DataStoreComponent } from './datastore.component';
import { DataWindowChildComponent } from './datawindowchild.component';

declare var http: any;
declare var dwexpr: any;
declare var _primary: string;

@Component({
    // tslint:disable-next-line: component-selector
    selector: 'datawindowchild',
    template: '<div><h6>hola</h6></div>'
})

export class DataWindowControlComponent extends ControlComponent {
    static msgSer = {} //new MessageService();
    static setSer = {} //new AppSettingsService();
    static rxWhere = /(where[ \t\n\r]+)(.*)([ \t\n\r]+(order|group|having|union)[ \t\n\r]+)?/i;

    static columnDefaults = {
        protect: '0',
        tabsequence: '0',
        visible: '1'
    }

    public dwservice: any;
    public dwmodel: any;
    public _row:  any = {};
    public data: Array<any> = [];
    public container: any;
    public componentRef: any;
    public object: any;
    public currentrow: number = 0;
    public _controls: any = {};
    public _columns: any = { count: 0 };
    public _dddws: any = { };
    public selectrow: (row: any, nrow: any) => {};

    private service: any;
    private _dddwretrieve: boolean = false;
    private _sqlselect: string = "";
    private _transaction: TransactionComponent = null;

    @ViewChildren(DataWindowComponent) dddws: QueryList<DataWindowComponent>;


    
    constructor() { 
        super();/*
        if(!_service) {
            //_service = new _servicetype(new HttpRequestComponent(this._transaction), DataWindowControlComponent.msgSer, DataWindowcontrolComponent.setSer );    
        }
        this.dwservice = _service;
        this.dwmodel = _modeltype;
        this._row = new _modeltype();*/
        this.init()
    }

    ngAfterContentInit() {

    }

    ngAfterViewInit() { 
    }

    public init() {
        this.object = { 
            datawindow: {}, 
            column: this._columns,  
            data: this.data 
        };

        this.object.datawindow = { 
            column: this._columns,
            data: this.data, 
            grid: { columnmove: ''}, 
            readonly: '',
            syntax: "",
            table: { select: "" }
        };
           
    }

    public AssignProperty(id: Array<string>, val: any, expr: () => any) {
        let oVar = null;
        switch(true) {
            case id[0] === 'datawindow':
                oVar = this.object.datawindow;
                for(var kInx = 1; kInx < id.length - 1 ; kInx++) {
                    oVar = oVar[id[kInx]];
                }
                oVar[id[id.length - 1]] = val;
                break;
            case id.length === 1:
                oVar = this.data[this.currentrow];
                oVar[id[0]] = val;
                break;
            default:
                //Modifica el control
                oVar = this[id[0]];
                if(oVar) {
                    for(var kInx = 1; kInx < id.length - 1 ; kInx++) {
                        oVar = oVar[id[kInx]];
                    }
                    oVar[id[id.length - 1]] = val;
                }

                //Modifica el Template
                /*
                oVar = this._controls[id[0]];
                if(oVar) {
                    let ooVar = oVar.element.attrs.filter((x) => x[1] === id[1]);
                    if(ooVar.length > 0) {
                        ooVar[0][2] = val;    
                    } else {
                        oVar.element.attrs.push(["", id[1], val]);
                    }
                    ooVar = oVar.element.allProviders[Object.keys(oVar.element.allProviders)[0]];

                    let oBinds = ooVar.bindings.filter((x) => x.name === id[1]);
                    if(oBinds.length === 0) {
                        ooVar.bindings.push( {flags: 4, ns: "", name: id[1], nonMinifiedName: id[1], securityContext: undefined, suffix: null } )   
                    }
                }*/ 

                //Se guarda en el buffer
                oVar = this.object.column[id[0]];
                if(!oVar) {
                    oVar = {};
                    this.object.column[id[0]] = oVar;
                }
                oVar[id[1]] = val;                         
                    
                if(id.length > 2) {
                    console.warn("Modify: Assign to more tree level object is not implemented " + id.join('.'));
                }

                if(expr) {
                    console.warn("Modify: Assign to dynamic expr for " + id.join('.') + " is not implemented: " + expr.toString());
                }
                
        }    
    }

    public GetColumnName(column: number): any {
        return Object.keys(this.object.datawindow.column)[column];
    }

    public GetValue(id: Array<string>): any {
        let oVar = null;
        switch(true) {
            case id[0] === 'datawindow':
                oVar = this.object.datawindow;
                for(var kInx = 1; kInx < id.length; kInx++) {
                    oVar = oVar[id[kInx]];
                }
                break;
            case id.length === 1:
                oVar = this.data[this.currentrow];
                oVar = oVar[id[0]];
                break;
            case id[1] === "background" && id.length > 2:
               id[1] = "back" + id[2];
               id.splice(2,1);
               //Continua      
            default:
                //Toma el dato del template
                let ooVar = this._controls[id[0]];
                if(ooVar) {
                    oVar = ooVar.element.attrs.filter((x) => x[1] === id[1]);
                    oVar = (oVar.length > 0) ? oVar[0][2] : DataWindowControlComponent.columnDefaults[id[1]]; 
                } else {
                    //Toma el dato del buffer
                    ooVar = this.object.column[id[0]];                    
                    oVar = ooVar ? ooVar[id[1]] : (id[1] === "id"?id[0]: DataWindowControlComponent.columnDefaults[id[1]]);
                }

                //Toma el dato del control 
                ooVar = this[id[0]];
                if(ooVar) {
                    for(var kInx = 1; kInx < id.length; kInx++) {
                        ooVar = ooVar[id[kInx]];
                    }
                    oVar = ooVar || oVar;
                } 

                if(id.length > 2) {
                    console.warn("Modify: Assign to more tree level object is not implemented " + id.join('.'));
                }                
        }
        return oVar;    
    }

    public findInTemplate(column: string, band: any): any {
        let oNodes =  band._def.element.template.nodes;
        let oNode = oNodes.filter((x) => x.element && x.element.attrs.filter((y) => y[1] === "id" && y[2] === column).length );
        return oNode[0];
    }

    public get retrieved(): boolean {
        return this._dddwretrieve;
    } 

    public set retrieved(val: boolean) {
        this._dddwretrieve = val;
    } 

    public getDddw(dddwname: string): DataStoreComponent {
        return this._dddws[dddwname].datastore;
    }

    public bitmap(arg1: any): any  {
        console.error("datawindow: bitmap : '" + arg1.join() + "' Not Implemented");
        
        return arg1;
    } 

    public currentRow(): number {
        return this.currentrow;
    }
    public describe(arg1: string): any {
        arg1 = dwexpr.parse('DESCRIBE: ' + arg1);
        return eval(arg1);
    }

    public find(arg1: string, from: number, to: number): number {
        let CurrentRow = this.currentrow;
        let kkRow = 0;
        arg1 = dwexpr.parse('DESCRIBE: ' + arg1);
        let fCheck = new Function('return ' + arg1 + ';');
        
        for(var kRow = from; kRow <= to && kRow <= this.data.length; kRow++ ) {
            this.currentrow = kRow - 1;
            if(fCheck.call(this, arg1)) {
                kkRow = kRow;
                break;
            }
        }
        this.currentrow = CurrentRow;
        return kkRow;
    }

    public getchild(col: string): DataWindowControlComponent {
        console.error("Datawindow: '" +  this.constructor.name + "' Gechild: '" + col + "'");
        let oDddw = this._dddws[col.toLowerCase()];
        return (oDddw) ? oDddw.datastore : null;
    }

    public getitem(row: number, arg1: any): any {
        arg1 = arg1.toLowerCase();
        return this.data[row - 1][arg1];
    }

    public getitemdecimal(row: number, arg1: any): number {
        arg1 = arg1.toLowerCase();
        return this.data[row - 1][arg1];
    }

    public getitemdate(row: number, arg1: any): any {
        arg1 = arg1.toLowerCase();
        return this.data[row - 1][arg1];
    }

    public getitemdatetime(row: number, arg1: any): any {
        arg1 = arg1.toLowerCase();
        return this.data[row - 1][arg1];
    }

    public getitemnumber(row: number, arg1: any): number {
        arg1 = arg1.toLowerCase();
        return this.data[row - 1][arg1];
    }

    public getitemstring(row: number, arg1: any): string {
        arg1 = arg1.toLowerCase();
        return this.data[row - 1][arg1];
    }

    public getitemtime(row: number, arg1: any): any {
        arg1 = arg1.toLowerCase();
        return this.data[row - 1][arg1];
    }

    public getrow(): number {
        return 1;
    }

    public insertrow(row: number): any {
        var mdl = new this.dwmodel();

        if(row === 0) {
            row = this.data.length + 1;
        }
        
        this.currentrow = row;
        this._row = mdl;
        this.data[row - 1] = mdl;
        return row;
    }

    public modify(arg1: string): any {    
        arg1 = dwexpr.parse('MODIFY: ' + arg1);
        eval(arg1);
        return "";
    }

    public reset(): any {
        this._row = new this.dwmodel();
        this.data = [];
        this.currentrow = 0;
        return 1;
    }

    public retrieve(...args: Array<any>): any {  
        var nRes = 1;   
        let body: any = { };
        let where = DataWindowControlComponent.rxWhere.exec(this.object.datawindow.table.select || "");
        if(where) {        
            let sWhere = (where[2] || "").replace(/"/g, "");
            sWhere = sWhere.replace(/'/g, '"');
            body.__where =  sWhere;
            console.log("Datawindow: Where " + this.dwservice.constructor.name);
        }

        let res = this.dwservice.search(body);
        this.data = res.rows; 
        this.object.data = this.data;
        this.object.datawindow.data = this.data;

        nRes = this.data.length;
        if(nRes > 0) {            
            this.currentrow = 1;
            this._row = this.data[0];
        }

        if(!this._dddwretrieve) {
            this._dddwretrieve = true;
            for(var key in this._dddws) {
                if(key !== "count") {
                    let dddw = this._dddws[key];
                    dddw.datastore.retrieve();
                }
            }
        }

        return  nRes;
    }

    public rowscopy(startrow: number, endrow: number, copybuffer: any, targetdw: DataWindowControlComponent, beforerow: number, targetbuffer: any): number {
        if(copybuffer !== _primary) {
            console.warn("datawindow: source buffer " + copybuffer + " is not supported");
        }
        if(targetbuffer !== _primary) {
            console.warn("datawindow: target buffer " + targetbuffer + " is not supported");
        }
        var src = this.data.slice(startrow - 1, endrow);
        targetdw.object.data.splice(beforerow - 1, 0, ...src);
        return 1;
    }
    public setitem(row: number, arg1: any, arg2: any): any {
        if(!row || !arg1 ) {
            console.error("datawindow: setitem: null arguments used");
            return null;
        }
        try {
            arg1 = arg1.toLowerCase();
            this.data[row - 1][arg1] = arg2;
            return 1;
        } catch (ex) {
            console.error("datawindow: setitem: " + ex.message);
            return -1;
        }
    }

    public setsqlselect(arg1: string): any {
        this.object.datawindow.table.select = arg1;
        this._sqlselect = arg1.trim();
        return 1;
    }

    public settransaction(arg1: TransactionComponent) {
        this._transaction = arg1;
        this.dwservice.xhttp.transaction = arg1;
    }

    public onSelectRow(row: any, nrow: number) {
        if(row !== this._row) {
            this.currentrow = nrow;
            this._row = row;    
            if(this.selectrow) {
                this.selectrow.apply(this.container, [row, nrow]);   
            }
        }
    }

}
