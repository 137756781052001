import { Component, AfterViewInit, Inject, OnInit, ViewChild } from '@angular/core';
import { IncidenciasService } from './incidencias.service';
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar, MatBottomSheet, MatPaginator } from '@angular/material';
import { FormBuilder, FormGroup, Validators, FormControl, ValidationErrors } from '@angular/forms';
import { Observable } from 'rxjs';
import { startWith, map, switchMap } from 'rxjs/operators';
import { IncidenciasModel } from './incidencias.model';

declare var CONDITIONS_LIST: any;
declare var CONDITIONS_LIST_NUMBER: any;

@Component({
  selector: 'incidencias-component',
  templateUrl: './incidencias.component.html',
  styleUrls: ['./incidencias.component.css'],
  providers: [IncidenciasService]
})
export class IncidenciasComponent implements OnInit {

  _proc = false;
  filtroForm: FormGroup;
  filteredModulos_DddwModulos: Array<any> = [];
  resultsLength = 0;
  rows: IncidenciasModel[];
  public _pageSize = 10;

  tableParams = {
    tableWidth: '900px'
  };

  columns = [
    {
      columnDef: 'requerimiento', columnDefSnake: 'requerimiento', type: 'text',
      header: 'incidencias.requerimiento', cell: (element: any) => `${element.requerimiento}`
    },
    { columnDef: 'descCambio', columnDefSnake: 'descCambio', type: 'text',
    header: 'incidencias.descCambio', cell: (element: any) => `${element.descCambio}` },
    { columnDef: 'fecha', columnDefSnake: 'fecha', type: 'text',
    header: 'incidencias.fecha', cell: (element: any) => `${element.fecha}` },
    { columnDef: 'versionPom', columnDefSnake: 'versionPom', type: 'text',
    header: 'incidencias.versionPom', cell: (element: any) => `${element.versionPom}` },
    { columnDef: 'evidencias', columnDefSnake: 'evidencias', type: 'text',
    header: 'incidencias.evidencias', cell: (element: any) => `${element.evidencias}` },
    { columnDef: 'versionMayor', columnDefSnake: 'versionMayor', type: 'number',
    header: 'incidencias.versionMayor', cell: (element: any) => `${element.versionMayor}` },
    { columnDef: 'versionMenor', columnDefSnake: 'versionMenor', type: 'number',
    header: 'incidencias.versionMenor', cell: (element: any) => `${element.versionMenor}` },
    { columnDef: 'bugs', columnDefSnake: 'bugs', type: 'number',
    header: 'incidencias.bugs', cell: (element: any) => `${element.bugs}` }
  ];

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  constructor(private incidenciasService: IncidenciasService,
    private router: Router,
    private _snackBar: MatSnackBar,
    private _bottomSheet: MatBottomSheet,
    private builder: FormBuilder,
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.rows = [];
    this.getUrlRoute();
  }

  ngOnInit() {
    this.filtroForm = this.builder.group({
      'modulo': ['', [Validators.required]],
      'modulo_Dddw': ['', [Validators.required]],
      'version': [''],
      'descripcion': [''],
      'fecha': ['']
    });
    this.filtroForm.controls.modulo_Dddw.valueChanges
      .pipe(
        startWith(''),
        switchMap((data) => this.incidenciasService.getDddwModulos(data))
      ).subscribe((data: any) => this.filteredModulos_DddwModulos = data.rows);
  }

  filtrar() {
    this._proc = true;
    this.incidenciasService.getVersion(this.filtroForm.value, this.paginator).subscribe(rst => {
      console.log(rst);
      this._proc = false;
      if (rst.error) {

      } else {
        this.rows = [...rst.data];
      }
    });
  }

  getUrlRoute() {
    const filter1 = this.router.url.split('/app/');
    if (filter1.length === 2) {
      const filter2 = filter1[1].split('/');
      if (filter2[0]) {
        const path = filter2[0];
        this.incidenciasService.getByUrlDddwModulos(path).subscribe(rst => {
          this.onSelectModulo_DddwModulo(rst.rows[0]);
        });
      }
    }
  }

  onSelectModulo_DddwModulo(opt: any) {
    this.filtroForm.patchValue({
      modulo: opt.rutaEjecutable,
      modulo_Dddw: opt.moduloNombre
    });
  }

}
