import { Component, Input } from '@angular/core';
import { ControlComponent } from './control.component';

@Component({
    selector: 'statictext',
    template: '<span style="width: 100%; height: 100%">{{text}}</span>'
})
export class StaticTextComponent extends ControlComponent {
    alignment: string;

    constructor() {
        super();
    }
}
