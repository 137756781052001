import { Component, Inject, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatBottomSheet } from '@angular/material';
import { AlertasConfirmComponent } from 'src/app/components/alertasConfirm.component';
import { AwsComponent } from 'src/app/components/aws.component';
import { DocumentoDesembolsoService } from './documentodesembolso.service';
//import { TipoDocumentoOriginacionModel } from './tipodocumentooriginacion.model';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/internal/operators/map';
import { DocumentoDesembolsoModel } from './documentodesembolso.model';

@Component({
	templateUrl: './tipodocumentooriginacion.visual.html',
	styleUrls: ['./documentodesembolso.table.css'],
})
export class DocumentoDesembolsoVisual {
	//selectedTipoDocumento: TipoDocumentoOriginacionModel;
	tipoImpresion: number;
	tipoProceso: number;

	selectedTipoDocumento = new DocumentoDesembolsoModel();

	impresionDocumentosForm: FormGroup;
	isLoadingResults = true;
	rutaAws: string;

	_proc: boolean = false;
	_status: boolean = false;
	resultError: string = null;

	datos: any = {};

	@ViewChild('pdfViewerAutoLoad', { static: false }) pdfViewerAutoLoad;

	constructor(private awsComponent: AwsComponent,
		private http: HttpClient,
		private _bottomSheet: MatBottomSheet,
		private DocumentoDesembolsoService: DocumentoDesembolsoService,
		public dialogRef: MatDialogRef<DocumentoDesembolsoVisual>,
		@Inject(MAT_DIALOG_DATA) public data: any) {
		this.datos = data.selected;
	}

	ngOnInit() {
		if (this.datos.anexo == 0) {
			this.DocumentoDesembolsoService.generarPDF(this.datos).subscribe((data: any) => {
				this.awsComponent.download_file(data.fileResult).subscribe((file: Blob) => {
					var url = window.URL.createObjectURL(file);
					this.downloadFile(data.fileResult).subscribe((resp: any) => {
						this.pdfViewerAutoLoad.pdfSrc = resp;
						this.pdfViewerAutoLoad.refresh();
						this.isLoadingResults = false;
					});
				});
			});
		} else {
			this.DocumentoDesembolsoService.getAllDocumentoEstaticoDetail(this.datos.plantilla).subscribe(
				(resp: any) => {
					let proc = `dialogo-procesos.parametros.documentos.estaticos.input.${this.datos.plantilla}`;
					let urlRuta = `parametros/documentos/estaticos/input/${this.datos.plantilla}`;
					this.isLoadingResults = true;
					let archivo = resp.rutaAws.replace(urlRuta, "");
					this.awsComponent.downloadFile(proc, archivo)
						.subscribe((data: any) => {
							this.downloadFile(data.url).subscribe((resp: any) => {
								this.pdfViewerAutoLoad.pdfSrc = resp;
								this.pdfViewerAutoLoad.refresh();
								this.isLoadingResults = false;
							});
						});
				}
			);

		}
	}

	private downloadFile(url: string): any {
		return this.http.get(url, { responseType: 'blob' })
			.pipe(
				map((result: any) => {
					return result;
				})
			)
	}
}
