import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, ValidationErrors } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar, MatBottomSheet } from '@angular/material';
import { map, switchMap, startWith } from 'rxjs/operators';

import { marcaFraudeService } from './marcarfraude.service';
import { MarcaFraudeModel } from './marcarfraude.model';
import { AlertasConfirmComponent } from 'src/app/components/alertasConfirm.component';


import * as moment from 'moment';

@Component({
    templateUrl: './marcarfraude.dialog.html',
    // styleUrls: ['./devolucionestado.dialog.css'],
    providers: [marcaFraudeService]
})
export class MarcaFraudeDialog {
    nroCarpeta: number;
    codigoFraude: number;
    selectedMarcacionEspecialCarpeta: MarcaFraudeModel;
    originalMarcacionEspecialCarpeta: MarcaFraudeModel;

    filteredCodigoFraude_DddwFraudes: Array<any> = [];

    marcaFraudeModelForm: FormGroup;

    _proc: boolean = false;
    _status: boolean = false;
    resultError: string = null;
    

    constructor(private builder: FormBuilder,
        private marcaFraudeService: marcaFraudeService,
        private _snackBar: MatSnackBar,
        private _bottomSheet: MatBottomSheet,
        public dialogRef: MatDialogRef<MarcaFraudeDialog>,
        @Inject(MAT_DIALOG_DATA) public data: any) {
        this.nroCarpeta = data.nroCredito;
        this.selectedMarcacionEspecialCarpeta = new MarcaFraudeModel();
        this.originalMarcacionEspecialCarpeta = new MarcaFraudeModel();
        this.codigoFraude = data.codigoFraude;
        console.log(data);
    }

    ngOnInit() {
        const disabledPKey = this.originalMarcacionEspecialCarpeta._estado === 'O';
        this.marcaFraudeModelForm = this.builder.group({
            'faCodigoFraude': [''],
            'faMotivo': [''],
            'codigoFraude_DddCodigoFraude': [''],
            // '_estado': [ '' ],
        }, {
            validators: (formGroup: FormGroup): ValidationErrors | null => {
                const data = formGroup.getRawValue();
                let validationErrors = {

                };

                return validationErrors;
            }
        });

        this.marcaFraudeModelForm.valueChanges.subscribe((data) => {

            this.marcaFraudeModelForm.patchValue({

            }, { emitEvent: false, onlySelf: true });
        });


        // Marca Fraude
        this.marcaFraudeModelForm.controls.codigoFraude_DddCodigoFraude.valueChanges
            .pipe(
                startWith(''),
                switchMap((data) => this.marcaFraudeService.filterMarcaFraude(data))
            ).subscribe((data) => this.filteredCodigoFraude_DddwFraudes = data.rows);
    }

    marcar(formData: MarcaFraudeModel) {
        this._proc = true;
        if (this.marcaFraudeModelForm.valid) {
            console.log('Data de MARCAR', this.marcaFraudeModelForm);
            formData['faNroCredito'] =this.nroCarpeta;
            formData['faFecha'] = moment(new Date).format('YYYY-MM-DD');
            formData = Object.assign(this.originalMarcacionEspecialCarpeta, formData);
            console.log('El FormData', formData);
            this.marcaFraudeService.addMotivoFraude(formData).subscribe((data: any) => {
                console.log('Data de MARCAR 2', data);
                this._proc = false;
                this._status = !!data.error;
                this.resultError = null;
                if (!this._status) {
                    this.dialogRef.close();
                } else {
                    this.resultError = data.message.mensaje;
                    console.log(this.resultError);
                    this.openNotificationDanger(this.resultError)
                }
            });

        }
    }
    desmarcar(formData: MarcaFraudeModel) {
        this._proc = true;
        if (this.marcaFraudeModelForm.valid) {
            formData['faNroCredito'] =this.nroCarpeta;
            formData['faFecha'] = moment(new Date).format('YYYY-MM-DD');
            formData = Object.assign(this.originalMarcacionEspecialCarpeta, formData);
            this.marcaFraudeService.desmarcarMotivoFraude(formData).subscribe((data: any) => {
                this._proc = false;
                this._status = !!data.error;
                this.resultError = null;
                if (!this._status) {
                    this.dialogRef.close();
                } else {
                    this.resultError = data.message.mensaje;
                    console.log(this.resultError);
                    this.openNotificationDanger(this.resultError)
                }
            });

        }
    }
    openNotificationDanger(message: string, action?: string) {
        this._snackBar.open(message, action, {
            duration: 2000,
            panelClass: 'dangerSnackBar',
        });
    }

    onSelectCodigoFraude(opt: any) {
        this.marcaFraudeModelForm.patchValue({
            faCodigoFraude: opt.codigoMotivoFraude,
            codigoFraude_DddCodigoFraude:opt.descripcionMotivoDevolucion
        });
    }
}