import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class UrlChangeService {
    private subject = new Subject<any>();
    alertUrl(ruta: string) {
        this.subject.next(ruta);
    }
    listenUrl(): Observable<string> {
        return this.subject.asObservable();
    }
}