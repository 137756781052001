import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Message } from '../model/message';
import { map } from 'rxjs/internal/operators/map';

@Injectable()
export class SocketService {
  url: string = environment.dataServiceUrl + "/notificacion/notificaciones/socket";

  constructor(private http: HttpClient) { }

  post(data: Message) {
    return this.http.post<Message>(this.url, data).pipe(
      map((data: Message) => { return data; 
      })
    );
  }
}
