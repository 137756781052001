import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from '../../environments/environment';
import { catchError, map, tap, retry, switchMap } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material';
@Injectable({ providedIn: 'root' })
export class AwsComponent {

  private identityUrl = '';
  private signedUrl = '';
  public dataSend: any;
  public fileData: string = '';

  _file: File;
  fileBlob: Blob;

  constructor(private http: HttpClient, private _snackBar: MatSnackBar) {
    this.identityUrl = `${environment.dataServiceUrl}/Identity/AWSBucket/`;
  }

  uploadFile(proceso: string, file: File): Observable<any> {
    this._file = file;
    return this.getUrlCarga(proceso, file.name);
  }

  uploadFileBlob(proceso: string, file: Blob, fileName: string): Observable<any> {
    this.fileBlob = file;
    return this.getUrlCargaBlob(proceso, fileName);
  }

  getUrlCarga(proceso: string, file: string): Observable<string> {
    return this.http.get<any>(this.identityUrl + "GetSignedUrl/" + proceso + "/" + file).pipe(
      tap(row => this.log('fetched AwsComponent')),
      switchMap((data) => this.putFile(data)),
      catchError((error) => this.handleError('AwsComponent', error))
    );
  }

  getUrlCargaBlob(proceso: string, file: string): Observable<string> {
    return this.http.get<any>(this.identityUrl + "GetSignedUrl/" + proceso + "/" + file).pipe(
      tap(row => this.log('fetched AwsComponent')),
      switchMap((data) => this.putFileBlob(data)),
      catchError((error) => this.handleError('AwsComponent', error))
    );
  }

  putFile(data: any): Observable<any> {

    const contentType = this._file.type;
    this.dataSend = data;
    this.fileData = data.file;

    const httpOptions = {
      observe: null,
      params: null,
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded'
      })
    };

    httpOptions.observe = "response";
    httpOptions.headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded'
    });
    return this.http.put(data.url, this._file, httpOptions);
  }

  putFileBlob(data: any): Observable<any> {    
    this.dataSend = data;
    this.fileData = data.file;

    const httpOptions = {
      observe: null,
      params: null,
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded'
      })
    };

    httpOptions.observe = "response";
    httpOptions.headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded'
    });
    return this.http.put(data.url, this.fileBlob, httpOptions);
  }

  downloadFile(proceso: string, file: string): Observable<string> {

    return this.getUrlDescarga(proceso, file).pipe(
      tap(row => this.log('fetched AwsComponent')),
      catchError((error) => this.handleError('AwsComponent', error))
    );
  }

  download_file(downlode_file_url: string) {

    let headers = new Headers({
      'Content-Type': 'application/json'
    });
    let options = { headers: headers };
    return this.http.get(downlode_file_url, { responseType: "blob" })
      .pipe(
        map((result: any) => {
          return result;
        })
      );
  }

  getUrlDescarga(proceso: string, file: string): Observable<string> {

    return this.http.get<any>(this.identityUrl + "GetSignedUrl/Get/" + proceso + "/" + file).pipe(
      tap(row => this.log('fetched AwsComponent')),
      catchError((error) => this.handleError('AwsComponent', error))
    );
  }

  private log(message: string) {
    // this.messageService.add(`ActividadDeportivaService: ${message}`);
    console.log(`AwsComponent: ${message}`);
  }

  private handleError(operation = 'operation', result?: any) {

    // TODO: send the error to remote logging infrastructure
    console.error(result.error); // log to console instead
    if (result.error !== undefined && result.error !== null && result.error.codigo === 409) {
      this.openNotificationDanger(result.error.mensaje);
    };
    // TODO: better job of transforming error for user consumption
    this.log(`${operation} failed: ${result.message}`);

    // Let the app keep running by returning an empty result.
    return of(result);
  }

  openNotificationDanger(message: string, action?: string) {
    this._snackBar.open(message, action, {
      duration: 5000,
      panelClass: 'dangerSnackBar',
    });
  }

}