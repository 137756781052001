import {AlertasConfirmComponent} from '../components/alertasConfirm.component';
import {MatBottomSheet} from '@angular/material';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import * as $ from 'jquery';

export default class Utilidades {


  static EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  static EXCEL_EXTENSION = '.xlsx';

  static onExport(namefile: string, dataexp: any, titlabel: any, _bottomSheet: MatBottomSheet) {

    let sheetRef = _bottomSheet.open(AlertasConfirmComponent, {
      data: {tipoMensaje: 3}
    });
    sheetRef.afterDismissed().subscribe((data: any) => {
      if (data.mensaje === 'excel') {
        this.exportAsExcelFile(dataexp, namefile);
      } else if (data.mensaje === 'csv') {
        //
        this.saveAsCsvFile(dataexp, namefile, titlabel);
      }
    });
  }

  static onExportCsv(namefile: string, dataexp: any, titlabel: any, _bottomSheet: MatBottomSheet) {

    let sheetRef = _bottomSheet.open(AlertasConfirmComponent, {
      data: {tipoMensaje: 25}
    });
    sheetRef.afterDismissed().subscribe((data: any) => {
        this.saveAsCsvFile(dataexp, namefile, titlabel);
    });
  }

  static onExportExcel(namefile: string, dataexp: any, titlabel: any, _bottomSheet: MatBottomSheet) {

        this.exportAsExcelFile(dataexp, namefile);


  }

  static saveAsCsvFile(data: any, namefile: string, titlabel: any) {
    let csvData = this.dataToCsv(data);
    // let csvData = this.convertToCSV(data, titlabel);
    let blob = new Blob(['\ufeff' + csvData], {type: 'text/csv;charset=utf-8;'});
    let dwldLink = document.createElement('a');
    let url = URL.createObjectURL(blob);
    let isSafariBrowser = navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1;
    if (isSafariBrowser) {
      dwldLink.setAttribute('target', '_blank');
    }
    dwldLink.setAttribute('href', url);
    dwldLink.setAttribute('download', namefile + '.csv');
    dwldLink.style.visibility = 'hidden';
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }

  static saveAsCsvFileBlob(data: any) {
    let csvData = this.dataToCsvChar(data, ';');
    // let csvData = this.convertToCSV(data, titlabel);
    return new Blob(['\ufeff' + csvData], {type: 'text/csv;charset=utf-8;'});
  }

  static dataToCsv(data: any) {
    const replacer = (key, value) => value === null ? '' : value; // specify how you want to handle null values here
    const header = Object.keys(data[0]);
    const csv = data.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
    csv.unshift(header.join(','));
    const csvArray = csv.join('\r\n');
    return csvArray;
  }

  static dataToCsvChar(data: any, char: string) {
    const replacer = (key, value) => value === null ? '' : value; // specify how you want to handle null values here
    const header = Object.keys(data[0]);
    const csv = data.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(char));
    csv.unshift(header.join(char));
    const csvArray = csv.join('\r\n');
    return csvArray;
  }

  static convertToCSV(objArray: any, headerList: any) {
    let array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
    let str = '';
    let row = '';
    for (let index in headerList) {
      row += headerList[index] + ',';
    }
    row = row.slice(0, -1);
    str += row + '\r\n';
    for (let i = 0; i < array.length; i++) {
      let line = '';
      for (let index in headerList) {
        let head = headerList[index];
        line += array[i][head] + ',';
      }
      str += line + '\r\n';
    }
    return str;
  }

  static exportAsExcelFile(json: any[], namefile: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = {Sheets: {'data': worksheet}, SheetNames: ['data']};
    const excelBuffer: any = XLSX.write(workbook, {bookType: 'xlsx', type: 'array'});
    this.saveAsExcelFile(excelBuffer, namefile);
  }

  static exportAsExcelMultiplesHojas(workbook: any, namefile: string): void {
    const excelBuffer: any = XLSX.write(workbook, {bookType: 'xlsx', type: 'array'});
    this.saveAsExcelFile(excelBuffer, namefile);
  }

  static saveAsExcelFile(buffer: any, namefile: string): void {
    const data: Blob = new Blob([buffer], {type: this.EXCEL_TYPE});
    FileSaver.saveAs(data, namefile + this.EXCEL_EXTENSION);
  }

  static bajarscroll(numcel: number) {
    // alto de celda + alto de los titulos de mat- table
    const num = (22 * (numcel - 1));
    $('.mat-table-scroll').scrollTop(num);
  }

  static saveAsTxtFile(data: any, namefile: string, titlabel: any, separador: string) {
    let csvData = this.dataToTxt(data, separador);
    // let csvData = this.convertToCSV(data, titlabel);
    let blob = new Blob(['\ufeff' + csvData], {type: 'text/txt;charset=utf-8;'});
    let dwldLink = document.createElement('a');
    let url = URL.createObjectURL(blob);
    let isSafariBrowser = navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1;
    if (isSafariBrowser) {
      dwldLink.setAttribute('target', '_blank');
    }
    dwldLink.setAttribute('href', url);
    dwldLink.setAttribute('download', namefile + '.txt');
    dwldLink.style.visibility = 'hidden';
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }


  static dataToTxt(data: any, separador:string) {
    const replacer = (key, value) => value === null ? '' : value; // specify how you want to handle null values here
    const header = Object.keys(data[0]);
    const csv = data.map(row => header.map(fieldName => row[fieldName], replacer).join(separador));
    const csvArray = csv.join('\r\n');
    return csvArray;
  }

}
