import { Component, OnInit, Input, AfterViewInit, EventEmitter, Output } from '@angular/core';
import { Trazabilidad } from './trazabilidad';
import { TrazabilidadService } from './trazabilidad.service';
@Component({
	selector: 'app-table-trazabilidad',
	templateUrl: './table-trazabilidad.component.html',
	styleUrls: ['./table-trazabilidad.component.css']
})
export class TableTrazabilidadComponent implements AfterViewInit {

	@Input() trazabilidad: any;
	@Input() tipo_bandeja: any;
	public trazabilidadModel: Trazabilidad = new Trazabilidad();
	public datosMostrar: any = {};
	@Output() returnData = new EventEmitter<any>();


	constructor(
		public _TrazabilidadService: TrazabilidadService
	) { }

	ngAfterViewInit() {
		switch (this.tipo_bandeja) {
			case 'SUCURSAL': this.traerdatos(this.trazabilidadModel); this.addData(this.trazabilidad.codigoSucursal); break;
			case 'REGION': this.addData(this.trazabilidad.consecutivo); break;
			case 'GERENCIA': this.addData(this.trazabilidad.consecutivo); break;
			case 'DIVISION': this.addData(this.trazabilidad.consecutivo); break;
			case 'DIRECCION': this.addData(this.trazabilidad.consecutivo); break; 
			case 'ASESOR': this.addData(this.trazabilidad.codigoEjecutivo); break;
			default: break;
		}
		this.trazabilidadModel.v_tipo_bandeja = this.tipo_bandeja;
		this.traerdatos(this.trazabilidadModel);
	}

	traerdatos(row: Trazabilidad) {
		this._TrazabilidadService.semaforo(row).subscribe(
			(resp: any) => {
				this.datosMostrar = resp;
				this._TrazabilidadService.colorProceso$.emit(this.datosMostrar);
				this.trazabilidad.estado_nuevo_mostrar = this.datosMostrar.estado_nuevo_mostrar;
				this.trazabilidad.color_nuevo = this.datosMostrar.color_nuevo;
			}
		);
	}


	addData(registro: number) {
		this.trazabilidadModel.v_numero_registro = registro;
	}
}
