import { Component, ViewChild, AfterViewInit, Inject } from '@angular/core';
import { MatPaginator, MatSort, PageEvent, MatDialog, MatSnackBar, MAT_DIALOG_DATA, MatDialogRef, MatBottomSheet } from '@angular/material';
import { merge, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { AlertasConfirmComponent } from 'src/app/components/alertasConfirm.component';


import { BlockUI, NgBlockUI } from 'ng-block-ui';

import { ChartDataSets, ChartOptions, ChartPoint, ChartType } from 'chart.js';

import { Label, Color } from 'ng2-charts';
import { GestionAvanceProcesosMultiplesDialog } from '../gestionavanceprocesosmultiples/gestionavanceprocesosmultiples.dialog';

import { DetallesPagosModel } from './detallespagos.model';
import { DetallesPagosService } from './detallespagos.service';
import Utils from '../../services/utilidades';


declare var CONDITIONS_LIST: any;
declare var CONDITIONS_LIST_NUMBER: any;
declare var appComponent: any;

@Component({
  selector: 'gestionavanceprocesos-table',
  templateUrl: './detallespagos.table.html',
  styleUrls: ['./detallespagos.table.css'],
  providers: [DetallesPagosService]
})
export class DetallesPagosTable implements AfterViewInit  {
    rows: DetallesPagosModel[] = [];
    selectedRow: DetallesPagosModel;
    selectedIndex: number = 0;

    barra = true;

    idInterval: any;
    progreso: number = 0;
    procesosRealizados: number = 0;
    totalProcesos: number = 0;
    complete: boolean = false;
    mensajeRespuesta: string = '';
    verGrafica1 = false;

    pagosSegundos = 0;
    registroTotal = 0;
    procesosTotal = 0;

    intervalSingle:any;
    progressSingle = 0;

    totalPagos = 0;
    aplicados = 0;
    pendientes = 0;
    errados = 0;
    avance = 0;
    avancenum=0;




    	// VARIABLES PARA DATA DE GRAFICAS
	public numeroProcesosEjecutados: any = [];
	public fecha: number[] | ChartPoint[];
  public segTiempoProcXRegistro: any = [];


  public data1: any = [];

	public totalRegistros: any = [];
	public totalProcesosFecha: any;
  public verGrafica = false;


    	// DATA PARA GRAFICAS
	public tittle: string = '';
	public barChartDataProcesosEjecutados:  ChartDataSets[] = [];
	public barChartDataTotalRegistros: ChartDataSets[] = [];
	public barChartDataSegTiempoRegistro:  ChartDataSets[] = [];
  public barChartDataTiempoxRegistros:  ChartDataSets[] = [];



    //'codigo_proceso'
    public displayedColumns: string[] = [  'nro_credito', 'fecha_aplicacion', 'fecha_generacion', 'nro_pago','usuario','valor_pago'];

    public conditionsList = CONDITIONS_LIST;
    public conditionsListNumber = CONDITIONS_LIST_NUMBER;
    public searchValue: any = {};
    public searchCondition: any = {};
    public _pageSize = 10;

    filter = {
      column: '',
      condition: '',
      value: ''
    };

    selectedColumn = {
      name: '',
      dbName: '',
      type: '',
      sign: '',
      filter: []
    };

    filtro = {
      idProceso: 0,
      codEmpresa: 0,
      lista: []
    };

    resultsLength = 0;
    isLoadingResults = false;
    isRateLimitReached = false;

    numeroProceso:any = 0;

    intervalId:any;
    procesoFin:any = [];

    @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
    @ViewChild(MatSort, {static: false}) sort: MatSort;
    @BlockUI() blockUI: NgBlockUI;

    _proc: boolean = false;
    _status: boolean = false;
    resultError: string = null;

    list:any = [];

    single:any;
    pagos_aplicados = 0;
    pagos_pendientes = 0;
    pagos_errados = 0;

    titulo:any;


    constructor(public dialog: MatDialog,
                private _snackBar: MatSnackBar,
                private _bottomSheet: MatBottomSheet,
                private gestionAvanceProcesosService: DetallesPagosService,
                public dialogRef: MatDialogRef<DetallesPagosTable>,
                @Inject(MAT_DIALOG_DATA) public data: any) {



        this.dialogRef.disableClose = true;


        this.pagos_aplicados = data.aplicados;
        this.pagos_pendientes = data.pendientes;
        this.pagos_errados = data.errados;


        this.filtro.idProceso = data.numeroProceso;
        this.numeroProceso = data.numeroProceso;
        this.filtro.codEmpresa = Number(appComponent.usuario.mpr);

        if(this.pagos_aplicados == 1){
            this.titulo = "pagos_aplicados";
            this.displayedColumns = [  'nro_credito', 'fecha_aplicacion','fecha_generacion', 'nro_pago','usuario','valor_pago'];
        }else if(this.pagos_pendientes == 1){
          this.titulo = "pagos_pendientes";
          this.displayedColumns = [  'nro_credito', 'fecha_generacion', 'nro_pago','usuario','valor_pago'];
        }else if(this.pagos_errados == 1){
          this.titulo = "pagos_errados";
          this.displayedColumns = [  'nro_credito', 'fecha_generacion', 'nro_pago','error','usuario','valor_pago'];
        }

    }




    ngAfterViewInit() {

      this.rows = [];

       if(this.pagos_aplicados == 1){

          this.onConsultaPagosAplicados(this.filtro.idProceso);

       } else if(this.pagos_pendientes == 1){

          this.onConsultaPagosPendientes(this.filtro.idProceso);

       }else if(this.pagos_errados == 1){

          this.onConsultaPagosErrados(this.filtro.idProceso);
       }





    }


    onCalcularAvanceSingle(filtro:any){


      this.list = [];

      this.gestionAvanceProcesosService.getAvanceProcesosAll(filtro).subscribe((data: any) => {

        this._status = !!data.error;
        this.resultError = null;

        if (!this._status) {


            this.progressSingle = ((data.rows[0].registroActual / data.rows[0].totalRegistros) * 100);

            this.list.push({
              codigoProceso: data.rows[0].codigoProceso,
              procesoRealizados: data.rows[0].registroActual,
              totalProceso: data.rows[0].totalRegistros,
              progreso: ((data.rows[0].registroActual / data.rows[0].totalRegistros) * 100),
              mensajeRespuesta: data.rows[0].mensajeActual
            });


            this.procesosTotal =  data.rows[0].registroActual;
            this.registroTotal =  data.rows[0].totalRegistros;
            this.progreso =  (( this.procesosTotal / this.registroTotal ) * 100);


        } else {
          this.resultError = data.message;
        }

      });



    }



    onCalcularAvance(filtro:any) {



      this.gestionAvanceProcesosService.getAvanceProcesosAll(filtro).subscribe((data: any) => {

        this._status = !!data.error;
        this.resultError = null;

        if (!this._status) {


           if(data.rows[0].totalRegistros == 0){

                clearInterval(this.idInterval);

                let sheetRef = this._bottomSheet.open(AlertasConfirmComponent, {
                  data: { tipoMensaje: 17 }
                });

                sheetRef.afterDismissed().subscribe(dataDismised => {
                  if (dataDismised.mensaje === 'accept') {
                    this.onCloseDialog();
                  }
                })

           }



        } else {

          this.resultError = data.message;

          clearInterval(this.idInterval);


          const sheetRef = this._bottomSheet.open(AlertasConfirmComponent, {
            data: { tipoMensaje: 20 }
          });
          sheetRef.afterDismissed().subscribe(dataDismised => {
            if (dataDismised.mensaje === 'accept') {
              this.onCloseDialog();
            }
          })
        }

      });




  }

  onConsultaPagosErrados(codigoProceso:any) {

    this.filtro.idProceso = codigoProceso;

    // If the user changes the sort order, reset back to the first page.
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          let sortExpr = '';
          if (this.sort.active) {
            sortExpr = `${this.sort.active} ${this.sort.direction}`;
          }
          return this.gestionAvanceProcesosService.getPagosErrados(
                this.filtro,
                this.filter,
                this.paginator,
                this.sort);
        }),
        map((data: any) => {
          // Flip flag to show that loading has finished.
          this.isRateLimitReached = false;
          this.resultsLength = data.count;
          return data.rows;
        }),
        catchError(() => {
          // Catch if the API has reached its rate limit. Return empty data.
          this.isRateLimitReached = true;
          return observableOf([]);
        })
      ).subscribe((data: any) => {
        this.rows = [...data];

      });
}


  onConsultaPagosPendientes(codigoProceso:any) {

    this.filtro.idProceso = codigoProceso;

    // If the user changes the sort order, reset back to the first page.
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          let sortExpr = '';
          if (this.sort.active) {
            sortExpr = `${this.sort.active} ${this.sort.direction}`;
          }
          return this.gestionAvanceProcesosService.getPagosPendientes(
                this.filtro,
                this.filter,
                this.paginator,
                this.sort);
        }),
        map((data: any) => {
          // Flip flag to show that loading has finished.
          this.isRateLimitReached = false;
          this.resultsLength = data.count;
          return data.rows;
        }),
        catchError(() => {
          // Catch if the API has reached its rate limit. Return empty data.
          this.isRateLimitReached = true;
          return observableOf([]);
        })
      ).subscribe((data: any) => {
        this.rows = [...data];

      });
}



    onConsultaPagosAplicados(codigoProceso:any) {

        this.filtro.idProceso = codigoProceso;

        // If the user changes the sort order, reset back to the first page.
        this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

        merge(this.sort.sortChange, this.paginator.page)
          .pipe(
            startWith({}),
            switchMap(() => {
              let sortExpr = '';
              if (this.sort.active) {
                sortExpr = `${this.sort.active} ${this.sort.direction}`;
              }
              return this.gestionAvanceProcesosService.getPagosAplicados(
                    this.filtro,
                    this.filter,
                    this.paginator,
                    this.sort);
            }),
            map((data: any) => {
              // Flip flag to show that loading has finished.
              this.isRateLimitReached = false;
              this.resultsLength = data.count;
              return data.rows;
            }),
            catchError(() => {
              // Catch if the API has reached its rate limit. Return empty data.
              this.isRateLimitReached = true;
              return observableOf([]);
            })
          ).subscribe((data: any) => {
            this.rows = [...data];

          });
    }


    closeFinally(){
      this.dialogRef.close({
        complete: this.complete
      });
    }

    onCloseDialog() {


      this.dialogRef.close({
        complete: this.complete
      });


    }

    onRefresh() {
      this.filter.column = '';
      this.filter.value = '';
      this.filter.condition = '';

     // this.onConsultarLog();
    }

    onChangePage() {
      //this.onConsultarLog();
    }

    onSelect(event: Event, row: DetallesPagosModel, index: number) {
        this.selectedRow = row;
        this.selectedIndex = index;
    }

    onSelectColumn(name: string, columnType: string, dbName: string, sign: string) {
      this.selectedColumn.name = name;
      this.selectedColumn.dbName = dbName;
      this.selectedColumn.type = columnType;
      this.selectedColumn.sign = sign;
      this.selectedColumn.filter = (columnType === 'number') ? CONDITIONS_LIST_NUMBER : CONDITIONS_LIST;
    }

    onSelectAndEdit(event, row: DetallesPagosModel, index: number) {
      this.selectedRow = row;
      this.selectedRow._estado = 'O';
      this.selectedIndex = index;

      this.openDialog();
    }

    onApplyFilter(e: Event) {
      this.filter.column = this.selectedColumn.dbName;
      this.filter.condition = this.searchCondition[this.selectedColumn.name];
      this.filter.value = this.searchValue[this.selectedColumn.name];

      let evt = new PageEvent();
      evt.pageIndex = 0;
      this.paginator.page.emit(evt);
    }

    onClearColumn(e: Event) {
      this.searchValue[this.selectedColumn.name] = '';
      this.searchCondition[this.selectedColumn.name] = '';
      this.onRefresh();
    }

    onExportCsv(e: Event) { }


    onExport() {
      Utils.onExport(`${this.titulo}`, this.rows, [], this._bottomSheet);
    }

    onTotals(data: any) {
      Object.assign(this.selectedRow, data);
    }

    openDialog(): void {
      const dialogRef = this.dialog.open(GestionAvanceProcesosMultiplesDialog, {
        data: {
          selected: this.selectedRow
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          Object.assign(this.selectedRow, result.data);
          switch (this.selectedRow._estado )
          {
            case 'N':
              this.selectedRow._estado = 'O';
              this.selectedIndex = this.rows.length;
              this.rows.push(this.selectedRow);
              this.rows = [...this.rows];
              this.resultsLength = this.rows.length;
              break;
            case 'D':
              this.rows.splice(this.selectedIndex, 1);
              this.rows = [...this.rows];
              this.selectedIndex = -1;
              this.selectedRow = null;
              break;
          }
        }
      });
    }

    openNotificationDanger(message: string, action?: string) {
      this._snackBar.open(message, action, {
          duration: 2000,
          panelClass: 'dangerSnackBar',
      });
   }






   getUniqueListBy(arr, key) {
    return [...new Map(arr.map(item => [item[key], item])).values()]
  }




}
