import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, map, tap, retry } from 'rxjs/operators';
import { MatPaginator, MatSort } from '@angular/material';

import { environment } from '../../../../../src/environments/environment';

import {MarcaFraudeModel } from './marcarfraude.model';

@Injectable({ providedIn: 'root' })
export class marcaFraudeService {
    private reactivacionCarpetasUrl = '' ; 
    private desactivacionCarpetasUrl='';  // URL to web api

    constructor(private http: HttpClient) {
        this.reactivacionCarpetasUrl = `${environment.dataServiceUrl}/Cartera/FCambioFraudeBayport/Marcar`;
        this.desactivacionCarpetasUrl = `${environment.dataServiceUrl}/Cartera/FCambioFraudeBayport/Desmarcar`;
    }

    addMotivoFraude(row: MarcaFraudeModel): Observable<MarcaFraudeModel> {
        console.log("row ", row);
        return this.http.post<MarcaFraudeModel>(this.reactivacionCarpetasUrl, MarcaFraudeModel.clone(row)).pipe(
            // retry(3),
            tap((rrow: MarcaFraudeModel) => this.log(`added MarcaFraude w/ id=/${row}`)),
            catchError((error) => this.handleError('Registro MarcaFraude', error))
        );
    }

    desmarcarMotivoFraude(row: MarcaFraudeModel): Observable<MarcaFraudeModel> {
        console.log("row ", row);
        return this.http.post<MarcaFraudeModel>(this.desactivacionCarpetasUrl, MarcaFraudeModel.clone(row)).pipe(
            // retry(3),
            tap((rrow: MarcaFraudeModel) => this.log(`added MarcaFraude w/ id=/${row}`)),
            catchError((error) => this.handleError('Registro MarcaFraude', error))
        );
    }
    
    filterMarcaFraude(val: any): Observable<any> {
        
        const sUrl = `${environment.dataServiceUrl}/WorkFlow/MotivoResultadoFraude/Pagina`;

        let params = new HttpParams();
 
        params = params.append('ordenColumna', 'descripcion_motivo_devolucion');
        params = params.append('ordenTipo', 'asc');

        params = params.append('filtroValor', ' ');
        params = params.append('filtroCondicion', 'like');
        params = params.append('filtroColumna', 'descripcion_motivo_devolucion');

        params = params.append('paginaTamano', '10');
        params = params.append('paginaNumero', '1');

        return this.http.get<any>(sUrl, { params: params }).pipe(
            retry(3),
            tap(_ => this.log(`filter dddw_marca_fraudeid=${val}`)),
            catchError((error) => this.handleError('filterdddw_marca_fraude', error))
        );
    }

    getByIdfilterMarcaFraude(val: any, ): Observable<any> {
        console.log("val get by id ", val);
        const sUrl = `${environment.dataServiceUrl}/WorkFlow/MotivoResultadoFraude/${val.codigoMotivoFraude}`;
        let params = new HttpParams();
        

        return this.http.get<any>(sUrl).pipe(
            retry(3),
            tap(_ => this.log(`getByIdfilterMarcaFraude id=${val}`)),
            catchError((error) => this.handleError('getByIdfilterMarcaFraude', error))
        );
    }

    private handleError(operation = 'operation', result?: any) {

        // TODO: send the error to remote logging infrastructure
        console.error(result.error); // log to console instead

        // TODO: better job of transforming error for user consumption
        this.log(`${operation} failed: ${result.message}`);

        // Let the app keep running by returning an empty result.
        return of(result);
    }

    /** Log a DevolucionEstadoService message with the MessageService */
    private log(message: string) {
        // this.messageService.add(`DevolucionEstadoService: ${message}`);
        console.log(`Marcacion Especial Carpeta: ${message}`);
    }

}
