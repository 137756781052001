import { FormControl } from "@angular/forms";

export class DialogValidators {

    static autocomplete(control: FormControl) {
        const value: string = control.value;
        const selected = control["selected"] ? true : false;
        if (value == '') {
            return null;
        } else {
            return !selected ? { value: true } : null;
        }
    }

    static autocompleteCodigo(control: FormControl) {
        const value: number = control.value;
        switch (value) {
            case -1: return { value: true };
            default: return null;
        };
    }

    static espacios(control: FormControl) {
        const value: string = control.value;
        if (value == '' || value == null) {
            return null;
        } else if (value.substring(0, 1) == ' ') {
            return { value: true };
        } else if (value.substring(value.length - 1) == ' ') {
            return { value: true };
        } else {
            return null;
        };
    }

    static numContinuo(control: FormControl) {
        var contador = 0;
        const value: string = String(control.value);
        if (value == '' || value == null) {
            return null;
        } else {
            for (let i = 1; i < value.length; i++) {
                if (value[i - 1] === value[i]) {
                    contador += 1;
                } else {
                    contador = 0;
                };

                if (contador > 4) {
                    return { value: true };
                }
            };
            return null;
        };
    }

    
    static numSecuencia(control: FormControl) {
        const arreglos: string = String(control.value);
        var contador = 0;
        var i2 = 1;
        const consecutivos: Array<any> = [];
        if (arreglos == '' || arreglos == null) {
            return null;
        } else {
            for (let i = 1; i < arreglos.length; i++) {
                if (Number(arreglos[i]) + 1 === Number(arreglos[i + 1])) {
                    consecutivos.push(arreglos[i]);
                    if (Number(consecutivos[i2 - 1]) + 1 === Number(arreglos[i + 1])) {
                        contador += 1;
                        i2++;
                    }
                } else {
                    i2 = 1;
                };
            };
            if (contador > 3) {
                return { value: true };
            }
        }
        return null;
    }


}
