import { Component } from '@angular/core';
import { ControlComponent } from './control.component';

@Component({
    selector: 'groupbox',
    templateUrl: './groupbox.component.html'
})
export class GroupBoxComponent extends ControlComponent {
    //public text: string = "";
    public TypeOf: string = "groupbox";

}
