import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, map, tap, retry } from 'rxjs/operators';
import { MatPaginator, MatSort } from '@angular/material';

import { environment } from '../../../../environments/environment';

import { DocumentoDesembolsoModel } from './documentodesembolso.model';
import * as moment from 'moment';

@Injectable({ providedIn: 'root' })
export class DocumentoDesembolsoService {
  private documentoDesembolsoUrl = '';  // URL to web api
  private documentoEstaticoUrl = '';  // URL to web api

  constructor(private http: HttpClient) {
    this.documentoDesembolsoUrl = `${environment.dataServiceUrl}/Cartera/DocumentoDesembolso`;
    this.documentoEstaticoUrl = `${environment.dataServiceUrl}/Parametros/DocumentoEstatico`;
  }

  getDocumentoDesembolso(row: DocumentoDesembolsoModel): Observable<DocumentoDesembolsoModel> {
    const sUrl = `${this.documentoDesembolsoUrl}/${row.secuencia}`;

    return this.http.get<DocumentoDesembolsoModel>(sUrl).pipe(

      tap(() => this.log('fetched DocumentoDesembolso')),
      catchError((error) => this.handleError('getDocumentoDesembolso', error))
    );
  }

  getDocumentoDesembolsoAll(): Observable<any> {
    return this.http.get<any>(this.documentoDesembolsoUrl, {}).pipe(

      tap(row => this.log('fetched DocumentoDesembolso')),
      catchError((error) => this.handleError('getDocumentoDesembolsoList', error))
    );
  }

  getDocumentoDesembolsoList(
    filter: {
      value: any,
      condition: string,
      column: string
    },
    paginator: MatPaginator,
    sort: MatSort, pagaduria: number, fechaDesembolso: string): Observable<any> {

    let params = new HttpParams();
    if (filter.condition != '') {
      params = params.append('filtroValor', filter.value);
      params = params.append('filtroCondicion', filter.condition);
      params = params.append('filtroColumna', filter.column);
      params = params.append('filtroRelacion', 'and');
    }

    params = params.append('paginaNumero', (paginator.pageIndex + 1).toString());
    params = params.append('paginaTamano', paginator.pageSize.toString());
    params = params.append('ordenColumna', sort.active || 'secuencia');
    params = params.append('ordenTipo', sort.direction || 'asc');

    params = params.append('filtroValor', String(pagaduria));
    params = params.append('filtroCondicion', '=');
    params = params.append('filtroColumna', 'pagaduria');

    params = params.append('filtroRelacion', 'or');

    params = params.append('filtroValor', '0');
    params = params.append('filtroCondicion', '=');
    params = params.append('filtroColumna', 'tipo_documento');

    const sUrl = `${this.documentoDesembolsoUrl}/${moment(fechaDesembolso).format('YYYY-MM-DD')}/Pagina`;

    return this.http.get<any>(sUrl, { params }).pipe(

      tap(row => this.log('fetched DocumentoDesembolso')),
      catchError((error) => this.handleError('getDocumentoDesembolsoList', error))
    );
  }

  addDocumentoDesembolso(row: DocumentoDesembolsoModel): Observable<DocumentoDesembolsoModel> {
    return this.http.post<DocumentoDesembolsoModel>(this.documentoDesembolsoUrl, [DocumentoDesembolsoModel.clone(row)]).pipe(

      tap((rrow: DocumentoDesembolsoModel) => this.log(`added DocumentoDesembolso w/ id=/${row.secuencia}`)),
      catchError((error) => this.handleError('addDocumentoDesembolso', error))
    );
  }

  updateDocumentoDesembolso(row: DocumentoDesembolsoModel, original: DocumentoDesembolsoModel): Observable<DocumentoDesembolsoModel> {
    return this.http.put<DocumentoDesembolsoModel>(this.documentoDesembolsoUrl, DocumentoDesembolsoModel.clone(row)).pipe(

      tap(_ => this.log(`update DocumentoDesembolso id=/${row.secuencia}`)),
      catchError((error) => this.handleError('updateDocumentoDesembolso', error))
    );
  }

  saveDocumentoDesembolso(row: DocumentoDesembolsoModel, original: DocumentoDesembolsoModel): Observable<DocumentoDesembolsoModel> {
    if (row._estado === 'N') {
      return this.addDocumentoDesembolso(row);
    } else {
      return this.updateDocumentoDesembolso(row, original);
    }
  }

  deleteDocumentoDesembolso(row: DocumentoDesembolsoModel): Observable<DocumentoDesembolsoModel> {
    const sUrl = `${this.documentoDesembolsoUrl}/${row.secuencia}`;

    return this.http.delete(sUrl).pipe(

      tap(_ => this.log(`filter DocumentoDesembolso id=/${row.secuencia}`)),
      catchError((error) => this.handleError('deleteDocumentoDesembolso', error))
    );
  }

  saveRowsDocumentoDesembolso(rows: Array<DocumentoDesembolsoModel>): Observable<any> {
    const _rows = rows.map((row) => DocumentoDesembolsoModel.clone(row));
    return this.http.post<any>(this.documentoDesembolsoUrl, _rows).pipe(

      tap((rrows: DocumentoDesembolsoModel) => this.log(`pasted rows in DocumentoDesembolso `)),
      catchError((error) => this.handleError('addDocumentoDesembolso', error))
    );
  }

  filterDwMotivoBloqueo(val: string): Observable<any> {
    const sUrl = `${environment.dataServiceUrl}/Parametros/PlantillaHtml/Pagina`;

    let params = new HttpParams();
    if (val != '') {
      params = params.append('filtroValor', val);
      params = params.append('filtroCondicion', 'like');
      params = params.append('filtroColumna', 'nombre');
    };
    params = params.append('paginaTamano', '10');
    params = params.append('paginaNumero', '1');
    params = params.append('ordenColumna', 'nombre');
    params = params.append('ordenTipo', 'asc');

    return this.http.get<any>(sUrl, { params: params }).pipe(
      tap(_ => this.log(`filter dw_motivo_bloqueo id=${val}`)),
      catchError((error) => this.handleError('filterdw_motivo_bloqueo', error))
    );
  }

  getByIdDwMotivoBloqueo(val: any): Observable<any> {
    const sUrl = `${environment.dataServiceUrl}/Parametros/PlantillaHtml/${val}`;

    return this.http.get<any>(sUrl).pipe(

      tap(_ => this.log(`getByIdDwMotivoBloqueo id=${val}`)),
      catchError((error) => this.handleError('getByIdDwMotivoBloqueo', error))
    );
  }

  getAllDwMotivoBloqueo() {
    const sUrl = `${environment.dataServiceUrl}/Parametros/PlantillaHtml`;

    const params: any = {};

    return this.http.get<any>(sUrl, { params: params }).pipe(

      tap(_ => this.log(`GetAll DwMotivoBloqueo`)),
      catchError((error) => this.handleError('GetAllDwMotivoBloqueo', error))
    );

  }

  getAllDocumentoEstaticoList(): Observable<any> {
    const sUrl = `${this.documentoEstaticoUrl}`;

    return this.http.get<any>(sUrl).pipe(

      tap(row => this.log('fetched DocumentoEstatico')),
      catchError((error) => this.handleError('getDocumentoEstaticoList', error))
    );
  }

  getAllDocumentoEstaticoDetail(secuencia): Observable<any> {
    const sUrl = `${this.documentoEstaticoUrl}/${secuencia}`;
    return this.http.get<any>(sUrl).pipe(
      tap(row => this.log('fetched DocumentoEstatico')),
      catchError((error) => this.handleError('getDocumentoEstaticoList', error))
    );
  }


  filterDwEnteRegula(val: string): Observable<any> {
    const sUrl = `${environment.dataServiceUrl}/Libranza/MaestroPagaduria/Pagina`;
    let params = new HttpParams();
    if (val != '') {
      params = params.append('filtroValor', val);
      params = params.append('filtroCondicion', 'like');
      params = params.append('filtroColumna', 'nombre_pagaduria');
    };
    params = params.append('paginaTamano', '10');
    params = params.append('paginaNumero', '1');
    params = params.append('ordenColumna', 'nombre_pagaduria');
    params = params.append('ordenTipo', 'asc');

    return this.http.get<any>(sUrl, { params: params }).pipe(
      tap(_ => this.log(`filter dw_ente_regula id=${val}`)),
      catchError((error) => this.handleError('filterdw_ente_regula', error))
    );
  }

  getByIdDwEnteRegula(val: any): Observable<any> {
    const sUrl = `${environment.dataServiceUrl}/Libranza/MaestroPagaduria/${val}`;

    return this.http.get<any>(sUrl).pipe(

      tap(_ => this.log(`getByIdDwEnteRegula id=${val}`)),
      catchError((error) => this.handleError('getByIdDwEnteRegula', error))
    );
  }

  getAllDwEnteRegula() {
    const sUrl = `${environment.dataServiceUrl}/Libranza/MaestroPagaduria/`;

    const params: any = {};

    return this.http.get<any>(sUrl, { params: params }).pipe(

      tap(_ => this.log(`GetAll DwEnteRegula`)),
      catchError((error) => this.handleError('GetAllDwEnteRegula', error))
    );

  }

  generarPDF(data: any): Observable<any> {

    let urlXML = '';
    if (data.plantilla == 30) {
      urlXML = `${environment.dataServiceUrl}/Reportes/GeneracionPlantilla/pdfInfo/${data.plantilla}/${Number(data.codigoCarpeta)}`;
    } else {
      urlXML = `${environment.dataServiceUrl}/Reportes/GeneracionPlantilla/pdfInfo/${data.plantilla}/${Number(data.empresa)}%7C${data.codigoCarpeta}`;
    }
    return this.http.get<any>(urlXML, {}).pipe(
      tap(_ => this.log(`update pdf`)),
      catchError((error) => this.handleError('pdf error', error))
    );

  }

  downloadExpediente(codigoCarpeta: any): Observable<any> {
    const sUrl = `${environment.dataServiceUrl}/SolicitudCredito/consultaExpCom/${codigoCarpeta}`;

    return this.http.get<any>(sUrl).pipe(
      tap(row => this.log('fetched DocumentoProceso')),
      catchError((error) => this.handleError('getDocumentoProcesoList', error))
    );
  }


  downloadExpedientillo(codigoCarpeta: any): Observable<any> {
    const sUrl = `${environment.dataServiceUrl}/SolicitudCredito/consultaExpedientillo/${codigoCarpeta}`;

    return this.http.get<any>(sUrl).pipe(
      tap(row => this.log('fetched DocumentoProceso')),
      catchError((error) => this.handleError('consultaExpedientillo', error))
    );
  }

  envioCorreoDesembolso(numeroCarpeta: number, numeroProceso: number) {
    let url = `${environment.dataServiceUrl}/WorkFlow/FEnvioCorreoDesembolso/Pqr/${numeroCarpeta}/${numeroProceso}`;
    return this.http.get<any>(url).pipe(
      tap(_ => this.log(`update pdf`)),
      catchError((error) => this.handleError('pdf error', error))
    );
  }

  correoDesembolso(numeroCarpeta: number) {
    let url = `${environment.dataServiceUrl}/WorkFlow/FEnvioCorreoDesembolso/Correo/${numeroCarpeta}`;
    return this.http.get<any>(url).pipe(
      tap(_ => this.log(`update pdf`)),
      catchError((error) => this.handleError('pdf error', error))
    );
  }

  getDocumentoTramiteList(val: any, codFolder: any, linea: any, codEmpresa: any): Observable<any> {
    let params = new HttpParams();

    params = params.append('filtroValor', val || '');
    params = params.append('filtroCondicion', 'like');
    params = params.append('filtroColumna', 'nombre_documento');
    params = params.append("filtroRelacion", "and");

    params = params.append("filtroValor", codFolder);
    params = params.append("filtroCondicion", "=");
    params = params.append("filtroColumna", "numero_carpeta");

    params = params.append("filtroRelacion", "and");
    params = params.append("filtroValor", linea);
    params = params.append("filtroCondicion", "=");
    params = params.append("filtroColumna", "linea_credito");

    params = params.append("filtroRelacion", "and");
    params = params.append("filtroValor", codEmpresa);
    params = params.append("filtroCondicion", "=");
    params = params.append("filtroColumna", "codigo_empresa");

    params = params.append("filtroRelacion", "and");
    params = params.append('filtroValor', "0");
    params = params.append('filtroCondicion', '=');
    params = params.append('filtroColumna', 'ind_expediente_com');

    // params = params.append("filtroRelacion", "and");
    // params = params.append('filtroValor', '2');
    // params = params.append('filtroCondicion', '=');
    // params = params.append('filtroColumna', 'FASE_DIGITALIZACION' );

    params = params.append("filtroRelacion", "and");
    params = params.append('filtroValor', '1');
    params = params.append('filtroCondicion', '!=');
    params = params.append('filtroColumna', 'ind_carga_expediente_completo');

    params = params.append("filtroRelacion", "and");
    params = params.append('filtroValor', '1');
    params = params.append('filtroCondicion', '!=');
    params = params.append('filtroColumna', 'ind_carga_expedientillo');

    params = params.append("filtroRelacion", "and");
    params = params.append('filtroValor', '1');
    params = params.append('filtroCondicion', '!=');
    params = params.append('filtroColumna', 'ind_carga_impresion');

    params = params.append("filtroRelacion", "and");
    params = params.append('filtroValor', '1');
    params = params.append('filtroCondicion', '!=');
    params = params.append('filtroColumna', 'ind_carga_solo_firmas');

    params = params.append("paginaTamano", "10");
    params = params.append("paginaNumero", "1");
    params = params.append("ordenColumna", "orden");
    params = params.append("ordenTipo", "asc");

    const sUrl = `${environment.dataServiceUrl}/WorkFlow/DocumentoTramite/Pagina`;
    return this.http.get<any>(sUrl, { params }).pipe(
      tap((row) => this.log("fetched DocumentoTramite")),
      catchError((error) => this.handleError("getDocumentoTramiteList", error))
    );
  }

  private handleError(operation = 'operation', result?: any) {

    // TODO: send the error to remote logging infrastructure
    console.error(result.error); // log to console instead

    // TODO: better job of transforming error for user consumption
    this.log(`${operation} failed: ${result.message}`);

    // Let the app keep running by returning an empty result.
    return of(result);
  }

  /** Log a DocumentoDesembolsoService message with the MessageService */
  private log(message: string) {
    // this.messageService.add(`DocumentoDesembolsoService: ${message}`);
    console.log(`DocumentoDesembolsoService: ${message}`);
  }

  downloadPdf(data) {
    const sUrl = `${environment.dataServiceUrl}/Reportes/GeneracionPlantilla/pdfInfo/16/${data}`
    return this.http.get<any>(sUrl).pipe(
      tap(row => this.log('fetched ConsultaDatos')),
      catchError((error) => this.handleError('getConsultaDatosList', error))
    );
  }

  envioEstadoDesembolso(row: any) {
    let url = `${environment.dataServiceUrl}/Cartera/FEnvioEstadoDesembolso`
    return this.http.post<any>(url, row).pipe(
      tap(row => this.log('envio realizado')),
      catchError((error) => this.handleError('getConsultaDatosList', error))
    );
  }

  consultaLogPdl(proceso: string): Observable<any> {
    const sUrl = `${environment.dataServiceUrl}/Identity/DocumentoProceso/Pagina`;

    const params: any = {
      filtroValor: proceso,
      filtroCondicion: '=',
      filtroColumna: 'llave_busqueda',
      paginaTamano: 1,
      paginaNumero: 1,
      ordenColumna: 'llave_busqueda',
      ordenTipo: 'asc'
    };

    return this.http.get<any>(sUrl, { params: params }).pipe(
      tap(_ => this.log(`filter consultaLogPdl`)),
      catchError((error) => this.handleError('filterConsultaLogPdl', error))
    );
  }

  getPasos(ventana: string , proceso?: string): Observable<any> {
    let params = new HttpParams();
    const sUrl = `${environment.dataServiceUrl}/Identity/GestionProceso/Pagina`;
    params = params.append('paginaTamano', '999');
    params = params.append('paginaNumero', '1');
    params = params.append('ordenColumna', 'orden');
    params = params.append('ordenTipo', 'asc');

    params = params.append('filtroValor', ventana);
    params = params.append('filtroCondicion', '=');
    params = params.append('filtroColumna', 'ventana');
    params = params.append('filtroRelacion', 'and');

    if(proceso !== undefined){
      params = params.append('filtroValor', proceso);
      params = params.append('filtroCondicion', '=');
      params = params.append('filtroColumna', 'UO_EVENTO');
      params = params.append('filtroRelacion', 'and');
    }

    params = params.append('filtroValor', '1');
    params = params.append('filtroCondicion', '=');
    params = params.append('filtroColumna', 'codigo_empresa');

    return this.http.get<any>(sUrl, { params }).pipe(
        tap(() => this.log('fetched PASOS')),
        catchError((error) => this.handleError('getCarpeta', error))
    );
  }
  cartaFiniquito(nroCredito: string): Observable<any> {
    const sUrl = `${environment.dataServiceUrl}/Cartera/DocumentoDesembolso/Finiquito/${nroCredito}`;
    return this.http.get<any>(sUrl, {}).pipe(
      tap(_ => this.log(`filter consultaLogPdl`)),
      catchError((error) => this.handleError('filterConsultaLogPdl', error))
    );
  }
}
