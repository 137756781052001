
import { DataWindowComponent } from './datawindow.component';
 
export class DataStoreComponent extends DataWindowComponent {
    TypeOf: string = "datastore";

    constructor()  {
        super(null);
    }
}
