import { Component, ViewChild, AfterViewInit, Inject } from '@angular/core';
import { MatPaginator, MatSort, PageEvent, MatDialog, MatSnackBar, MAT_DIALOG_DATA, MatDialogRef, MatBottomSheet } from '@angular/material';
import { merge, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { AlertasConfirmComponent } from 'src/app/components/alertasConfirm.component';

import { GestionAvanceProcesosMultiplesDialog } from './gestionavanceprocesosmultiples.dialog';
import { GestionAvanceProcesosMultiplesService } from './gestionavanceprocesosmultiples.service';
import { GestionAvanceProcesosModel } from './gestionavanceprocesosmultiples.model';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

import { ChartDataSets, ChartOptions, ChartPoint, ChartType } from 'chart.js';

import { Label, Color } from 'ng2-charts';

declare var CONDITIONS_LIST: any;
declare var CONDITIONS_LIST_NUMBER: any;
declare var appComponent: any;

@Component({
  selector: 'gestionavanceprocesos-table',
  templateUrl: './gestionavanceprocesosmultiples.table.html',
  styleUrls: ['./gestionavanceprocesosmultiples.table.css'],
  providers: [GestionAvanceProcesosMultiplesService]
})
export class GestionAvanceProcesosMultiplesTable implements AfterViewInit  {
    rows: GestionAvanceProcesosModel[] = [];
    selectedRow: GestionAvanceProcesosModel;
    selectedIndex: number = 0;

    barra = true;

    idInterval: any;
    progreso: number = 0;
    procesosRealizados: number = 0;
    totalProcesos: number = 0;
    complete: boolean = false;
    mensajeRespuesta: string = '';
    verGrafica1 = false;

    registroTotal = 0;
    procesosTotal = 0;

    intervalSingle:any;
    progressSingle = 0;

	//VARIABLES GRAFICAS
  public barChartOptions: ChartOptions = { responsive: true,
    scales: {
      yAxes: [
           {
              ticks: {
                  min: 0,
                  max: 50
              }
           }
      ]
  }
};

  public barChartOptions1: ChartOptions = { responsive: true,
  scales: {
      yAxes: [
           {
              ticks: {
                  min: 0,
                  max: 50
              }
           }
      ]
  }
};

  public barChartTypeHorizontalBar: ChartType = 'horizontalBar';

  public lineChartType = 'line';

  public lineChartType1 = 'line';

	public barChartType: ChartType = 'bar';

  public barChartLabels: Label[];

  public barChartLabels1: Label[];

  public barChartLegend = true;

	public barChartPlugins = [];



  public barChartLegend1 = true;

	public barChartPlugins1 = [];


	// COLORES GRAFICAS
	public backgroundColorList: any = [	'#0d99ba','#0b85a2','#09728b','#085f74','#064c5d','#043945','#03262e','#258fdc', '#3899e8', '#48a4f3', '#0d99ba','#0b85a2','#09728b','#085f74','#064c5d','#043945','#03262e','#258fdc','#57afff', '#65baff', '#72c5ff',' #7fd0ff',	'#0d99ba','#0b85a2','#09728b','#085f74','#064c5d'];

	public pieChartColors:any = [
		{
			backgroundColor: ['#0d99ba','#0b85a2','#09728b','#085f74','#064c5d','#043945','#03262e','#258fdc', '#3899e8', '#48a4f3', '#0d99ba','#0b85a2','#09728b','#085f74','#064c5d','#043945','#03262e','#258fdc','#57afff', '#65baff', '#72c5ff',' #7fd0ff']
		},];

	public lineChartColors1: Color[] = [
		{ // grey
		  backgroundColor: 'rgba(148,159,177,0)',
		  borderColor: '#006699',
		  pointBackgroundColor: this.backgroundColorList,
		  pointBorderColor: '#700404',
		  pointHoverBackgroundColor: '#fff',
		  pointHoverBorderColor: 'rgba(148,159,177,0.8)'
		},
		{ // grey
			backgroundColor: 'rgba(0, 137, 171, 0)',
			borderColor: '#3399FF',
			pointBackgroundColor: this.backgroundColorList,
			pointBorderColor: '#fff',
			pointHoverBackgroundColor: '#fff',
			pointHoverBorderColor: 'rgba(148,159,177,0)'
      },
      { // grey
        backgroundColor: 'rgba(0, 137, 171, 0)',
        borderColor: '#339966',
        pointBackgroundColor: this.backgroundColorList,
        pointBorderColor: '#0F0E4D',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgba(148,159,177,0.8)'
        },
        { // grey
          backgroundColor: 'rgba(0, 137, 171, 0)',
          borderColor: '#FF5C33',
          pointBackgroundColor: this.backgroundColorList,
          pointBorderColor: '#fff',
          pointHoverBackgroundColor: '#fff',
          pointHoverBorderColor: 'rgba(148,159,177,0.8)'
      },
      { // grey
        backgroundColor: 'rgba(0, 137, 171, 0)',
        borderColor: '#B8B894',
        pointBackgroundColor: this.backgroundColorList,
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    },
    { // grey
      backgroundColor: 'rgba(0, 137, 171, 0)',
      borderColor: '#FF5C33',
      pointBackgroundColor: this.backgroundColorList,
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)'
  },
  { // grey
    backgroundColor: 'rgba(0, 137, 171, 0)',
    borderColor: '#83FF00',
    pointBackgroundColor: this.backgroundColorList,
    pointBorderColor: '#fff',
    pointHoverBackgroundColor: '#fff',
    pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    },
    { // grey
      backgroundColor: 'rgba(0, 137, 171, 0)',
      borderColor: '#1A60C6',
      pointBackgroundColor: this.backgroundColorList,
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)'
      },
      { // grey
        backgroundColor: 'rgba(0, 137, 171, 0)',
        borderColor: '#C6751A',
        pointBackgroundColor: this.backgroundColorList,
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgba(148,159,177,0.8)'
        },
        { // grey
          backgroundColor: 'rgba(0, 137, 171, 0)',
          borderColor: '#63C61A',
          pointBackgroundColor: this.backgroundColorList,
          pointBorderColor: '#fff',
          pointHoverBackgroundColor: '#fff',
          pointHoverBorderColor: 'rgba(148,159,177,0.8)'
          },
          { // grey
            backgroundColor: 'rgba(0, 137, 171, 0)',
            borderColor: '#1AC3C6',
            pointBackgroundColor: this.backgroundColorList,
            pointBorderColor: '#fff',
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: 'rgba(148,159,177,0.8)'
            },
            { // grey
              backgroundColor: 'rgba(148,159,177,0)',
              borderColor: '#09728b',
              pointBackgroundColor: this.backgroundColorList,
              pointBorderColor: '#fff',
              pointHoverBackgroundColor: '#fff',
              pointHoverBorderColor: 'rgba(148,159,177,0.8)'
            },
            { // grey
              backgroundColor: 'rgba(0, 137, 171, 0)',
              borderColor: '#1AC1C6',
              pointBackgroundColor: this.backgroundColorList,
              pointBorderColor: '#fff',
              pointHoverBackgroundColor: '#fff',
              pointHoverBorderColor: 'rgba(148,159,177,0.8)'
              }
    ];

    public lineChartColors: Color[] = [
      { // grey
        backgroundColor: 'rgba(148,159,177,0)',
        borderColor: '#09728b',
        pointBackgroundColor: this.backgroundColorList,
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgba(148,159,177,0.8)'
      },
      { // grey
        backgroundColor: 'rgba(0, 137, 171, 0)',
        borderColor: '#72c5ff',
        pointBackgroundColor: this.backgroundColorList,
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgba(148,159,177,0.8)'
        },
        { // grey
          backgroundColor: 'rgba(0, 137, 171, 0)',
          borderColor: '#72c5ff',
          pointBackgroundColor: this.backgroundColorList,
          pointBorderColor: '#fff',
          pointHoverBackgroundColor: '#fff',
          pointHoverBorderColor: 'rgba(148,159,177,0.8)'
          },
          { // grey
            backgroundColor: 'rgba(0, 137, 171, 0)',
            borderColor: '#52c5ff',
            pointBackgroundColor: this.backgroundColorList,
            pointBorderColor: '#fff',
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: 'rgba(148,159,177,0.8)'
            },
            { // grey
              backgroundColor: 'rgba(0, 137, 171, 0)',
              borderColor: '#42c5ff',
              pointBackgroundColor: this.backgroundColorList,
              pointBorderColor: '#fff',
              pointHoverBackgroundColor: '#fff',
              pointHoverBorderColor: 'rgba(148,159,177,0.8)'
              },
              { // grey
                backgroundColor: 'rgba(0, 137, 171, 0)',
                borderColor: '#83FF00',
                pointBackgroundColor: this.backgroundColorList,
                pointBorderColor: '#fff',
                pointHoverBackgroundColor: '#fff',
                pointHoverBorderColor: 'rgba(148,159,177,0.8)'
                },
                { // grey
                  backgroundColor: 'rgba(0, 137, 171, 0)',
                  borderColor: '#1A60C6',
                  pointBackgroundColor: this.backgroundColorList,
                  pointBorderColor: '#fff',
                  pointHoverBackgroundColor: '#fff',
                  pointHoverBorderColor: 'rgba(148,159,177,0.8)'
                  },
                  { // grey
                    backgroundColor: 'rgba(0, 137, 171, 0)',
                    borderColor: '#C6751A',
                    pointBackgroundColor: this.backgroundColorList,
                    pointBorderColor: '#fff',
                    pointHoverBackgroundColor: '#fff',
                    pointHoverBorderColor: 'rgba(148,159,177,0.8)'
                    },
                    { // grey
                      backgroundColor: 'rgba(0, 137, 171, 0)',
                      borderColor: '#63C61A',
                      pointBackgroundColor: this.backgroundColorList,
                      pointBorderColor: '#fff',
                      pointHoverBackgroundColor: '#fff',
                      pointHoverBorderColor: 'rgba(148,159,177,0.8)'
                      },
                      { // grey
                        backgroundColor: 'rgba(0, 137, 171, 0)',
                        borderColor: '#1AC3C6',
                        pointBackgroundColor: this.backgroundColorList,
                        pointBorderColor: '#fff',
                        pointHoverBackgroundColor: '#fff',
                        pointHoverBorderColor: 'rgba(148,159,177,0.8)'
                        }
      ];


    	// VARIABLES PARA DATA DE GRAFICAS
	public numeroProcesosEjecutados: any = [];
	public fecha: number[] | ChartPoint[];
  public segTiempoProcXRegistro: any = [];


  public data1: any = [];

	public totalRegistros: any = [];
	public totalProcesosFecha: any;
  public verGrafica = false;


    	// DATA PARA GRAFICAS
	public tittle: string = '';
	public barChartDataProcesosEjecutados:  ChartDataSets[] = [];
	public barChartDataTotalRegistros: ChartDataSets[] = [];
	public barChartDataSegTiempoRegistro:  ChartDataSets[] = [];
  public barChartDataTiempoxRegistros:  ChartDataSets[] = [];


  //TEST1

  public barChartTest1:  ChartDataSets[] = [];



    public displayedColumns: string[] = [ 'codigo_proceso', 'nombre_evento', 'descripcion', 'codigo_retorno', 'usuario', 'fecha_evento', 'fecha_negocio' ];

    public conditionsList = CONDITIONS_LIST;
    public conditionsListNumber = CONDITIONS_LIST_NUMBER;
    public searchValue: any = {};
    public searchCondition: any = {};
    public _pageSize = 10;

    filter = {
      column: '',
      condition: '',
      value: ''
    };

    selectedColumn = {
      name: '',
      dbName: '',
      type: '',
      sign: '',
      filter: []
    };

    filtro = {
      idProceso: 0,
      codEmpresa: 0,
      lista: []
    };

    resultsLength = 0;
    isLoadingResults = false;
    isRateLimitReached = false;

    numeroProceso:any = 0;

    intervalId:any;
    procesoFin:any = [];

    @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
    @ViewChild(MatSort, {static: false}) sort: MatSort;
    @BlockUI() blockUI: NgBlockUI;

    _proc: boolean = false;
    _status: boolean = false;
    resultError: string = null;

    list:any = [];

    single:any;


    constructor(public dialog: MatDialog,
                private _snackBar: MatSnackBar,
                private _bottomSheet: MatBottomSheet,
                private gestionAvanceProcesosService: GestionAvanceProcesosMultiplesService,
                public dialogRef: MatDialogRef<GestionAvanceProcesosMultiplesTable>,
                @Inject(MAT_DIALOG_DATA) public data: any) {



        this.dialogRef.disableClose = true;
        this.filtro.idProceso = data.idProceso;
        this.filtro.lista = data.lista;

        this.single = data.single;

        this.numeroProceso = data.numeroProceso;
        this.filtro.codEmpresa = Number(appComponent.usuario.mpr);

        this.iniciarLista();
    }



    iniciarLista(){

      for(let i=0; i < this.filtro.lista.length; i++){

        this.list.push({
          codigoProceso: this.filtro.lista[i],
          procesoRealizados: 0,
          totalProceso: 0,
          progreso: 0,
          mensajeRespuesta: ''
        });

        this.orderdata();

      }


    }
    ngAfterViewInit() {


      if(!this.single){


      let contSuccess = 0;


      this.intervalId =  setInterval(() => {

        let cad = this.filtro.lista.toString();

         this.gestionAvanceProcesosService.graficaGeneral(cad).subscribe(res => {


             this.orderDataGrafica(res);

         });


       },20000);



      this.idInterval = setInterval(() => {



      for (var numeroPagina = 0; numeroPagina < this.filtro.lista.length; numeroPagina++ ){



        this.filtro.idProceso = this.filtro.lista[numeroPagina];



        this.onCalcularAvance(this.filtro);



        this.onConsultarLog(this.filtro.lista[0]);


        this.orderdata();



        if(this.filtro.lista[numeroPagina] == this.list[numeroPagina].codigoProceso){



            if(this.list[numeroPagina].progreso >= 100){




              if(this.procesoFin.length < this.filtro.lista.length){

                if(this.procesosTotal == this.registroTotal){
                  this.procesoFin.push(this.list[numeroPagina].codigoProceso);
                  contSuccess++;
                }

              }/*else{

                 let ban = this.procesoFin.filter( res => res == this.list[numeroPagina].codigoProceso);


                 if(ban.length == 0){
                    this.procesoFin.push(this.list[numeroPagina].codigoProceso);

                    ++contSuccess;
                 }
              }*/



              //this.onCalcularAvance(this.filtro);

            }




        }



        if(contSuccess ==  this.filtro.lista.length){


          this.complete = true;

          this.barra = false;

          clearInterval(this.idInterval);

          clearInterval(this.intervalId);

          const sheetRef = this._bottomSheet.open(AlertasConfirmComponent, {
            data: { tipoMensaje: 20 }
          });

          sheetRef.afterDismissed().subscribe(dataDismised => {
            if (dataDismised.mensaje === 'accept') {
              this.onCloseDialog();
            }
          });

        }


      }


      this.list = [];

    }, 10000);




      }else{

        this.numeroProceso = 1;






        this.intervalSingle = setInterval(() => {

          let cad = this.filtro.lista.toString();


           this.gestionAvanceProcesosService.graficaGeneral(cad).subscribe(res => {


              this.orderdataSingle(res,  res[0].codigo_proceso);


        });


          this.onConsultarLog(this.filtro.idProceso);

          if (this.progressSingle < 100) {
            this.onCalcularAvanceSingle(this.filtro);
            this.orderdata();
          } else {
            this.complete = true;
            this.barra = false;
            clearInterval(this.intervalSingle);

            const sheetRef = this._bottomSheet.open(AlertasConfirmComponent, {
              data: { tipoMensaje: 20 }
            });
            sheetRef.afterDismissed().subscribe(dataDismised => {
              if (dataDismised.mensaje === 'accept') {
                this.onCloseDialog();
              }
            })


          };


       }, 3500);



      }

    }


    onCalcularAvanceSingle(filtro:any){


      this.list = [];

      this.gestionAvanceProcesosService.getAvanceProcesosAll(filtro).subscribe((data: any) => {

        this._status = !!data.error;
        this.resultError = null;

        if (!this._status) {


            this.progressSingle = ((data.rows[0].registroActual / data.rows[0].totalRegistros) * 100);

            this.list.push({
              codigoProceso: data.rows[0].codigoProceso,
              procesoRealizados: data.rows[0].registroActual,
              totalProceso: data.rows[0].totalRegistros,
              progreso: ((data.rows[0].registroActual / data.rows[0].totalRegistros) * 100),
              mensajeRespuesta: data.rows[0].mensajeActual
            });


            this.procesosTotal =  data.rows[0].registroActual;
            this.registroTotal =  data.rows[0].totalRegistros;
            this.progreso =  (( this.procesosTotal / this.registroTotal ) * 100);


        } else {
          this.resultError = data.message;
        }

      });



    }

    onCalcularAvance(filtro:any) {



        this.gestionAvanceProcesosService.getAvanceProcesosAll(filtro).subscribe((data: any) => {

          this._status = !!data.error;
          this.resultError = null;

          if (!this._status) {


            let obj = this.list.filter( res => res.codigoProceso  == data.rows[0].codigoProceso);

            if(obj.length == 0){

              this.list.push({
                codigoProceso: data.rows[0].codigoProceso,
                procesoRealizados: data.rows[0].registroActual,
                totalProceso: data.rows[0].totalRegistros,
                progreso: ((data.rows[0].registroActual / data.rows[0].totalRegistros) * 100),
                mensajeRespuesta: data.rows[0].mensajeActual
              });




              this.list.sort((a, b) => Number(a.codigoProceso) - Number(b.codigoProceso))

            }

            this.procesosTotal = 0;
            this.registroTotal = 0;
            this.progreso = 0;

            this.list.forEach(element => {

              this.procesosTotal = this.procesosTotal + element.procesoRealizados;
              this.registroTotal = this.registroTotal + element.totalProceso;
              this.progreso =  (( this.procesosTotal / this.registroTotal ) * 100);

            });






          } else {
            this.resultError = data.message;
          }

        });




    }

    onConsultarLog(codigoProceso:any) {

        this.filtro.idProceso = codigoProceso;

        // If the user changes the sort order, reset back to the first page.
        this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

        merge(this.sort.sortChange, this.paginator.page)
          .pipe(
            startWith({}),
            switchMap(() => {
              let sortExpr = '';
              if (this.sort.active) {
                sortExpr = `${this.sort.active} ${this.sort.direction}`;
              }
              return this.gestionAvanceProcesosService.getConsultaLogAvanceProcesosList(
                    this.filtro,
                    this.filter,
                    this.paginator,
                    this.sort);
            }),
            map((data: any) => {
              // Flip flag to show that loading has finished.
              this.isRateLimitReached = false;
              this.resultsLength = data.count;
              return data.rows;
            }),
            catchError(() => {
              // Catch if the API has reached its rate limit. Return empty data.
              this.isRateLimitReached = true;
              return observableOf([]);
            })
          ).subscribe((data: any) => {
            this.rows = [...data];
          });
    }

    onCloseDialog() {
      clearInterval(this.idInterval);


      const sheetRef = this._bottomSheet.open(AlertasConfirmComponent, {
        data: { tipoMensaje: 137 }
      });
      sheetRef.afterDismissed().subscribe(dataDismised => {
        if (dataDismised.mensaje === 'accept') {
          //this.onCloseDialog();
          this.dialogRef.close({
            complete: this.complete
          });
        }
      })




    }

    onRefresh() {
      this.filter.column = '';
      this.filter.value = '';
      this.filter.condition = '';

     // this.onConsultarLog();
    }

    onChangePage() {
      //this.onConsultarLog();
    }

    onSelect(event: Event, row: GestionAvanceProcesosModel, index: number) {
        this.selectedRow = row;
        this.selectedIndex = index;
    }

    onSelectColumn(name: string, columnType: string, dbName: string, sign: string) {
      this.selectedColumn.name = name;
      this.selectedColumn.dbName = dbName;
      this.selectedColumn.type = columnType;
      this.selectedColumn.sign = sign;
      this.selectedColumn.filter = (columnType === 'number') ? CONDITIONS_LIST_NUMBER : CONDITIONS_LIST;
    }

    onSelectAndEdit(event, row: GestionAvanceProcesosModel, index: number) {
      this.selectedRow = row;
      this.selectedRow._estado = 'O';
      this.selectedIndex = index;

      this.openDialog();
    }

    onApplyFilter(e: Event) {
      this.filter.column = this.selectedColumn.dbName;
      this.filter.condition = this.searchCondition[this.selectedColumn.name];
      this.filter.value = this.searchValue[this.selectedColumn.name];

      let evt = new PageEvent();
      evt.pageIndex = 0;
      this.paginator.page.emit(evt);
    }

    onClearColumn(e: Event) {
      this.searchValue[this.selectedColumn.name] = '';
      this.searchCondition[this.selectedColumn.name] = '';
      this.onRefresh();
    }

    onExportCsv(e: Event) { }

    onTotals(data: any) {
      Object.assign(this.selectedRow, data);
    }

    openDialog(): void {
      const dialogRef = this.dialog.open(GestionAvanceProcesosMultiplesDialog, {
        data: {
          selected: this.selectedRow
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          Object.assign(this.selectedRow, result.data);
          switch (this.selectedRow._estado )
          {
            case 'N':
              this.selectedRow._estado = 'O';
              this.selectedIndex = this.rows.length;
              this.rows.push(this.selectedRow);
              this.rows = [...this.rows];
              this.resultsLength = this.rows.length;
              break;
            case 'D':
              this.rows.splice(this.selectedIndex, 1);
              this.rows = [...this.rows];
              this.selectedIndex = -1;
              this.selectedRow = null;
              break;
          }
        }
      });
    }

    openNotificationDanger(message: string, action?: string) {
      this._snackBar.open(message, action, {
          duration: 2000,
          panelClass: 'dangerSnackBar',
      });
   }



   orderDataGrafica(data:any) {


        for (var numeroPagina = 0; numeroPagina < this.filtro.lista.length; numeroPagina++ ){



              let filtro = data.filter(res => res.codigo_proceso == this.filtro.lista[numeroPagina]);


              this.orderdataPrincipal(filtro, this.filtro.lista[numeroPagina]);

        }
   }


   orderdataSingle(data: Array<any>, proceso?:any) {

    let labels = [];



		for (let d of data) {

      this.data1.push(d.lotes_procesados);  //segTiempoProcXRegistro
      labels.push(d.linea_tiempo)
			/*this.totalProcesosFecha = this.totalProcesosFecha + d.fecha;
			labels.push(d.fecha);
			this.numeroProcesosEjecutados.push(d.nroProcesosEjecutados);
			this.totalRegistros.push(d.totalRegistros);
      this.backgroundColorList.push(d.color)*/

		}

		this.barChartLabels1 = labels;

    let datas:any = {
            data: this.data1,
            label: proceso,
            backgroundColor: this.backgroundColorList,
            borderCapStyle: "round",
            barThickness: 10,
          }


   // this.barChartTest1.push(datas);



      if(this.barChartTest1.length == 0){
        this.barChartTest1.push(datas);
      }else{
        this.barChartTest1 = [];
        this.barChartTest1.push(datas);
      }

		this.verGrafica = true;
  }

   getUniqueListBy(arr, key) {
    return [...new Map(arr.map(item => [item[key], item])).values()]
  }


   orderdataPrincipal(data: Array<any>, proceso?:any) {

    let labels = [];
    this.data1 = [];


		for (let d of data) {

      this.data1.push(d.lotes_procesados);  //segTiempoProcXRegistro
      labels.push(d.linea_tiempo)

		}

		this.barChartLabels1 = labels;

    let datas:any = {
            data: this.data1,
            label: proceso,
            backgroundColor: this.backgroundColorList,
            borderCapStyle: "round",
            barThickness: 10,
          }

    console.log(datas);

    this.barChartTest1.push(datas);

    this.barChartTest1 = this.getUniqueListBy(this.barChartTest1, 'label');


		this.verGrafica = true;
	}

   orderdata(data?: Array<any>) {

    let labels = [];
    this.segTiempoProcXRegistro = [];

    let datas = [{ id: 1, time: 4}]

		for (let d of datas) {

      this.segTiempoProcXRegistro.push(d.time);  //segTiempoProcXRegistro
      labels.push(d.id);
			/*this.totalProcesosFecha = this.totalProcesosFecha + d.fecha;
			labels.push(d.fecha);
			this.numeroProcesosEjecutados.push(d.nroProcesosEjecutados);
			this.totalRegistros.push(d.totalRegistros);
      this.backgroundColorList.push(d.color)*/

		}

		this.barChartLabels = labels;



		this.barChartDataSegTiempoRegistro = [
			{
				data: this.segTiempoProcXRegistro,
				label: 'Tiempo(Seg) Proceso Registro',
				backgroundColor: this.backgroundColorList,
				borderCapStyle: "round",
				barThickness: 10,
      }
		]


		this.verGrafica1 = true;
	}
}
