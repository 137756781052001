import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError} from 'rxjs/operators';
import { environment } from '../../../../environments/environment';

@Injectable({ providedIn: 'root' })

export class ConfirmarAcuerdoPagoService {
    private url = '';  // URL to web api

    constructor(private http: HttpClient) {
        this.url = `${environment.dataServiceUrl}/Identity/ConfirmarAcuerdoPago/`;
    }

    confirmar(token: string): Observable<any> {
        return this.http.post<any>(this.url + token, null).pipe(
            catchError((error) => this.handleError('addCarpeta', error))
        );
    }

    private handleError(operation = 'operation', result?: any) {

        // TODO: send the error to remote logging infrastructure
        console.error(result.error); // log to console instead

        // TODO: better job of transforming error for user consumption
        this.log(`${operation} failed: ${result.message}`);

        // Let the app keep running by returning an empty result.
        return of(result);
    }
    /** Log a dw_wfc_carpeta_maestro_detalleService message with the MessageService */
    private log(message: string) {
        // this.messageService.add(`dw_wfc_carpeta_maestro_detalleService: ${message}`);
        console.log(`dw_wfc_carpeta_maestro_detalleService: ${message}`);
    }

}
