import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { Trazabilidad } from './trazabilidad'
import { environment } from '../../../../../environments/environment';
@Injectable({
	providedIn: 'root'
})
export class TrazabilidadService {
	colorProceso$ = new EventEmitter();
	private urlTrazabilidad = '';  // URL to web api

	constructor(private http: HttpClient) {
		this.urlTrazabilidad = `${environment.dataServiceUrl}/EstructuraComercial/SemaforoEC`;
	}

	/**
	 * 
	 * @param row objeto tipo trazabilidad
	 */
	public semaforo(row: Trazabilidad): Observable<Trazabilidad> {
		return this.http.post<Trazabilidad>(this.urlTrazabilidad, row).pipe(
			tap((rrow: Trazabilidad) => this.log(`added RegistroDireccion w/ id=/${row}`)),
			catchError((error) => this.handleError('addRegistroDireccion', error))
		);
	}

	/** Log a RegistroDireccionService message with the MessageService */
	private log(message: string) {
		// this.messageService.add(`RegistroDireccionService: ${message}`);
		console.log(`RegistroDireccionService: ${message}`);
	}

	private handleError(operation = 'operation', result?: any) {

		// TODO: send the error to remote logging infrastructure
		console.error(result.error); // log to console instead

		// TODO: better job of transforming error for user consumption
		this.log(`${operation} failed: ${result.message}`);

		// Let the app keep running by returning an empty result.
		return of(result);
	}
}
