import { Component, ViewChild, AfterViewInit, Input, Output, EventEmitter, ElementRef, Inject } from '@angular/core';
import { MatPaginator, MatSort, PageEvent } from '@angular/material';
import { merge, Observable, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar, MatBottomSheet, MatDialog } from '@angular/material';
import { DocumentoDesembolsoService } from './documentodesembolso.service';
import Utils from '../../../services/utilidades';
import * as PB from 'src/app/components/powerbuilder.functions';
import { DocumentoDesembolsoVisual } from './tipodocumentooriginacion.visual';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { HttpClient } from '@angular/common/http';
import { AwsComponent } from 'src/app/components/aws.component';
import { VisualizarDocumentosVisual } from '../../../components/visualizardocumentos/visualizardocumentos.visual';
import { DocumentosDownDialog } from './viewdoctos.dialog';
import { DocumentoRegeneradoCarteraTable } from '../documentoregeneradocartera/documentoregeneradocartera.table';

declare var CONDITIONS_LIST: any;
declare var CONDITIONS_LIST_NUMBER: any;
declare var appComponent: any;

@Component({
	selector: 'documentodesembolso-table',
	templateUrl: './documentodesembolso.table.html',
	styleUrls: ['./documentodesembolso.table.css'],
	providers: [DocumentoDesembolsoService]
})
export class DocumentoDesembolsoTable implements AfterViewInit {
	PBF = PB;

	DwMotivoBloqueo: any[] = [];
	DwEnteRegula: any[] = [];
	rows: any[] = [];
	originals: any[] = [];
	DwDocEstaticos: any[] = [];
	selectedRow: any ={};
	selectedIndex: number = 0;
	originalRow: any;
  consultaRegenerado: boolean =  false;

  descargaPdf: boolean =  false;
  previsualizacionPdf: boolean =  false;
  enviarCorreoPdf: boolean =  false;

	public displayedColumns: string[] = ['cod_plantilla', 'tipo_documento', 'pagaduria', 'documento', 'envio_correo'];

	public conditionsList = CONDITIONS_LIST;
	public conditionsListNumber = CONDITIONS_LIST_NUMBER;
	public searchValue: any = {};
	public searchCondition: any = {};
	public _pageSize = 10;

	filter = {
		column: '',
		condition: '',
		value: ''
	};

	selectedColumn = {
		name: '',
		dbName: '',
		type: '',
		sign: '',
		filter: []
	};

	resultsLength = 0;
	isLoadingResults = true;
	isRateLimitReached = false;

	@ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
	@ViewChild(MatSort, { static: false }) sort: MatSort;
	@BlockUI() blockUI: NgBlockUI;

	_proc: boolean = false;
	_status: boolean = false;
	resultError: string = null;
	title: string = '';
	codigoPagaduria: number;
	codigoCarpeta: string;
	correoCliente: any = {};
  	indEnvioPdfDesembolso: number = 0;
  	numeroProceso: string = 'Sin Asociar';
	lineaCredito: any;
  fechaDesembolso: string;
  despachos: boolean = false;

	constructor(public dialog: MatDialog,
				private http: HttpClient,
				private _snackBar: MatSnackBar,
				private _bottomSheet: MatBottomSheet,
				private awsComponent: AwsComponent,
				private documentoDesembolsoService: DocumentoDesembolsoService,
				public dialogRef: MatDialogRef<DocumentoDesembolsoTable>,
				@Inject(MAT_DIALOG_DATA) public data: any) {

    this.despachos = data.despachos;
		this.codigoCarpeta = data.nroCredito;
		this.codigoPagaduria = data.codigoPagaduria;
		this.indEnvioPdfDesembolso = data.indEnvioPdfDesembolso;
		this.lineaCredito = data.lineaCredito;
    this.fechaDesembolso = data.fechaDesembolso;
		if (data.numeroProceso !== undefined) { this.numeroProceso = String(data.numeroProceso) };
    this.controlDocumentosDesembolso();
		this.dialogRef.disableClose = true;
	}

	ngAfterViewInit() {
		this.blockUI.start('Cargando Información...'); // Start blocking
		this.documentoDesembolsoService.getAllDwMotivoBloqueo().subscribe((data: any) => {
			this.blockUI.stop();
			this.DwMotivoBloqueo = [...data];
		});

		this.documentoDesembolsoService.getAllDocumentoEstaticoList().subscribe((data: any) => {
			this.DwDocEstaticos = data;
		});

		this.blockUI.start('Cargando Información...'); // Start blocking
		this.documentoDesembolsoService.getAllDwEnteRegula().subscribe((data: any) => {
			this.blockUI.stop();
			this.DwEnteRegula = [...data];
		});

		// If the user changes the sort order, reset back to the first page.
		this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

		merge(this.sort.sortChange, this.paginator.page)
			.pipe(
				startWith({}),
				switchMap(() => {
					this.isLoadingResults = true;
					let sortExpr = '';
					if (this.sort.active) {
						sortExpr = `${this.sort.active} ${this.sort.direction}`;
					}
					return this.documentoDesembolsoService.getDocumentoDesembolsoList(
						this.filter,
						this.paginator,
						this.sort,
						this.codigoPagaduria, this.fechaDesembolso);
				}),
				map((data: any) => {
					// Flip flag to show that loading has finished.
					this.isLoadingResults = false;
					this.isRateLimitReached = false;
					this.resultsLength = data.count;
					return data.rows;
				}),
				catchError(() => {
					this.isLoadingResults = false;
					// Catch if the API has reached its rate limit. Return empty data.
					this.isRateLimitReached = true;
					return observableOf([]);
				})
			).subscribe((data: any) => {
        // this.cartaFiniquito();
				this.rows = [...data];
        this.documentoDesembolsoService.cartaFiniquito(this.codigoCarpeta).subscribe(data => {
          this._status = !!data.error;
          this.resultError = null;
          if (!this._status) {
              let rowsCopy = this.rows;
              rowsCopy.push(data);
              this.rows = [...rowsCopy];
          }
        });
				this.originals = data;
				this.correo();
			});
	}

	onAdd(): void {
		this.selectedRow =  {};
		this.originalRow = {};
		this.selectedIndex = 0;

		this.openDialog();
	}

	onEdit(): void {
		if (this.selectedIndex >= 0) {
			this.selectedRow._estado = 'O';
			this.openDialog();
		}
	}

	onRefresh() {
		this.filter.column = '';
		this.filter.value = '';
		this.filter.condition = '';

		this.ngAfterViewInit();
	}

	onChangePage() {
		this.ngAfterViewInit();
	}

	onSelect(event: Event, row: any, index: number) {
		this.selectedRow = row;
		this.selectedIndex = index;
		this.originalRow = this.originals[index];
	}

	onSelectAndEdit(event, row: any, index: number) {
		this.selectedRow = row;
		this.selectedRow._estado = 'O';
		this.selectedIndex = index;
		this.originalRow = this.originals[index];
		this.originalRow._estado = 'O';

		this.openDialog();
	}

	onSelectColumn(e: Event, name: string, columnType: string, dbName: string, sign: string) {
		this.selectedColumn.name = name;
		this.selectedColumn.dbName = dbName;
		this.selectedColumn.type = columnType;
		this.selectedColumn.sign = sign;
		this.selectedColumn.filter = (columnType === 'number') ? CONDITIONS_LIST_NUMBER : CONDITIONS_LIST;
	}

	onApplyFilter(e: Event) {
		this.filter.column = this.selectedColumn.dbName;
		this.filter.condition = this.searchCondition[this.selectedColumn.name];
		this.filter.value = this.searchValue[this.selectedColumn.name];

		let evt = new PageEvent();
		evt.pageIndex = 0;
		this.paginator.page.emit(evt);
	}

	onClearColumn(e: Event) {
		this.searchValue[this.selectedColumn.name] = '';
		this.searchCondition[this.selectedColumn.name] = '';
		this.onRefresh();
	}

	onTotals(data: any) {
		Object.assign(this.selectedRow, data);
	}

	openDialog(): void {

	}

	openNotificationDanger(message: string, action?: string) {
		this._snackBar.open(message, action, {
			duration: 2000,
			panelClass: 'dangerSnackBar',
		});
	}

	getChildDwMotivoBloqueo(row: any) {
		//let d = this.DwMotivoBloqueo.find(val => val.secuencia === id) || {};

		if(row.anexo == 0 || row.anexo == 2){
			let d = this.DwMotivoBloqueo.find(val => val.secuencia === row.codPlantilla) || {};
			return d.nombre || '';
		}else{
			let d = this.DwDocEstaticos.find(val => val.secuencia === row.codPlantilla) || {};
			return d.nombreAnexo || '';
		}
	}

	getChildDwEnteRegula(id: number) {
		let d = this.DwEnteRegula.find(val => val.codigoPagaduria === id) || {};
		return d.nombrePagaduria || '';
	}

	openNotificationSuccess(message: string, action?: string) {
        this._snackBar.open(message, action, {
            duration: 2000,
            panelClass: 'successSnackBar',
        });
        this.dialogRef.close();
	}

	onTitle(trans: any) {
		this.title = trans
	}

	onExport() {
		Utils.onExport(`${this.title}`, this.originals, [], this._bottomSheet);
	}

	viewPdf(row: any) {
    if(this.previsualizacionPdf){
      if(row.anexo == 2){
        this.dialog.open(VisualizarDocumentosVisual, {
          data: {
            selected: row
          }
        });
      }else{
        let data = {
          plantilla: row.codPlantilla,
          empresa: appComponent.usuario.mpr,
          codigoCarpeta: this.codigoCarpeta,
          anexo: row.anexo
        }
        this.dialog.open(DocumentoDesembolsoVisual, {
          data: {
            selected: data
          }
        });
      }
    }else{
      this.openNotificationDanger("No tiene permisos para previsualizar");
    }
	}

	viewFiles() {
    if(this.descargaPdf){
      let data = {
        codigoCarpeta: this.codigoCarpeta,
        lineaCredito: this.lineaCredito,
        despachos: this.despachos
      }
      this.dialog.open(DocumentosDownDialog, {
        data: data
      });
    }else{
      this.openNotificationDanger('No tiene permisos para descargar documentos');
    }

	}

	sendMail() {
		this.documentoDesembolsoService.envioCorreoDesembolso(Number(this.codigoCarpeta), Number(this.numeroProceso)).subscribe(
			(resp: any) => {
				this._proc = false;
				this._status = !!resp.error;
				this.resultError = null;

				if (!this._status) {
					this.openNotificationSuccess('Documentos enviados');
				} else {
					this.resultError = resp.message.mensaje;
					this.openNotificationDanger(this.resultError);
				};
			}
		);
	}


	correo() {
		this.documentoDesembolsoService.correoDesembolso(Number(this.codigoCarpeta)).subscribe(
			(resp: any) => {
				if(resp){
					this.correoCliente = resp;
				}
			}
		);
  }

  sendMailUnitario(row: any) {
    if(this.enviarCorreoPdf){
      let data = {
        plantilla: row.codPlantilla,
        empresa: appComponent.usuario.mpr,
        codigoCarpeta: this.codigoCarpeta,
        anexo: row.anexo
      }
      let obj: any = {};
      obj.nombreFormato = this.getChildDwMotivoBloqueo(row);
      obj.nroCredito = this.codigoCarpeta;
      obj.numeroProceso = this.numeroProceso;
      if (Object.keys(this.correoCliente).length == 0) {
          this.openNotificationDanger("Aviso!, correo no valido.");

      } else {
      obj.correo = this.correoCliente.correo;
      if (data.anexo == 0) {
        this.documentoDesembolsoService.generarPDF(data).subscribe((data: any) => {
          obj.rutaProceso = data.rutaProceso;
          this.enviarCorreo(obj);
          this.isLoadingResults = false;
        });
      } else if(data.anexo == 2){
        obj.rutaProceso = row.rutaAws;
        this.enviarCorreo(obj);
      }else{
        this.documentoDesembolsoService.getAllDocumentoEstaticoDetail(data.plantilla).subscribe(
          (resp: any) => {
              obj.rutaProceso = resp.rutaAws;
              this.enviarCorreo(obj);
              this.isLoadingResults = false;
          }
        );
      }
      }
    }else{
      this.openNotificationDanger('No tiene permisos para enviar correos');
    }


  }

  enviarCorreo(obj: any){
    if(this.enviarCorreoPdf){
    this.documentoDesembolsoService.envioEstadoDesembolso(obj).subscribe(
      (resp: any) => {
        this._proc = false;
        this._status = !!resp.error;
        this.resultError = null;

        if (!this._status) {
          this.openNotificationSuccess('Correo enviado correctamente');
        } else {
          this.resultError = resp.message.mensaje;
          this.openNotificationDanger(this.resultError)
		};

      }
    );
    }else{
      this.openNotificationDanger('No tiene permisos para enviar correos');
    }
  }

  onConsultaPld() {
	if (!this._proc) {
		this._proc = true
		let procesoPld = `dialogo-procesos.cargue.workflow.consultapld.${this.codigoCarpeta}.input`;
		this.documentoDesembolsoService.consultaLogPdl(procesoPld).subscribe(data => {
			this._status = !!data.error;
			this.resultError = null;

			if (!this._status) {
				if (data.count > 0) {
					this.onDescargarPld(data.rows[0]);
				} else {
					this.openNotificationDanger('No hay documentos en el log de consulta pld para descargar.');
				};

			} else {
				this._proc = false
				this.resultError = data.message.mensaje;
				this.openNotificationDanger(this.resultError);
			};

		})
	};
}

onDescargarPld(row: any){
	let proceso = `dialogo-procesos.cargue.workflow.consultapld.${this.codigoCarpeta}.input`;
	let urlRuta = `cargue/workflow/consultapld/${this.codigoCarpeta}/input/`;

	let archivo = row.ruta.replace(urlRuta, "") ;
	this.awsComponent.downloadFile(proceso, archivo)
	  .subscribe((data: any) => {
		this.awsComponent.download_file(data.url).subscribe((file: Blob) => {
			var url = window.URL.createObjectURL(file);
			var anchor = document.createElement("a");
			anchor.download = row.nombreArchivo;
			anchor.href = url;
			anchor.click();
			this._proc = false;
		});
	});
  }


  consultaRegenerados() {
		let data = {
			codigoCarpeta: this.codigoCarpeta
		}
		this.dialog.open(DocumentoRegeneradoCarteraTable, {
			data: {
				selected: data
			}
		});
	}


  controlConsultaRegenerados() {
    this.documentoDesembolsoService.getPasos('w_regeneracion_documentos_wfc', 'ue_consultar').subscribe(data => {
      if (data.rows.length > 0) {
        this.consultaRegenerado = true;
      }
    });
  }

  controlDocumentosDesembolso() {
    this.documentoDesembolsoService.getPasos('w_pdf_documentos_despachos', 'ue_descargar').subscribe(data => {
      if (data.rows.length > 0) {
        this.descargaPdf = true;
      }
    });
    this.documentoDesembolsoService.getPasos('w_pdf_documentos_despachos', 'ue_previsualizar').subscribe(data => {
      if (data.rows.length > 0) {
        this.previsualizacionPdf = true;
      }
    });
    this.documentoDesembolsoService.getPasos('w_pdf_documentos_despachos', 'ue_enviar').subscribe(data => {
      if (data.rows.length > 0) {
        this.enviarCorreoPdf = true;
      }
    });
  }



}
