import { CommonModule } from '@angular/common';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { httpInterceptorProviders } from './interceptors';
import { appMaterialModule } from './app.material.module';
import { PowerbuilderSharedModule } from './components/powerbuilder.shared.module';
import { AlertasComponent } from './components/alertas.component';
import { AlertasConfirmComponent } from './components/alertasConfirm.component';
import { TableComponent } from './components/table/table.component';
import { TableTrazabilidadComponent } from './estructuracomercial/gr-gestion/componentes/table-trazabilidad/table-trazabilidad.component';
import { SocketService } from './services/socket.service';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';
import { IncidenciasComponent } from './components/incidencias/incidencias.component';
import { VideoPlayerComponent } from './components/videoplayer/videoplayer.component';
import { MultiVideoPlayerComponent } from './components/multivideoplayer/multivideoplayer.component';
import { CurrencyPipe, DatePipe, DecimalPipe } from '@angular/common';
import { OnlyNumber } from './directives/onlyNumber.directive';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { decla_insolvenciaTable } from './cartera/gr-operaciones/declaracioninsolvencia/decla_insolvenciaTable';

import { MarcaFraudeDialog } from './cartera/gr-operaciones/marcarfraude/marcarfraude.dialog';
import { TipoDocumentoPdfVisual } from './components/tipodocumentopdf.visual';
import { AutocompleteComponent } from './components/autocomplete/autocomplete.component';
import { dw_captura_pagoDialog } from './cartera/gr-consultar/dw_captura_pago/dw_captura_pago.dialog';
import { dw_captura_pagoTable } from './cartera/gr-consultar/dw_captura_pago/dw_captura_pago.table';
import { DocumentoDesembolsoTable } from './cartera/gr-gestion/documentodesembolso/documentodesembolso.table';
import { DocumentoDesembolsoVisual } from './cartera/gr-gestion/documentodesembolso/tipodocumentooriginacion.visual';
import { DocumentosDownDialog } from './cartera/gr-gestion/documentodesembolso/viewdoctos.dialog';
import { VisualizarDocumentosVisual } from './components/visualizardocumentos/visualizardocumentos.visual';
import { VisualizarDocumentosSinVisual } from './components/visualizardocumentossin/visualizardocumentossin.visual';


// import { YamiCodeSocketComponent } from './components/yami-code-socket/yami-code-socket.component';
// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

window['CONDITIONS_LIST'] = [
  { value: 'like', label: '' },
  { value: 'not like', label: '' },
  { value: '=', label: '' },
  { value: '<>', label: '' }
];

window['CONDITIONS_LIST_BOOL'] = [
  { value: '=', label: '' },
  { value: '<>', label: '' }
];

window['CONDITIONS_LIST_DATE'] = [
  { value: 'like', label: '' },
  { value: '<>', label: '' },
  { value: '>', label: '' },
  { value: '>=', label: '' },
  { value: '<', label: '' },
  { value: '<=', label: '' }
];

window['CONDITIONS_LIST_NUMBER'] = [
  { value: '>', label: '' },
  { value: '>=', label: '' },
  { value: '<', label: '' },
  { value: '<=', label: '' },
  { value: '=', label: '' },
  { value: '<>', label: '' }
];

@NgModule({
  declarations:[
    AutocompleteComponent,
    AlertasComponent,
    TableComponent,
    TableTrazabilidadComponent,
    AlertasConfirmComponent,
    IncidenciasComponent,
    VideoPlayerComponent,
    MultiVideoPlayerComponent,
    OnlyNumber,
    decla_insolvenciaTable,
    MarcaFraudeDialog,
    TipoDocumentoPdfVisual,
    dw_captura_pagoTable,
    dw_captura_pagoDialog,
    DocumentoDesembolsoTable,
    DocumentoDesembolsoVisual,
    DocumentosDownDialog,
    VisualizarDocumentosVisual,
    VisualizarDocumentosSinVisual
  ],
  imports: [
    CommonModule,
    appMaterialModule,
    PowerbuilderSharedModule,
    FormsModule,
    PdfJsViewerModule,
    ReactiveFormsModule,
    AngularEditorModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
 ],
 exports: [
    appMaterialModule,
    AutocompleteComponent,
    PowerbuilderSharedModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    CommonModule,
    PdfJsViewerModule,
    TableComponent,
    AngularEditorModule,
    TableTrazabilidadComponent,
    IncidenciasComponent,
    VideoPlayerComponent,
    MultiVideoPlayerComponent,
    VisualizarDocumentosVisual,
    OnlyNumber
  ],
  entryComponents: [
    DocumentosDownDialog,
    AutocompleteComponent,
    AlertasComponent,
    AlertasConfirmComponent,
    IncidenciasComponent,
    VideoPlayerComponent,
    MultiVideoPlayerComponent,
    decla_insolvenciaTable,
    MarcaFraudeDialog,
    TipoDocumentoPdfVisual,
    dw_captura_pagoTable,
    dw_captura_pagoDialog,
    DocumentoDesembolsoTable,
    DocumentoDesembolsoVisual,
    VisualizarDocumentosVisual,
    VisualizarDocumentosSinVisual
  ],
  providers: [SocketService, CurrencyPipe, DatePipe, DecimalPipe],

})
export class SharedModule {

  constructor(private translate: TranslateService) {
    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang('es-mx');

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    translate.use('es-mx');

    window['CONDITIONS_LIST'].map((x) => this.translate.get(`CONDITIONS_LIST.${x.value}`).subscribe((res: string) => {
      x.label = res;
    }));

    window['CONDITIONS_LIST_BOOL'].map((x) => this.translate.get(`CONDITIONS_LIST_BOOL.${x.value}`).subscribe((res: string) => {
      x.label = res;
    }));

    window['CONDITIONS_LIST_DATE'].map((x) => this.translate.get(`CONDITIONS_LIST_DATE.${x.value}`).subscribe((res: string) => {
      x.label = res;
    }));

    window['CONDITIONS_LIST_NUMBER'].map((x) => this.translate.get(`CONDITIONS_LIST_NUMBER.${x.value}`).subscribe((res: string) => {
      x.label = res;
    }));

  }
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: SharedModule,
      providers: [httpInterceptorProviders, TranslateService]
    };
  }
}
