import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, map, tap, retry } from 'rxjs/operators';
import { MatPaginator, MatSort } from '@angular/material';

import { environment } from 'src/environments/environment';

import { DocumentoRegeneradoCarteraModel } from './documentoregeneradocartera.model';
import * as moment from 'moment';

@Injectable({ providedIn: 'root' })
export class DocumentoRegeneradoCarteraService {
    private documentoDesembolsoUrl = '';  // URL to web api

    constructor(private http: HttpClient) {
        this.documentoDesembolsoUrl = `${environment.dataServiceUrl}/WorkFlow/DocRegeneracionDesembolso`;
    }

    getDocumentoRegeneradoCartera(row: DocumentoRegeneradoCarteraModel): Observable<DocumentoRegeneradoCarteraModel> {
        const sUrl = `${this.documentoDesembolsoUrl}/${row.secuencia}`;

        return this.http.get<DocumentoRegeneradoCarteraModel>(sUrl).pipe(

            tap(() => this.log('fetched DocumentoRegeneradoCartera')),
            catchError((error) => this.handleError('getDocumentoRegeneradoCartera', error))
        );
    }

    getDocumentoRegeneradoCarteraAll(): Observable<any> {
        return this.http.get<any>(this.documentoDesembolsoUrl, {}).pipe(

            tap(row => this.log('fetched DocumentoRegeneradoCartera')),
            catchError((error) => this.handleError('getDocumentoRegeneradoCarteraList', error))
        );
    }

    getDocumentoRegeneradoCarteraList(
        filter: {
            value: any,
            condition: string,
            column: string
        },
        paginator: MatPaginator,
        sort: MatSort, nroCarpeta : string, principal: boolean): Observable<any> {

        let params = new HttpParams();
        if (filter.condition != '') {
            params = params.append('filtroValor', filter.value);
            params = params.append('filtroCondicion', filter.condition);
            params = params.append('filtroColumna', filter.column);
            params = params.append('filtroRelacion', 'and');
        }

        params = params.append('paginaNumero', (paginator.pageIndex + 1).toString());
        params = params.append('paginaTamano', paginator.pageSize.toString());
        params = params.append('ordenColumna', sort.active || 'fecha_creacion');
        params = params.append('ordenTipo', sort.direction || 'desc');

        params = params.append('filtroValor', String(nroCarpeta));
        params = params.append('filtroCondicion', '=');
        params = params.append('filtroColumna', 'nro_credito');

        let sUrl = `${this.documentoDesembolsoUrl}/Pagina`;
        if(principal){
          sUrl = `${this.documentoDesembolsoUrl}/Lote/${nroCarpeta}/Pagina`;
        }

        return this.http.get<any>(sUrl, { params }).pipe(

            tap(row => this.log('fetched DocumentoRegeneradoCartera')),
            catchError((error) => this.handleError('getDocumentoRegeneradoCarteraList', error))
        );
    }

    addDocumentoRegeneradoCartera(row: DocumentoRegeneradoCarteraModel): Observable<DocumentoRegeneradoCarteraModel> {
        return this.http.post<DocumentoRegeneradoCarteraModel>(this.documentoDesembolsoUrl, [DocumentoRegeneradoCarteraModel.clone(row)]).pipe(

            tap((rrow: DocumentoRegeneradoCarteraModel) => this.log(`added DocumentoRegeneradoCartera w/ id=/${row.secuencia}`)),
            catchError((error) => this.handleError('addDocumentoRegeneradoCartera', error))
        );
    }

    updateDocumentoRegeneradoCartera(row: DocumentoRegeneradoCarteraModel, original: DocumentoRegeneradoCarteraModel): Observable<DocumentoRegeneradoCarteraModel> {
        return this.http.put<DocumentoRegeneradoCarteraModel>(this.documentoDesembolsoUrl, DocumentoRegeneradoCarteraModel.clone(row)).pipe(

            tap(_ => this.log(`update DocumentoRegeneradoCartera id=/${row.secuencia}`)),
            catchError((error) => this.handleError('updateDocumentoRegeneradoCartera', error))
        );
    }

    saveDocumentoRegeneradoCartera(row: DocumentoRegeneradoCarteraModel, original: DocumentoRegeneradoCarteraModel): Observable<DocumentoRegeneradoCarteraModel> {
        if (row._estado === 'N') {
            return this.addDocumentoRegeneradoCartera(row);
        } else {
            return this.updateDocumentoRegeneradoCartera(row, original);
        }
    }

    deleteDocumentoRegeneradoCartera(row: DocumentoRegeneradoCarteraModel): Observable<DocumentoRegeneradoCarteraModel> {
        const sUrl = `${this.documentoDesembolsoUrl}/${row.secuencia}`;

        return this.http.delete(sUrl).pipe(

            tap(_ => this.log(`filter DocumentoRegeneradoCartera id=/${row.secuencia}`)),
            catchError((error) => this.handleError('deleteDocumentoRegeneradoCartera', error))
        );
    }

    saveRowsDocumentoRegeneradoCartera(rows: Array<DocumentoRegeneradoCarteraModel>): Observable<any> {
        const _rows = rows.map((row) => DocumentoRegeneradoCarteraModel.clone(row));
        return this.http.post<any>(this.documentoDesembolsoUrl, _rows).pipe(

            tap((rrows: DocumentoRegeneradoCarteraModel) => this.log(`pasted rows in DocumentoRegeneradoCartera `)),
            catchError((error) => this.handleError('addDocumentoRegeneradoCartera', error))
        );
    }

    filterDwMotivoBloqueo(val: string): Observable<any> {
        const sUrl = `${environment.dataServiceUrl}/Parametros/PlantillaHtml/Pagina`;

        let params = new HttpParams();
        if (val != '') {
            params = params.append('filtroValor', val);
            params = params.append('filtroCondicion', 'like');
            params = params.append('filtroColumna', 'nombre');
        };
        params = params.append('paginaTamano', '10');
        params = params.append('paginaNumero', '1');
        params = params.append('ordenColumna', 'nombre');
        params = params.append('ordenTipo', 'asc');

        return this.http.get<any>(sUrl, { params: params }).pipe(
            tap(_ => this.log(`filter dw_motivo_bloqueo id=${val}`)),
            catchError((error) => this.handleError('filterdw_motivo_bloqueo', error))
        );
    }

    getByIdDwMotivoBloqueo(val: any): Observable<any> {
        const sUrl = `${environment.dataServiceUrl}/Parametros/PlantillaHtml/${val}`;

        return this.http.get<any>(sUrl).pipe(

            tap(_ => this.log(`getByIdDwMotivoBloqueo id=${val}`)),
            catchError((error) => this.handleError('getByIdDwMotivoBloqueo', error))
        );
    }

    getAllDwMotivoBloqueo() {
        const sUrl = `${environment.dataServiceUrl}/Parametros/PlantillaHtml`;

        const params: any = {};

        return this.http.get<any>(sUrl, { params: params }).pipe(

            tap(_ => this.log(`GetAll DwMotivoBloqueo`)),
            catchError((error) => this.handleError('GetAllDwMotivoBloqueo', error))
        );

    }

    filterDwEnteRegula(val: string): Observable<any> {
        const sUrl = `${environment.dataServiceUrl}/Libranza/MaestroPagaduria/Pagina`;
        let params = new HttpParams();
        if (val != '') {
            params = params.append('filtroValor', val);
            params = params.append('filtroCondicion', 'like');
            params = params.append('filtroColumna', 'nombre_pagaduria');
        };
        params = params.append('paginaTamano', '10');
        params = params.append('paginaNumero', '1');
        params = params.append('ordenColumna', 'nombre_pagaduria');
        params = params.append('ordenTipo', 'asc');

        return this.http.get<any>(sUrl, { params: params }).pipe(
            tap(_ => this.log(`filter dw_ente_regula id=${val}`)),
            catchError((error) => this.handleError('filterdw_ente_regula', error))
        );
    }

    getByIdDwEnteRegula(val: any): Observable<any> {
        const sUrl = `${environment.dataServiceUrl}/Libranza/MaestroPagaduria/${val}`;

        return this.http.get<any>(sUrl).pipe(

            tap(_ => this.log(`getByIdDwEnteRegula id=${val}`)),
            catchError((error) => this.handleError('getByIdDwEnteRegula', error))
        );
    }

    getAllDwEnteRegula() {
        const sUrl = `${environment.dataServiceUrl}/Libranza/MaestroPagaduria/`;

        const params: any = {};

        return this.http.get<any>(sUrl, { params: params }).pipe(

            tap(_ => this.log(`GetAll DwEnteRegula`)),
            catchError((error) => this.handleError('GetAllDwEnteRegula', error))
        );

    }

    generarPDF(data: any): Observable<any> {

        let urlXML = '';
        if (data.plantilla == 30) {
            urlXML = `${environment.dataServiceUrl}/Reportes/GeneracionPlantilla/pdfInfo/${data.plantilla}/${Number(data.codigoCarpeta)}`;
        } else {
            urlXML = `${environment.dataServiceUrl}/Reportes/GeneracionPlantilla/pdfInfo/${data.plantilla}/${Number(data.empresa)}%7C${data.codigoCarpeta}`;
        }
        return this.http.get<any>(urlXML, {}).pipe(
            tap(_ => this.log(`update pdf`)),
            catchError((error) => this.handleError('pdf error', error))
        );

    }

    envioCorreoDesembolso(numeroCarpeta: number) {
        let url = `${environment.dataServiceUrl}/WorkFlow/FEnvioCorreoDesembolso/CorreoRegenera/${numeroCarpeta}`;
        return this.http.get<any>(url).pipe(
            tap(_ => this.log(`update pdf`)),
            catchError((error) => this.handleError('pdf error', error))
        );
    }

    correoDesembolso(numeroCarpeta: number) {
        let url = `${environment.dataServiceUrl}/WorkFlow/FEnvioCorreoDesembolso/Correo/${numeroCarpeta}`;
        return this.http.get<any>(url).pipe(
            tap(_ => this.log(`update pdf`)),
            catchError((error) => this.handleError('pdf error', error))
        );
    }

    private handleError(operation = 'operation', result?: any) {

        // TODO: send the error to remote logging infrastructure
        console.error(result.error); // log to console instead

        // TODO: better job of transforming error for user consumption
        this.log(`${operation} failed: ${result.message}`);

        // Let the app keep running by returning an empty result.
        return of(result);
    }

    /** Log a DocumentoRegeneradoCarteraService message with the MessageService */
    private log(message: string) {
        // this.messageService.add(`DocumentoRegeneradoCarteraService: ${message}`);
        console.log(`DocumentoRegeneradoCarteraService: ${message}`);
    }

    envioEstadoDesembolso(row: any) {
      let url = `${environment.dataServiceUrl}/Cartera/FEnvioEstadoDesembolso`
      return this.http.post<any>(url, row).pipe(
        tap(row => this.log('envio realizado')),
        catchError((error) => this.handleError('getConsultaDatosList', error))
      );
    }

    cartaFiniquito(nroCredito: string): Observable<any> {
      const sUrl = `${environment.dataServiceUrl}/Cartera/DocumentoDesembolso/Finiquito/${nroCredito}`;
      return this.http.get<any>(sUrl, {}).pipe(
        tap(_ => this.log(`filter consultaLogPdl`)),
        catchError((error) => this.handleError('filterConsultaLogPdl', error))
      );
    }

    getPasos(ventana: string , proceso?: string): Observable<any> {
      let params = new HttpParams();
      const sUrl = `${environment.dataServiceUrl}/Identity/GestionProceso/Pagina`;
      params = params.append('paginaTamano', '999');
      params = params.append('paginaNumero', '1');
      params = params.append('ordenColumna', 'orden');
      params = params.append('ordenTipo', 'asc');

      params = params.append('filtroValor', ventana);
      params = params.append('filtroCondicion', '=');
      params = params.append('filtroColumna', 'ventana');
      params = params.append('filtroRelacion', 'and');

      if(proceso !== undefined){
        params = params.append('filtroValor', proceso);
        params = params.append('filtroCondicion', '=');
        params = params.append('filtroColumna', 'UO_EVENTO');
        params = params.append('filtroRelacion', 'and');
      }

      params = params.append('filtroValor', '1');
      params = params.append('filtroCondicion', '=');
      params = params.append('filtroColumna', 'codigo_empresa');

      return this.http.get<any>(sUrl, { params }).pipe(
          tap(() => this.log('fetched PASOS')),
          catchError((error) => this.handleError('getCarpeta', error))
      );

  }

  consultaLogPdl(proceso: string): Observable<any> {
    const sUrl = `${environment.dataServiceUrl}/Identity/DocumentoProceso/Pagina`;

    const params: any = {
      filtroValor: proceso,
      filtroCondicion: '=',
      filtroColumna: 'llave_busqueda',
      paginaTamano: 1,
      paginaNumero: 1,
      ordenColumna: 'llave_busqueda',
      ordenTipo: 'asc'
    };

    return this.http.get<any>(sUrl, { params: params }).pipe(
      tap(_ => this.log(`filter consultaLogPdl`)),
      catchError((error) => this.handleError('filterConsultaLogPdl', error))
    );
  }

}
