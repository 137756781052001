import { Component, ViewChild, Inject } from '@angular/core';
import { MatPaginator, MatSort, PageEvent, MatDialog, MatSnackBar, MAT_DIALOG_DATA, MatDialogRef, MatBottomSheet } from '@angular/material';
import { catchError, map, startWith, switchMap, timeout } from 'rxjs/operators';
import { merge, of as observableOf } from 'rxjs';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import {EventSourcePolyfill} from 'ng-event-source';

import { AlertasConfirmComponent } from 'src/app/components/alertasConfirm.component';
import { GestionAvanceProcesosReactiveDialog } from './gestionavanceprocesosreactive.dialog';
import { GestionAvanceProcesosReactiveService } from './gestionavanceprocesosreactive.service';
import { GestionAvanceProcesosReactiveModel } from './gestionavanceprocesosreactive.model';
import { environment } from 'src/environments/environment';

declare var CONDITIONS_LIST: any;
declare var CONDITIONS_LIST_NUMBER: any;
declare var appComponent: any;

@Component({
  selector: 'gestionavanceprocesosreactive-table',
  templateUrl: './gestionavanceprocesosreactive.table.html',
  styleUrls: ['./gestionavanceprocesosreactive.css'],
  providers: [GestionAvanceProcesosReactiveService]
})
export class GestionAvanceProcesosReactiveTable {
    rows: GestionAvanceProcesosReactiveModel[] = [];
    rowsOriginal: GestionAvanceProcesosReactiveModel[] = [];
    selectedRow: GestionAvanceProcesosReactiveModel;
    selectedIndex: number = 0;
    destino: string;

    idInterval: any;
    progreso: number = 0;
    procesosRealizados: number = 0;
    totalProcesos: number = 0;
    complete: boolean = false;
    errorProceso: boolean = false;
    mensajeRespuesta: string = '';

    public displayedColumns: string[] = ['codigo_proceso', 'nombre_evento', 'descripcion', 'codigo_retorno', 'usuario', 'fecha_evento', 'fecha_negocio'];

    public conditionsList = CONDITIONS_LIST;
    public conditionsListNumber = CONDITIONS_LIST_NUMBER;
    public searchValue: any = {};
    public searchCondition: any = {};
    public _pageSize = 10;

    filter = {
      column: '',
      condition: '',
      value: ''
    };

    selectedColumn = {
      name: '',
      dbName: '',
      type: '',
      sign: '',
      filter: []
    };

    filtro = {
      idProceso: 0,
      codEmpresa: 0
    };

    resultsLength = 0;
    isLoadingResults = false;
    isRateLimitReached = false;

    @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
    @ViewChild(MatSort, {static: false}) sort: MatSort;
    @BlockUI() blockUI: NgBlockUI;

    _proc: boolean = false;
    _status: boolean = false;
    resultError: string = null;

    evtSource: any;

    constructor(public dialog: MatDialog,
                private _snackBar: MatSnackBar,
                private _bottomSheet: MatBottomSheet,
                private gestionAvanceProcesosService: GestionAvanceProcesosReactiveService,
                public dialogRef: MatDialogRef<GestionAvanceProcesosReactiveTable>,
                @Inject(MAT_DIALOG_DATA) public data: any) {

        this.dialogRef.disableClose = true;
        this.filtro.idProceso = data.idProceso;
        this.filtro.codEmpresa = Number(appComponent.usuario.mpr);
        this.destino = data.destino === undefined ? 'Identity' : data.destino;
    }

    ngAfterViewInit() {
        this.onCalcularAvance(1);
    }

    onCalcularAvance(ini: any) {
      let url = `${environment.dataServiceUrl}/${this.destino}/AvanceProcesos/Log/${this.filtro.idProceso}`;

      if(ini == 2){
        this.rowsOriginal = [];
        this.rows = [];
      }

      this.evtSource = new EventSourcePolyfill(url, {
        headers: {
          'Content-Type': 'text/event-stream',
          'Authorization': 'Bearer ' + window.sessionStorage.getItem("token")
        }
      });

      this.evtSource.onmessage = (e) => {
        if(JSON.parse(e.data).estado === -999){
          return
        }

        let avance = JSON.parse(e.data).avance;
        let log = JSON.parse(e.data).log;

        this.procesosRealizados = avance.registroActual;
        this.totalProcesos = avance.totalRegistros;
        this.mensajeRespuesta = avance.mensajeActual;

        this.selectedRow = log;
        this.rowsOriginal.unshift(this.selectedRow);
        this.rows = this.rowsOriginal;

        if (this.procesosRealizados === 0 && this.totalProcesos === 0) {
          this.progreso = 100;
          this.errorProceso = true;
        } else {
          this.progreso = ( (this.procesosRealizados/this.totalProcesos) * 100 );
        };

        if (this.progreso >= 100) {
          this.complete = true;
          /*const sheetRef = this._bottomSheet.open(AlertasConfirmComponent, {
            data: { tipoMensaje: 20 }
          });*/

          /*sheetRef.afterDismissed().subscribe(dataDismised => {
            if(dataDismised){
              if (dataDismised.mensaje === 'accept') {
                //this.onCloseDialog();
              };
            }
          })*/
        };
      }

      this.evtSource.onerror = (e) => {
          console.log('connection error');
          console.log(e);
          this.evtSource.close();
      }

      this.evtSource.onopen = (e) => {
          console.log('connection open');
          console.log(e);
      }
    }

    onCloseDialog() {
      this.evtSource.close();
      this.dialogRef.close({
        complete: this.complete,
        errorProceso: this.errorProceso
      });
    }

    onRefresh() {
        this.gestionAvanceProcesosService.getAvanceProcesosAll(this.filtro, this.destino).subscribe((data: any) => {
          this._status = !!data.error;
          this.resultError = null;

          if (!this._status) {
            this.onCalcularAvance(2);
          } else {
            this.resultError = data.message;
            this.openNotificationDanger(this.resultError);
          };
        })
    }

    onChangePage() {
      // this.onConsultarLog();
    }

    onSelect(event: Event, row: GestionAvanceProcesosReactiveModel, index: number) {
        this.selectedRow = row;
        this.selectedIndex = index;
    }

    onSelectColumn(name: string, columnType: string, dbName: string, sign: string) {
      this.selectedColumn.name = name;
      this.selectedColumn.dbName = dbName;
      this.selectedColumn.type = columnType;
      this.selectedColumn.sign = sign;
      this.selectedColumn.filter = (columnType === 'number') ? CONDITIONS_LIST_NUMBER : CONDITIONS_LIST;
    }

    onSelectAndEdit(event, row: GestionAvanceProcesosReactiveModel, index: number) {
      this.selectedRow = row;
      this.selectedRow._estado = 'O';
      this.selectedIndex = index;

      this.openDialog();
    }

    onApplyFilter(e: Event) {
      this.filter.column = this.selectedColumn.dbName;
      this.filter.condition = this.searchCondition[this.selectedColumn.name];
      this.filter.value = this.searchValue[this.selectedColumn.name];

      let evt = new PageEvent();
      evt.pageIndex = 0;
      this.paginator.page.emit(evt);
    }

    onClearColumn(e: Event) {
      this.searchValue[this.selectedColumn.name] = '';
      this.searchCondition[this.selectedColumn.name] = '';
      this.onRefresh();
    }

    onExportCsv(e: Event) { }

    onTotals(data: any) {
      Object.assign(this.selectedRow, data);
    }

    openDialog(): void {
      const dialogRef = this.dialog.open(GestionAvanceProcesosReactiveDialog, {
        data: {
          selected: this.selectedRow
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          // Object.assign(this.selectedRow, result.data);
          // switch (this.selectedRow._estado )
          // {
          //   case 'N':
          //     this.selectedRow._estado = 'O';
          //     this.selectedIndex = this.rows.length;
          //     this.rows.push(this.selectedRow);
          //     this.rows = [...this.rows];
          //     this.resultsLength = this.rows.length;
          //     break;
          //   case 'D':
          //     this.rows.splice(this.selectedIndex, 1);
          //     this.rows = [...this.rows];
          //     this.selectedIndex = -1;
          //     this.selectedRow = null;
          //     break;
          // }
        }
      });
    }

    openNotificationDanger(message: string, action?: string) {
      this._snackBar.open(message, action, {
          duration: 2000,
          panelClass: 'dangerSnackBar',
      });
   }
}
