export class DocumentoDesembolsoModel {
    public secuencia: number;
    public codPlantilla: number;
    public pagaduria: number;
    public tipoDocumento: number;

    public _secuencia: number;
    public _estado: string = 'N';
    public _id: string;
    public _v: number;
    public _relaciones: any;

    constructor(json: any = null) {
        this._relaciones = {};
        if (json !== null) {
            this.secuencia = json.secuencia;
            this.codPlantilla = json.codPlantilla;
            this.pagaduria = json.pagaduria;
            this.tipoDocumento = json.tipoDocumento;
        }
    }

    static clone(row: DocumentoDesembolsoModel): DocumentoDesembolsoModel {
        const rowCloned = Object.assign({}, row);
        delete rowCloned._secuencia;
        delete rowCloned._estado;
        delete rowCloned._id;
        delete rowCloned._v;
        delete rowCloned._relaciones;
        return rowCloned;
    }

    toClipboard(): string {
        let result = '';

        result += `\t${this.secuencia}`;
        result += `\t${this.codPlantilla}`;
        result += `\t${this.pagaduria}`;
        result += `\t${this.tipoDocumento}`;

        return result.substring(1);
    }

    fromClipboard(value: string): DocumentoDesembolsoModel {
        const result = value.split('\t');

        this.secuencia =  Number(<any>(result[0]));
        this.codPlantilla =  Number(<any>(result[1]));
        this.pagaduria =  Number(<any>(result[2]));
        this.tipoDocumento =  Number(<any>(result[3]));

        return this;
    }
}
