import { Component } from '@angular/core';

import { ControlComponent } from './control.component';
import { MenuComponent } from './menu.component';
 
@Component({
    selector: 'window',
    templateUrl: './window.component.html',
    styleUrls: ['./window.component.css']
})
export class WindowComponent extends ControlComponent {
    public TypeOf: string = "window";

    constructor() {
        super();
        this.className = "window";
    }

    ngOnInit() {
        
    }

    ngAfterViewInit() {
    }

    on_open() {

    }
    
    public center: boolean = false;
    public toolbarvisible: boolean = false;
    workspacewidth(): any {
        throw new Error("Method not implemented.");
    }
    workspaceheight(): any {
        throw new Error("Method not implemented.");
    }
    workspacey(): any {
        throw new Error("Method not implemented.");
    }
    workspacex(): any {
        throw new Error("Method not implemented.");
    }
    on_key(arg0: any, arg1: any): any {
        throw new Error("Method not implemented.");
    }
    settoolbar(arg0: any, arg1: any): any {
        throw new Error("Method not implemented.");
    }
    setmicrohelp(arg0: any): any {
        throw new Error("Method not implemented.");
    }
    openuserobjectwithparm(arg0: any, arg1: any, arg2: any): any {
        throw new Error("Method not implemented.");
    }
    openuserobject(...args: Array<any>): any {
        throw new Error("Method not implemented.");
    }
    closeuserobject(arg0: any): any {
        throw new Error("Method not implemented.");
    }
    move(arg0: any, arg1: any): any {
        throw new Error("Method not implemented.");
    }

    public menuid: MenuComponent = null;
    public title: string;
    public windowstate: string = "";
    public windowtype: string = "";

    public changemenu(arg1: any): any {
        return null;
    };

    public getfirstsheet(): WindowComponent {
        return null;
    };

    public getnextsheet(...args: Array<any>): WindowComponent {
        return null;
    };

}
