import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ControlComponent } from './control.component';

@Component({
    selector: 'commandbutton',
    template: '<button (click)="onClick();">{{text}}</button>',
    styleUrls: ['./commandbutton.component.css']
})
export class CommandButtonComponent extends ControlComponent {
    default: boolean;
    //@Input() text: string;
    TypeOf: string = "commandbutton";

    @Output() clicked = new EventEmitter<any>();

    onClick() {
        this.clicked.emit();
    }

}
