import { Component, Inject, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material';

import { AwsComponent } from 'src/app/components/aws.component';

@Component({
  templateUrl: './tipodocumentopdf.visual.html',
})
export class TipoDocumentoPdfVisual {
    selectedTipoDocumento: any;
    tipoImpresion: number;
    tipoProceso: number;

    impresionDocumentosForm: FormGroup;
    isLoadingResults = false;
    tipo: number = 1;
    rutaAws: string;
    mensajeError: string;

    _proc: boolean = false;
    _status: boolean = false;
    resultError: string = null;

    @ViewChild('pdfViewerAutoLoad', {static: false}) pdfViewerAutoLoad;

    constructor(private awsComponent: AwsComponent,        
                @Inject(MAT_DIALOG_DATA) public data: any) {
        this.selectedTipoDocumento = data.selectedTipoDocumento;        
    }
                
    ngOnInit() {
        this.isLoadingResults = true;
        this.onCargaSimulacion();
    }

    onCargaSimulacion() {
      let folder;
      let proceso;

      if(this.selectedTipoDocumento.idRutaAws.includes('portal-agentes')){
        folder = `generacion/portal-agentes/pdf/output/${this.selectedTipoDocumento.secuencia}/`;
        proceso = `dialogo-procesos.generacion.portal-agentes.pdf.output.${this.selectedTipoDocumento.secuencia}`;
      }else if(this.selectedTipoDocumento.idRutaAws.includes('portal_agentes')){
        folder = `generacion/portal_agentes/pdf/output/${this.selectedTipoDocumento.secuencia}/`;
        proceso = `dialogo-procesos.generacion.portal_agentes.pdf.output.${this.selectedTipoDocumento.secuencia}`;
      }else if(this.selectedTipoDocumento.idRutaAws.includes('firma-digital')){
        folder = `workflow/firma-digital/${this.selectedTipoDocumento.secuencia}/`;
        proceso = `dialogo-procesos.workflow.firma-digital.${this.selectedTipoDocumento.secuencia}`;
      }   


      let archivo = this.selectedTipoDocumento.idRutaAws.replace(folder, "");
      this.awsComponent.downloadFile(proceso, archivo)
      .subscribe((data: any) => {
        this.awsComponent.download_file(data.url)
          .subscribe((file: Blob) => {
            this.pdfViewerAutoLoad.pdfSrc = file;
            this.pdfViewerAutoLoad.refresh();
            this.isLoadingResults = false;
          })
      })
    }
}
