import { Component, Inject, ElementRef, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'multivideoplayer-component',
  templateUrl: './multivideoplayer.component.html',
  styleUrls: ['./multivideoplayer.component.css'],
})
export class MultiVideoPlayerComponent {
  @ViewChild('vid', {static: false}) audioPlayer?: ElementRef<HTMLMediaElement>;

  _proc = false;
  srcImg: any = null;
  srcVid: any = null;
  srcImg2: any = null;
  srcVid2: any = null;  
  constructor(public dialogRef: MatDialogRef<any>,
              private sanitizer: DomSanitizer,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    let extension = data.content.substring(data.content.lastIndexOf('.'));
    let extension2 = data.content2.substring(data.content2.lastIndexOf('.'));

    if( extension == ".mp4" || extension == ".mov"|| extension == ".webm"){
      this.srcImg == null;
      this.loadVideo(data.fileB);
    }else if(extension == ".jpg" || extension == ".png"){
      this.srcVid == null;
      this.loadImg(data.fileB);
    }

    if( extension2 == ".mp4" || extension2 == ".mov"|| extension2 == ".webm"){
      this.srcImg2 == null;
      this.loadVideo2(data.fileB2);
    }else if(extension2 == ".jpg" || extension2 == ".png"){
      this.srcVid2 == null;
      this.loadImg2(data.fileB2);
    }    
    
  }

  async loadVideo(fileB) {
    const video: any = document.getElementsByTagName('video');
    this.srcVid = window.URL.createObjectURL(fileB);
    this.srcVid = this.sanitizer.bypassSecurityTrustUrl(this.srcVid);
    video.src = this.srcVid;
    video.autoplay = true;
    video.controls = true;
    try {
      await this.audioPlayer.nativeElement.play();
    } catch (err) {
      await this.audioPlayer.nativeElement.play();
    }
  }

  async loadImg(fileB) {
    this.srcImg = window.URL.createObjectURL(fileB);
    this.srcImg = this.sanitizer.bypassSecurityTrustUrl(this.srcImg);
  }

  async loadVideo2(fileB) {
    const video2: any = document.getElementsByTagName('video2');
    this.srcVid2 = window.URL.createObjectURL(fileB);
    this.srcVid2 = this.sanitizer.bypassSecurityTrustUrl(this.srcVid2);
    video2.src = this.srcVid2;
    video2.autoplay = true;
    video2.controls = true;
    try {
      await this.audioPlayer.nativeElement.play();
    } catch (err) {
      await this.audioPlayer.nativeElement.play();
    }
  }

  async loadImg2(fileB) {
    this.srcImg2 = window.URL.createObjectURL(fileB);
    this.srcImg2 = this.sanitizer.bypassSecurityTrustUrl(this.srcImg2);
  }  

}
