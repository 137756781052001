import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar, MatBottomSheet } from '@angular/material';
import { environment } from '../../../environments/environment';

@Component({
  templateUrl: './mfa.dialog.html',
  styleUrls: ['./mfa.dialog.css'],

})
export class MfaDialog {
  _proc: boolean = false;
  _status: boolean = false;
  resultError: string = null;
  apiUrl = "";

  constructor(private builder: FormBuilder,
    private _snackBar: MatSnackBar,
    private _bottomSheet: MatBottomSheet,
    public dialogRef: MatDialogRef<MfaDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.apiUrl = environment.dataServiceUrl + "/Identity/Usuario/"
  }

  ngOnInit() {
    let timeLeft = window['appComponent'].usuario.mfat;
    let attemptsLeft = window['appComponent'].usuario.mfaa;
    const timerElement = document.getElementById('time') as HTMLSpanElement;
    const attemptCountElement = document.getElementById('attemptCount') as HTMLSpanElement;
    const errorElement = document.getElementById('error') as HTMLParagraphElement;
    const verificationCodeInput = document.getElementById('verificationCode') as HTMLInputElement;
    const verifyButton = document.getElementById('verifyButton') as HTMLButtonElement;
    const resendButton = document.getElementById('resendButton') as HTMLButtonElement;
    const closeButton = document.getElementById('closeButton') as HTMLButtonElement;

    resendButton.style.visibility = "hidden";
    attemptCountElement.innerText = attemptsLeft;

    fetch(`${this.apiUrl}/mfa/send`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${sessionStorage.getItem('token')}`
      }
    })
      .then(response => response.json())
      .then(resultado => {
        if (resultado.codigo  === 200) {
          attemptsLeft--;
          attemptCountElement.textContent = attemptsLeft + " ";
        }
      })
      .catch(error => {
        console.error('Error al realizar la acción:', error);
      });

    verificationCodeInput.addEventListener('input', (event) => {
      verificationCodeInput.value = verificationCodeInput.value.replace(/[^0-9]/g, '');
    });

    function intervalo() {
      if (timeLeft > 0) {
        timeLeft--;
        timerElement.textContent = timeLeft + " ";
        //resendButton.style.visibility = "hidden";
      } else {
        clearInterval(countdown);
        verifyButton.style.visibility = "hidden";
        verificationCodeInput.style.visibility = "hidden";
        resendButton.style.visibility = "visible";
        errorElement.textContent = 'El tiempo de espera ha terminado';
        errorElement.style.color = "#ff0000"
        if (attemptsLeft === 0) {
          resendButton.style.visibility = "hidden";
          this.dialogRef.close({
            data: -1
          });
        }
      }
    }

    let countdown = setInterval(intervalo, 1000);

    verifyButton.addEventListener('click', () => {
      const code = verificationCodeInput.value;
      fetch(`${this.apiUrl}mfa/verify/${code}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
          }
        }).then(response => response.json())
        .then(resultado => {
          if (resultado.codigo === 200) {
            clearInterval(countdown);
            this.dialogRef.close({
              data: 1
            });
          } else {
            errorElement.textContent = 'Código no válido';
            errorElement.style.color = "#ff0000"
            verifyButton.style.visibility = "visible";
            verificationCodeInput.style.visibility = "visible";
            //resendButton.style.visibility = "visible";
            if (attemptsLeft === 0) {
              verifyButton.style.visibility = "hidden";
              verificationCodeInput.style.visibility = "hidden";
              resendButton.style.visibility = "hidden";
              this.dialogRef.close({
                data: -1
              });
            }
          }
        }).catch(error => {
          console.error('Error al cargar los datos:', error);
        });


    });

    closeButton.addEventListener('click', () => {
      this.dialogRef.close({
        data: -1
      });
    })

    resendButton.addEventListener('click', () => {
      verifyButton.style.visibility = "hidden";
      verificationCodeInput.style.visibility = "hidden";
      //resendButton.style.visibility = "visible";
      errorElement.textContent = 'Código reenviado';
      errorElement.style.color = "#0fff00"
      attemptCountElement.textContent = attemptsLeft + " ";
      timeLeft = window['appComponent'].usuario.mfat;
      attemptsLeft--;
      attemptCountElement.textContent = attemptsLeft + " ";
      clearInterval(countdown);
      countdown = setInterval(intervalo, 1000);
      verifyButton.style.visibility = "visible";
      verificationCodeInput.style.visibility = "visible";
      resendButton.style.visibility = "hidden";
      fetch(`${this.apiUrl}mfa/send`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        }
      })
        .then(response => response.json())
        .then(resultado => {
          if (resultado.codigo  === 200) {
            resendButton.style.visibility = "hidden";
          } else {
            resendButton.style.visibility = "visible";
          }
        })
        .catch(error => {
          console.error('Error al realizar la acción:', error);
        });
    });
  }

  openNotificationDanger(message: string, action?: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
      panelClass: 'dangerSnackBar',
    });
  }


}
