import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, ValidationErrors } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';

import { GestionAvanceProcesosService } from './gestionavanceprocesos.service';
import { GestionAvanceProcesosModel } from './gestionavanceprocesos.model';

@Component({
  templateUrl: './gestionavanceprocesos.dialog.html',
  providers: [GestionAvanceProcesosService]
})
export class GestionAvanceProcesosDialog {
    selectedGestionAvanceProcesos: GestionAvanceProcesosModel;

    gestionAvanceProcesosForm: FormGroup;

    _proc: boolean = false;
    _status: boolean = false;
    resultError: string = null;

    constructor(private builder: FormBuilder,
                private _snackBar: MatSnackBar,
                private gestionAvanceProcesosService: GestionAvanceProcesosService,
                public dialogRef: MatDialogRef<GestionAvanceProcesosDialog>,
                @Inject(MAT_DIALOG_DATA) public data: any) {

        this.dialogRef.disableClose = true;
        this.selectedGestionAvanceProcesos = data.selected;
    }

    ngOnInit() {
        const disabledPKey = this.selectedGestionAvanceProcesos._estado === 'O';

        this.gestionAvanceProcesosForm = this.builder.group({
            'nombreEvento': [ { value: this.selectedGestionAvanceProcesos.nombreEvento, disabled: true } ],
            'fechaEvento': [ { value: new Date(this.selectedGestionAvanceProcesos.fechaEvento), disabled: true } ],
            'codigoRetorno': [ { value: this.selectedGestionAvanceProcesos.codigoRetorno, disabled: true } ],
            'descripcion': [ { value: this.selectedGestionAvanceProcesos.descripcion, disabled: true } ],
            'usuario': [ { value: this.selectedGestionAvanceProcesos.usuario, disabled: true } ],
            'fechaNegocio': [ { value: new Date(this.selectedGestionAvanceProcesos.fechaNegocio), disabled: true } ],
            '_estado': [ { value: this.selectedGestionAvanceProcesos._estado, disabled: true } ]
        }, {
            validators: (formGroup: FormGroup): ValidationErrors | null => {
                const data = formGroup.getRawValue();
                let validationErrors = { };
                return validationErrors;
            }
        });

        this.gestionAvanceProcesosForm.valueChanges.subscribe((data) => {
            this.gestionAvanceProcesosForm.patchValue({
            }, {emitEvent: false, onlySelf: true});
        });
    }
    
    openNotificationDanger(message: string, action?: string) {
       this._snackBar.open(message, action, {
           duration: 2000,
           panelClass: 'dangerSnackBar',
       });
    }
}
