import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { LoginDialog } from '../login/logindialog/logindialog.dialog';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  isOpenDialog = false;
  authError;
  constructor(private router: Router,
    private translateService: TranslateService,
    public dialog: MatDialog, ) {
    this.authError = '';
    this.translateService.get('login').subscribe((res: any) => {
      this.authError = res.authError;
    });
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(err => {
      console.log(err);
      const error = err.message || err.statusText || err.error.message;
      if (err.status === 200) { return; }
      if (err.status === 401 || err.status === 412 || err.status === 403 /*|| err.status == 0*/) {
        if (sessionStorage.getItem('isLogin')) {//loged
          const errors = { status: err.status, message: err.error.message, error: true };
          return throwError(errors);
        } else { // notLoged
          // if (!this.isOpenDialog) {
          //   this.loginDialog();
          // }else{
          //   const errors = { status: err.status, message: err.error.message, error: true };
          //   return throwError(errors);
          // }
          // sessionStorage.removeItem("token");
          // sessionStorage.removeItem("isLogin");
          const errors = { status: err.status, message: err.error.message, error: true };
          return throwError(errors);
        }



      } else if (err.status === 409 || err.status === 404 || err.status === 500) {
        const errors = { status: err.error.status, message: err.error, error: true };
        return throwError(errors);
      } else if (err.message.includes('Unknown Error')) {
        if (!this.isOpenDialog) {
          // this.loginDialog();
        }

        const errors4 = { status: err.error.type, message: this.authError, error: true };
        return throwError(errors4);

      }
      return throwError(error);
    }));
  }

  loginDialog() {
    this.isOpenDialog = true;
    const dialogRef = this.dialog.open(LoginDialog, {
      data: {}
    });
    dialogRef.afterClosed().subscribe(result => {
      this.isOpenDialog = false;
    });
  }
}
