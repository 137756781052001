import { Component, ViewChild, AfterViewInit } from '@angular/core';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { MatSnackBar, MatBottomSheet, MatDialog, MatDialogRef } from '@angular/material';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, ValidationErrors, Validators } from '@angular/forms';

import { ProcesosAutomaticosService } from './procesosautomaticos.service';
import { ProcesosAutomaticosModel } from './procesosautomaticos.model';

@Component({
  selector: 'procesos-automaticos-table',
  templateUrl: './procesosautomaticos.table.html',
  styles: [],
  providers: [ProcesosAutomaticosService]
})
export class ProcesosAutomaticosTable {
    rows: ProcesosAutomaticosModel[] = [];
    filteredModulos: any[] = [];
    isLoadingResults = false;
    filtroForm: FormGroup;

    _proc: boolean = false;
    _status: boolean = false;
    _btnBuscar: boolean = false;
    resultError: string = null;

    public displayedColumns: string[] = ['nombre', 'inicio', 'fin'];

    constructor(public dialog: MatDialog,
                private builder: FormBuilder,
                private _snackBar: MatSnackBar,
                private _bottomSheet: MatBottomSheet,
                public dialogRef: MatDialogRef<ProcesosAutomaticosTable>,
                private ProcesosAutomaticosService: ProcesosAutomaticosService) {

                  this.dialogRef.disableClose = true;
    }

    ngOnInit() {
      this.filtroForm = this.builder.group({
        'codigoModulo': ['', Validators.required],
        'dddwNombreModulo': ['', Validators.required],
        'pathModulo':['']
      }, {
        validators: (formGroup: FormGroup): ValidationErrors | null => {
          const data = formGroup.getRawValue();
          let validationErrors = {
          };
          return validationErrors;
        }
      });

        this.filtroForm.valueChanges.subscribe((data) => {
          this.filtroForm.patchValue({
          }, { emitEvent: false, onlySelf: true });
        });

        this.filtroForm.controls.dddwNombreModulo.valueChanges
        .pipe(
          startWith(''),
          switchMap((data) => this.ProcesosAutomaticosService.getModulosList(data))
        ).subscribe((data) => {
          this.filteredModulos = data.rows
        });
    }

    onSearch() {
      if (this.filtroForm.valid) {
        this.ProcesosAutomaticosService.getProcesosAutomaticos(this.filtroForm.value).subscribe((data: any) => {
            console.log('proc autom rst ->', data);
          this._proc = false;
            this._status = !!data.error;
            this.resultError = null;
            if (!this._status) {
              this.rows = data;
            } else {
              this.resultError = 'Error';
              if (data.message.message) {
                this.resultError = data.message.message;
              }
              this.openNotificationDanger(this.resultError);
           }
        });
      }
    }

    openNotificationDanger(message: string, action?: string) {
       this._snackBar.open(message, action, {
           duration: 2000,
           panelClass: 'dangerSnackBar',
       });
    }

    onSelectModulo(opt: any) {
      this.filtroForm.patchValue({
        codigoModulo: opt.moduloCodigo,
        dddwNombreModulo: opt.moduloNombre,
        pathModulo: opt.moduloDescripcion
      });
    }

    onSelect(event: Event, row: ProcesosAutomaticosModel, index: number){
      // this.selectedRow = row;
      // this.selectedIndex = index;
      // this.originalRow = this.originals[index];
    }
}
