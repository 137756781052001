import { Component } from '@angular/core';
import { ControlComponent } from './control.component';

@Component({
    selector: 'groupbox',
    template: '<div style="boder: 2px solid black"></div>'
})
export class RectangleComponent extends ControlComponent {
	changemenu(m_fdo_clientes_formularios: any) {
		throw new Error("Method not implemented.");
	}
    //public text: string = "";
    public TypeOf: string = "rectangle";
	toolbaralignment: string;
	title: string;

}
