
export class PowerObjectComponent {
    public TypeOf: string = "powerobject";
    public className: string = "nonvisualobject";
    public classdefinition: any = null;

    constructor() { }

    public classname(arg1?: any): string {
        return this.className;
    };

    on_constructor() {

    }

    on_destructor() {
       
    }

    public closethemedata(arg0: any): any {
        throw new Error("Method not implemented.");
    }

    public handle(arg1: any): number {
        return null;
    };

    public getcontextservice(arg0: any, arg1: any): any {
        throw new Error("Method not implemented.");
    }

    public getsyscolor(arg1: any): number {
        return null;
    };

    public getthemecolor(arg0: any, arg1: any, arg2: any, arg3: any, arg4: any): any {
        throw new Error("Method not implemented.");
    }

    public gradientrect(arg0: any, arg1: any, arg2: any, arg3: any, arg4: any, arg5: any): any {
        throw new Error("Method not implemented.");
    }

    public isappthemed(): any {
        throw new Error("Method not implemented.");
    }

    public isselected(...args: Array<any>): boolean {
        throw new Error("Method not implemented.");
    }

    public isthemeactive(): any {
        throw new Error("Method not implemented.");
    }

    public setparent(...args: Array<any>): any {
        return null;
    };

    public triggerevent(arg1: any): any {
        let fx_event = this["on_" + arg1.toLowerCase()];
        if(fx_event) {
            return fx_event.apply(this);
        }
        return null;
    };

    public typeof(): any {
        return this.TypeOf;
    };

 }
