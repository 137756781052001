import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError} from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
const httpOptions = {
  observe: null,
  params: null,
  headers: new HttpHeaders({
    'Content-Type': 'application/json'  ,
    'codigo': '',
  })
};
@Injectable({ providedIn: 'root' })

export class ValidaOtpService {
    private url = '';  // URL to web api
    private urlCliente = '';
    constructor(private http: HttpClient) {
        this.url = `${environment.dataServiceUrl}/Identity/ValidaOtp/`;
        this.urlCliente = `${environment.dataServiceUrl}/Identity/ClienteValidaOtp/`;
    }

    confirmar(token: string, codigo:string , tipoValida: String): Observable<any> {

      httpOptions.headers = new HttpHeaders({
        'Content-Type': 'application/json'  ,
        'codigo': codigo ? codigo : ''
      });
      let urlPeticion = this.url + token;
      if(tipoValida == '3.0' || tipoValida == '4.0' ){
          urlPeticion = this.urlCliente + token;
      }
      httpOptions.observe = 'response';
        return this.http.post<any>(urlPeticion,{}, httpOptions).pipe(
            catchError((error) => this.handleError('addCarpeta', error))
        );
    }

    confirmarCliente(token: string, codigo:string): Observable<any> {

      httpOptions.headers = new HttpHeaders({
        'Content-Type': 'application/json'  ,
        'codigo': codigo ? codigo : ''
      });
      httpOptions.observe = 'response';
        return this.http.post<any>(this.url + token,{}, httpOptions).pipe(
            catchError((error) => this.handleError('addCarpeta', error))
        );
    }

    private handleError(operation = 'operation', result?: any) {

        // TODO: send the error to remote logging infrastructure
        console.error(result.error); // log to console instead

        // TODO: better job of transforming error for user consumption
        this.log(`${operation} failed: ${result.message}`);

        // Let the app keep running by returning an empty result.
        return of(result);
    }
    /** Log a dw_wfc_carpeta_maestro_detalleService message with the MessageService */
    private log(message: string) {
        // this.messageService.add(`dw_wfc_carpeta_maestro_detalleService: ${message}`);
        console.log(`dw_wfc_carpeta_maestro_detalleService: ${message}`);
    }

}
