import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, ValidationErrors } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar, MatBottomSheet, MatDialog, PageEvent, MatSort } from '@angular/material';

import { Decla_insolvenciaService } from './decla_insolvencia.service';
import { decla_insolvenciaModel } from './decla_insolvencia.model';
import { decla_insolvenciaCuotasModel } from './decla_insolvencia_cuotas.model';
import { decla_insolvenciaPagosModel } from './decla_insolvencia_pagos.model';
import Utils from '../../../services/utilidades';
import { decla_insolvenciadescuentModel } from './decla_insolvencia_descuent.model';
import { MAT_DATE_FORMATS, DateAdapter } from '@angular/material';
import { AppDateAdapter, APP_DATE_FORMATS } from 'src/app/components/appdateadapter.component';
import * as moment from 'moment';



declare var CONDITIONS_LIST: any;
declare var CONDITIONS_LIST_NUMBER: any;

@Component({
  selector: 'decla-insolvenciaTable',
  templateUrl: './decla_insolvenciaTable.html',
  styleUrls: ['./decla_insolvencia.css'],
  providers: [Decla_insolvenciaService,
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS }
  ]
})
export class decla_insolvenciaTable implements OnInit {
  selectedecla_insolvencia: decla_insolvenciaModel;
  originaldecla_insolvencia: decla_insolvenciaModel;
  rows: decla_insolvenciaCuotasModel[] = [];
  rowsPagos: decla_insolvenciaPagosModel[] = [];
  rowsDescuentos: decla_insolvenciadescuentModel[] = [];
  originals: decla_insolvenciaCuotasModel[] = [];
  selectedRow: decla_insolvenciaCuotasModel;
  selectedIndex: number = 0;
  originalRow: decla_insolvenciaCuotasModel;
  public displayedColumns: string[] = ['codEmp', 'nui', 'tipoLineaLin', 'lineaCredito', 'nroCredito', 'anexoCredito', 'fechaCobro', 'fechaInicial', 'fechaFinal', 'valorFlujo', 'flujoCapital', 'flujoInteres', 'tipoFlujo', 'saldoCapital', 'saldoInteres', 'tasaNominal', 'tipoInteres', 'estado', 'porcentajeInc', 'modalidad', 'diasLiquidacion', 'tasaNominalReal', 'tasaEfectivaReal', 'tasaEfectivaOriginal', 'nroFacturacion', 'periodoIntereses', 'usuaAmorti', 'totalCuotas', 'cuotaOriginal', 'baseCapital', 'codigoSucursal', 'intAnticipadoDev', 'identificacion', 'tipoIden', 'tipoCausacion', 'tipoCausacionAyer', 'causacionHoy', 'causacionAyer', 'fechaCausacion', 'swFac', 'diasMora', 'moraHoy', 'moraAyer', 'moraPagada', 'moraBalance', 'moraContingente', 'diasCausados', 'tasaMora', 'vlrIntAnticipado', 'causacionBalance', 'causacionCtgteAyer', 'vlrAbonoInteres', 'causacionCtgteHoy', 'saldoMora', 'nroCreditoMgr', 'tipoCarter', 'baseK', 'vlrPresente', 'duracionLiq', 'duracionRiesgo', 'banda', 'ver', 'fechaAplicaProrroga', 'causacionHoyRev', 'moraBalanceRev', 'moraContingenteRev', 'indContingente', 'fechaContingente', 'vlrSeguro', 'vlrAdminFee', 'vlrSeguroMora', 'vlrAvalMora', 'vlrSeguroPlan', 'vlrAvalPlan', 'vlrAdminFeePlan', 'flujoCapitalPlan', 'flujoInteresPlan', 'capitalAdicional'];
  public displayedColumnsPagos: string[] = ['codEmp', 'tipoLineaLin', 'lineaCreditoLin', 'identificacion', 'nroCredito', 'anexoCredito', 'fechaPago', 'estadoPago', 'nui', 'formaPago', 'valorPago', 'origenPago', 'fechaPrepago', 'nroPagoOrg', 'secuenciaDev', 'swPrepagar', 'codigoSucursal', 'fechaCancelacion', 'swPagoCheque', 'calRiesgoAntes', 'calTempAntes', 'diasMoraAntes', 'swRecalculo', 'porcAbogadoAntes', 'nroCupo', 'nroCtaCxp', 'fecCtaCxp', 'nroPagosCond', 'indRetrofecha', 'indEstadoRetrofecha', 'indDevCanje', 'indDevCaja', 'fechaAnulacion', 'motivoAnulacion', 'usuarioAnulacion', 'proximaCuota', 'valorAModuloCxc', 'ods', 'fechaServidor', 'vlrPeriodoGracia', 'tasa', 'motivo', 'nroLoteLista', 'fechaPagoLista', 'fechaRecibeLista', 'fechaRecibePago', 'tipoLista', 'filtro', 'swCupo', 'swCred', 'tasaAval', 'tasaSeguroVida', 'tasaAdm', 'swCobroAdm', 'swCobroInteresAnt'];
  public displayedColumnsDesc: string[] = ['codEmp', 'secuencia', 'fecha', 'valor', 'codFac', 'fechaDesde', 'fechaHasta', 'porcentajeGarantia', 'estadoCxp', 'fechaConfirmacion', 'nroCuota', 'codigoSucursal', 'nroCtaCxp', 'tasa', 'desctoSaldo', 'tipoCausacion', 'fechaCausacion', 'codFacObliga', 'fechaInserta', 'valorOriginal', 'fechaCambio', 'vlrCxc', 'vlrCtgte', 'valor1', 'valor2', 'vlrCxcB', 'vlrCtgteB', 'valor1B', 'valor2B', 'valor3B', 'fila'];

  public conditionsList = CONDITIONS_LIST;
  public conditionsListNumber = CONDITIONS_LIST_NUMBER;
  public searchValue: any = {};
  public searchCondition: any = {};
  public _pageSize = 10;
  buscarGen: string;
  buscarDB: string;
  buscarTipoDato: string = 'text';
  simulado = false;
  formDataSimulado;


  filter = {
    column: '',
    condition: '',
    value: ''
  };

  resultsLength = 0;
  isLoadingResults = false;
  isRateLimitReached = false;

  showForm = false;

  //@Input('carpetaValue') carpetaValue;


  decla_insolvenciaForm: FormGroup;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  _proc: boolean = false;
  _status: boolean = false;
  resultError: string = null;
  simulData: any;
  fechaDialogo;
  codigoCarpeta: number;

  //listTramites: Array<string> = [];
  listPasos: Array<string> = [];

  numeroCredito: number;
  identificacion: number;
  today = new Date();

  constructor(public dialog: MatDialog,
    private builder: FormBuilder,
    private _snackBar: MatSnackBar,
    private _bottomSheet: MatBottomSheet,
    private declaInsolvenciaService: Decla_insolvenciaService,
    public dialogRef: MatDialogRef<decla_insolvenciaTable>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.today.setDate(this.today.getDate());
    this.numeroCredito = data.nroCredito;
    this.identificacion = data.identificacion;
    this.declaInsolvenciaService.get_fecha_dialogo().subscribe((data) => {

      if (data.fechaDialogo) {
        this.fechaDialogo = moment(data.fechaDialogo);
        this.decla_insolvenciaForm.patchValue({
          "fechaCancelacion": this.fechaDialogo._d
        });
      }
    })
  }


  ngOnInit() {
    this.simulado = false;
    this.decla_insolvenciaForm = this.builder.group({
      'ldbNroCredito': [this.numeroCredito, [Validators.required]],
      'fechaCancelacion': ['', [Validators.required]],
      'fechaFallecido': ['', [Validators.required]],
      'indPagoOrg': [1, [Validators.required]],
      'lsMotivo': ['', [Validators.required, Validators.minLength(10)]],
    }, {
      validators: (formGroup: FormGroup): ValidationErrors | null => {
        const data = formGroup.getRawValue();
        let validationErrors = {};

        return validationErrors;
      }
    });
    this.decla_insolvenciaForm.valueChanges.subscribe((data) => {
      this.decla_insolvenciaForm.patchValue({}, { emitEvent: false, onlySelf: true });
    });

  }

  onSubmit(formData: decla_insolvenciaModel) {
    this.isLoadingResults = true;
    this.simulado = false;
    this._proc = true;
    if (this.decla_insolvenciaForm.valid) {
      formData = Object.assign(decla_insolvenciaModel.clone(this.originaldecla_insolvencia), formData);
      this.declaInsolvenciaService.simular(formData).subscribe((data: any) => {
        console.log('data to load info? ', data);
        this.formDataSimulado = formData
        this._proc = false;
        this._status = data.error;
        this.resultError = null;
        this.isLoadingResults = false;
        if (!this._status) {
          this.simulado = true;
          this.rowsPagos = [data.pago];
          this.rows = data.cuotas;
          this.rowsDescuentos = data.descuentos;
        } else {
          if (data.message.mensaje) {
            this.resultError = data.message.mensaje;
          } else if (data.message.message) {
            this.resultError = data.message.message;
          } else if (data.message) {
            this.resultError = data.message;
          } else {
            this.resultError = 'Error';
          }
          this.openNotificationDanger(this.resultError);
        }
      });

    }
  }



  onConfirm() {
    this.isLoadingResults = true;
    if (this.simulado) {
      this.declaInsolvenciaService.confirmar(this.formDataSimulado).subscribe((data: any) => {
        if (data === true) {
          this.dialogRef.close({
            data: true
          });
        } else {
          if(data.error && data.message){
            this.openNotificationDanger(data.error+': '+data.message);
          }else{
            this.openNotificationDanger("Ha ocurrido un error");
          }
        }
      });

    }
  }


  onSelect(event: Event, row: decla_insolvenciaCuotasModel, index: number) {
    this.selectedRow = row;
    this.selectedIndex = index;
    this.originalRow = this.originals[index];
  }

  onSelectAndEdit(event, row: decla_insolvenciaCuotasModel, index: number) {
    this.selectedRow = row;
    this.selectedRow._estado = 'O';
    this.selectedIndex = index;
    this.originalRow = this.originals[index];
    this.originalRow._estado = 'O';
    // this.openDialog();
  }

  onApplyFilter(e: Event, columnDB: string, columnModel: string) {
    this.filter.column = columnDB;
    this.filter.condition = this.searchCondition[columnModel];
    this.filter.value = this.searchValue[columnModel];

    let evt = new PageEvent();
    evt.pageIndex = 0;
    //this.paginator.page.emit(evt);
  }

  onClearColumn(columnModel: string) {
    this.searchValue[columnModel] = '';
    this.searchCondition[columnModel] = '';
    this.onRefresh();
  }

  onRefresh() {
    this.filter.column = '';
    this.filter.value = '';
    this.filter.condition = '';

    this.onSubmit(this.decla_insolvenciaForm.value);
  }

  onExportPago() {
    Utils.onExport('Pago', this.rowsPagos, [], this._bottomSheet);
  }

  onExport() {
    Utils.onExport('Cuotas', this.rows, [], this._bottomSheet);
  }

  onExportDesc() {
    Utils.onExport('Descuentos', this.rowsDescuentos, [], this._bottomSheet);
  }

  openNotificationDanger(message: string, action?: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
      panelClass: 'dangerSnackBar',
    });
  }

  selColumn(column: string, namedb: string, tipodato: string) {
    this.buscarGen = column;
    this.buscarDB = namedb;
    this.buscarTipoDato = tipodato;
  }
}
