import {
    animate,
    query,
    style,
    transition,
    trigger,
    stagger,
    sequence, animateChild, group
} from '@angular/animations';


export const subirAnimationWait = trigger('subirAnimationWait', [
    transition(':enter', [
        style({transform: 'translateY(30%)', opacity: 0}),
        animate(
            '0.4s ease-out',
            style({opacity: 0})
        ),
        animate(
            '0.3s ease-out',
            style({transform: 'translateY(0%)', opacity: 1})
        )
    ]),
    transition(':leave', [
        style({opacity: 0}),
    ])
]);


export const bajarAnimation = trigger('bajarAnimation', [
    transition(':enter', [
        style({transform: 'translateY(-30%)', opacity: 0}),
        animate(
            '0.5s ease-out',
            style({transform: 'translateY(5%)', opacity: 1})
        ),
        animate(
            '0.2s ease-out',
            style({transform: 'translateY(-5%)', opacity: 1})
        ),
        animate(
            '0.2s ease-out',
            style({transform: 'translateY(0%)', opacity: 1})
        )
    ]),
    transition(':leave', [
        style({opacity: 0}),
    ])
]);

export const derAIzAderButAnimation = trigger('derAIzAderButAnimation', [
    transition(':enter', [
        style({transform: 'translateX(-20%)', opacity: 0}),
        animate(
            '0.3s ease-out',
            style({opacity: 0})
        ),
        animate(
            '0.4s ease-out',
            style({transform: 'translateX(0%)', opacity: 1})
        )
    ]),
    transition(':leave', [
        style({opacity: 0}),
    ])
]);

export const derAIzAderButAnimationBajarWait = trigger('derAIzAderButAnimationBajarWait', [
  transition(':enter', [
    style({transform: 'translateX(-20%)', opacity: 0}),
    animate(
      '0.3s ease-out',
      style({opacity: 0})
    ),
    animate(
      '0.4s ease-out',
      style({transform: 'translateX(0%)', opacity: 1})
    ),
    group([
      query('@bajarAnimationMedWait', animateChild())
    ])
  ]),
  transition(':leave', [
    style({opacity: 0}),
  ])
]);



export const bajarAnimationMedWait = trigger('bajarAnimationMedWait', [
  transition(':enter', [
    style({transform: 'translateY(-30%)', opacity: 0}),
    animate(
      '0.1s ease-out',
      style({opacity: 0})
    ),
    animate(
      '0.2s ease-out',
      style({transform: 'translateY(0%)', opacity: 1})
    )
  ]),
  transition(':leave', [
    style({opacity: 0}),
  ])
]);



export const HijoBajIzqDerButtAnimation = trigger('HijoBajIzqDerButtAnimation', [
  transition(':enter', [
    group([
      query('@bajarAnimation', animateChild()),
      query('@derAIzAderButAnimation', animateChild())
    ]),
  ])
]);

export const bajarAnimationWait = trigger('bajarAnimationWait', [
  transition(':enter', [
    style({transform: 'translateY(-30%)', opacity: 0}),
    animate(
      '0.6s ease-out',
      style({opacity: 0})
    ),
    animate(
      '0.4s ease-out',
      style({transform: 'translateY(5%)', opacity: 1})
    ),
    animate(
      '0.2s ease-out',
      style({transform: 'translateY(-5%)', opacity: 1})
    ),
    animate(
      '0.2s ease-out',
      style({transform: 'translateY(0%)', opacity: 1})
    )
  ]),
  transition(':leave', [
    style({opacity: 0}),
  ])
]);

export const subirAnimationEsp = trigger('subirAnimationEsp', [
  transition(':enter', [
    style({transform: 'translateY(30%)', opacity: 0}),
    animate(
      '0.5s ease-out',
      style({opacity: 0})
    ),
    animate(
      '0.4s ease-out',
      style({transform: 'translateY(-5%)', opacity: 1})
    ),
    animate(
      '0.2s ease-out',
      style({transform: 'translateY(5%)', opacity: 1})
    ),
    animate(
      '0.2s ease-out',
      style({transform: 'translateY(0%)', opacity: 1})
    )
  ])
]);
export const subirAnimation = trigger('subirAnimation', [
  transition(':enter', [
    style({transform: 'translateY(30%)', opacity: 0}),
    animate(
      '0.4s ease-out',
      style({transform: 'translateY(-5%)', opacity: 1})
    ),
    animate(
      '0.2s ease-out',
      style({transform: 'translateY(5%)', opacity: 1})
    ),
    animate(
      '0.2s ease-out',
      style({transform: 'translateY(0%)', opacity: 1})
    )
  ]),
  transition(':leave', [
    style({opacity: 0}),
  ])
]);

export const derAIzAnimationHide = trigger('derAIzAnimationHide', [
  transition(':enter', [
    style({transform: 'translateX(-20%)', opacity: 0}),
    animate(
      '0.5s ease-out',
      style({transform: 'translateX(5%)', opacity: 1})
    ),
    animate(
      '0.2s ease-out',
      style({transform: 'translateX(-1%)', opacity: 1})
    ),
    animate(
      '0.2s ease-out',
      style({transform: 'translateX(0%)', opacity: 1})
    )
  ]),
  transition(':leave', [
    style({transform: 'translateX(0%)', opacity: 1}),
    animate(
      '0.3s ease-out',
      style({transform: 'translateX(-5%)', opacity: 1})
    ),
    animate(
      '0.1s ease-out',
      style({transform: 'translateX(1%)', opacity: 1})
    ),
    animate(
      '0.2s ease-out',
      style({transform: 'translateX(-20%)', opacity: 0})
    ),
    style({opacity: 0}),
  ])
]);

export const subirAnimationMenu = trigger('subirAnimationMenu', [
  transition(':enter', [
    style({transform: 'translateY(30%)', opacity: 0}),
    animate(
      '0.1s ease-out',
      style({ opacity: 0})
    ),
    animate(
      '0.3s ease-out',
      style({transform: 'translateY(0%)', opacity: 1})
    )
  ]),
  transition(':leave', [
    style({opacity: 0}),
  ])
]);
export const derAIzAderButAnimationWait = trigger('derAIzAderButAnimationWait', [
  transition(':enter', [
    style({transform: 'translateX(-20%)', opacity: 0}),
    animate(
      '0.8s ease-out',
      style({opacity: 0})
    ),
    animate(
      '0.4s ease-out',
      style({transform: 'translateX(0%)', opacity: 1})
    )
  ]),
  transition(':leave', [
    style({opacity: 0}),
  ])
]);

export const derAIzAnimationWait = trigger('derAIzAnimationWait', [
  transition(':enter', [
    style({transform: 'translateX(-20%)', opacity: 0}),
    animate(
      '0.5s ease-out',
      style({opacity: 0})
    ),
    animate(
      '0.5s ease-out',
      style({transform: 'translateX(5%)', opacity: 1})
    ),
    animate(
      '0.2s ease-out',
      style({transform: 'translateX(-1%)', opacity: 1})
    ),
    animate(
      '0.2s ease-out',
      style({transform: 'translateX(0%)', opacity: 1})
    )
  ]),
  transition(':leave', [
    style({opacity: 0}),
  ])
]);

export const IzAderAIzButAnimation = trigger('IzAderAIzButAnimation', [
    transition(':enter', [
        style({transform: 'translateX(20%)', opacity: 0}),
        animate(
            '0.3s ease-out',
            style({opacity: 0})
        ),
        animate(
            '0.4s ease-out',
            style({transform: 'translateX(0%)', opacity: 1})
        )
    ]),
    transition(':leave', [
        style({opacity: 0}),
    ])
]);
export const IzAderAIzButAnimationWait = trigger('IzAderAIzButAnimationWait', [
    style({transform: 'translateX(20%)', opacity: 0}),
    animate(
        '0.8s ease-out',
        style({opacity: 0})
    ),
    animate(
        '0.4s ease-out',
        style({transform: 'translateX(0%)', opacity: 1})
    )
]);
export const TitDialogAnimation = trigger('TitDialogAnimation', [
    transition(':enter', [
        style({transform: 'translateX(0%)', opacity: 0}),
        animate(
            '0.3s ease-out',
            style({transform: 'translateX(5%)', opacity: 1})
        ),
        animate(
            '0.3s ease-out',
            style({transform: 'translateX(-5%)', opacity: 1})
        ),
        animate(
            '0.1s ease-out',
            style({transform: 'translateX(0%)', opacity: 1})
        ),
        animate(
            '0.3s ease-out',
            style({transform: 'translateY(5%)', opacity: 1})
        ),
        animate(
            '0.1s ease-out',
            style({transform: 'translateY(0%)', opacity: 1})
        ),
    ])
]);
export const HijoSubBajEspAnimation = trigger('HijoSubBajEspAnimation', [
    transition(':enter', [
        query('@subirAnimation', stagger(75, [animateChild()])),
        query('@bajarAnimation', stagger(75, [animateChild()])),
    ])
]);
export const HijoSubBajIzqAnimation = trigger('HijoSubBajIzqAnimation', [
    transition(':enter', [
        group([
            query('@subirAnimation', animateChild()),
            query('@bajarAnimation', animateChild()),
            query('@derAIzAnimation', animateChild())
        ]),
    ])
]);
export const HijoBajIzqAnimationWait = trigger('HijoBajIzqAnimationWait', [
    transition(':enter', [
        group([
            query('@bajarAnimationWait', animateChild()),
            query('@derAIzAnimationWait', animateChild())
        ]),
    ])
]);

export const HijoDerAIzAderButBajarAnimationWait = trigger('HijoDerAIzAderButBajarAnimationWait', [
  transition(':enter', [
    group([
      query('@derAIzAderButAnimation', animateChild()),
      query('@bajarAnimationWait', animateChild())
    ]),
  ])
]);



export const HijoBajIzqAnimation = trigger('HijoBajIzqAnimation', [
    transition(':enter', [
        group([
            query('@bajarAnimation', animateChild()),
            query('@derAIzAnimation', animateChild())
        ]),
    ])
]);
export const HijoBajIzqButtAnimation = trigger('HijoBajIzqButtAnimation', [
    transition(':enter', [
        group([
            query('@bajarAnimation', animateChild()),
            query('@derAIzAderButAnimation', animateChild())
        ]),
    ])
]);


export const HijoBajderAIzAnimationWait = trigger('HijoBajderAIzAnimationWait', [
    transition(':enter', [
        group([
            query('@bajarAnimation', animateChild()),
            query('@derAIzAnimationWait', animateChild())
        ]),
    ])
]);


export const HijoSubDerButtAnimation = trigger('HijoSubDerButtAnimation', [
    transition(':enter', [
        group([
            query('@subirAnimation', animateChild()),
            query('@derAIzAderButAnimation', animateChild())
        ]),
    ])
]);
export const HijoSubIzqButtAnimation = trigger('HijoSubIzqButtAnimation', [
    transition(':enter', [
        group([
            query('@subirAnimation', animateChild()),
            query('@derAIzAderButAnimation', animateChild())
        ]),
    ])
]);
export const HijoSubIzqButtAnimationWait = trigger('HijoSubIzqButtAnimationWait', [
    transition(':enter', [
        group([
            query('@subirAnimationWait', animateChild()),
            query('@derAIzAderButAnimationWait', animateChild())
        ]),
    ])
]);

export const IzADerAnimation = trigger('IzADerAnimation', [
  transition(':enter', [
    style({transform: 'translateX(20%)', opacity: 0}),
    animate(
      '0.5s ease-out',
      style({transform: 'translateX(-5%)', opacity: 1})
    ),
    animate(
      '0.2s ease-out',
      style({transform: 'translateX(1%)', opacity: 1})
    ),
    animate(
      '0.2s ease-out',
      style({transform: 'translateX(0%)', opacity: 1})
    )
  ]),
  transition(':leave', [
    style({opacity: 0}),
  ])
]);
export const transpAnimation = trigger('transpAnimation', [
    transition(
        ':enter',
        [
            style({opacity: 0}),
            animate('2s ease-out',
                style({opacity: 1}))
        ]
    ),
    transition(
        ':leave',
        [
            style({opacity: 1}),
            animate('2s ease-in',
                style({opacity: 0}))
        ]
    )
]);

export function isRouteAnimationsAll() {
    return true;
}
