import { Component, Inject, OnInit, ViewEncapsulation  } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, ValidationErrors } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar, MatBottomSheet } from '@angular/material';
import { map, switchMap, startWith } from 'rxjs/operators';
import * as moment from 'moment';
import * as PB from 'src/app/components/powerbuilder.functions';

import { NotificacionService } from './notificacion.service';
import { NotificacionModel } from './notificacion.model';
import { AlertasComponent } from 'src/app/components/alertas.component';

@Component({
    templateUrl: './notificacion.dialog.html',
    styleUrls: ['./notificacion.dialog.css'],
    providers: [NotificacionService],
    encapsulation: ViewEncapsulation.None,
})
export class NotificacionDialog implements OnInit {
    PBF = PB;

    selectedNotificacion: NotificacionModel;
    originalNotificacion: NotificacionModel;


    notificacionForm: FormGroup;

    _proc: boolean = false;
    _status: boolean = false;
    resultError: string = null;

    constructor(private builder: FormBuilder,
        private notificacionService: NotificacionService,
        private _snackBar: MatSnackBar,
        private _bottomSheet: MatBottomSheet,
        public dialogRef: MatDialogRef<NotificacionDialog>,
        @Inject(MAT_DIALOG_DATA) public data: any) {
        console.log(data)
        if (data.selected) {
            this.selectedNotificacion = data.selected;
            this.originalNotificacion = data.original;
        } else {
            this.selectedNotificacion = data;
            this.originalNotificacion = data;
        }
        console.log(this.originalNotificacion)
        this.dialogRef.disableClose = true;
    }

    ngOnInit() {
        this.getUser(this.selectedNotificacion.usuarioFrom)
        const disabledPKey = this.originalNotificacion._estado === 'O';

        this.notificacionForm = this.builder.group({
            'secuencia': [{ value: this.selectedNotificacion.secuencia, disabled: disabledPKey }, [Validators.required]],
            'estado': [this.selectedNotificacion.estado],
            'usuarioFrom': [this.selectedNotificacion.usuarioFrom, [Validators.maxLength(100)]],
            'usuarioTo': [this.selectedNotificacion.usuarioTo, [Validators.maxLength(100)]],
            'asunto': [this.selectedNotificacion.asunto, [Validators.maxLength(200)]],
            'mensaje': [this.selectedNotificacion.mensaje, [Validators.maxLength(32766)]],
            'tipo': [this.selectedNotificacion.tipo],
            'topico': [this.selectedNotificacion.topico, [Validators.maxLength(32766)]],
            'hecho': [this.selectedNotificacion.hecho],
            'fechaCreacion': [new Date(this.selectedNotificacion.fechaCreacion)],
            'fechaLectura': [new Date(this.selectedNotificacion.fechaLectura)],
            '_estado': [this.selectedNotificacion._estado, Validators.required]
        }, {
            validators: (formGroup: FormGroup): ValidationErrors | null => {
                const data = formGroup.getRawValue();
                let validationErrors = {

                };

                return validationErrors;
            }
        });

        this.notificacionForm.valueChanges.subscribe((data) => {

            this.notificacionForm.patchValue({

            }, { emitEvent: false, onlySelf: true });
        });
        if (this.selectedNotificacion.estado === 0) this.onStatus(this.notificacionForm.value);
    }

    onStatus(formData: NotificacionModel) {
        this._proc = true;
        formData.fechaCreacion = moment(formData.fechaCreacion).format('YYYY-MM-DD HH:MM:SS');
        formData.fechaLectura = moment(formData.fechaLectura).format('YYYY-MM-DD HH:MM:SS');
        if (formData.hecho.toString() === '1' || formData.hecho.toString() === '0') {
            formData.hecho = formData.hecho;
        } else {
            formData.hecho = formData.hecho.toString() === 'true' ? 1 : 0;
        }
        formData = Object.assign(NotificacionModel.clone(this.originalNotificacion), formData);
        this.notificacionService.saveNotificacion(formData, this.originalNotificacion).subscribe((data: any) => {
            this._proc = false;
            this._status = !!data.error;
            this.resultError = null;

            if (!this._status) {

                if (formData._estado != 'O') {
                    formData.secuencia = data[0].secuencia;
                }
            } else {
                this.resultError = data.message;
                this.openNotificationDanger(this.resultError)
            }
        });
    }

    onSubmit(formData: NotificacionModel) {
        this._proc = true;
        formData.fechaCreacion = moment(formData.fechaCreacion).format('YYYY-MM-DD HH:MM:SS');
        formData.fechaLectura = moment(formData.fechaLectura).format('YYYY-MM-DD HH:MM:SS');
        if (formData.hecho.toString() === '1' || formData.hecho.toString() === '0') {
            formData.hecho = formData.hecho;
        } else {
            formData.hecho = formData.hecho.toString() === 'true' ? 1 : 0;
        }
        formData = Object.assign(NotificacionModel.clone(this.originalNotificacion), formData);
        this.notificacionService.saveNotificacion(formData, this.originalNotificacion).subscribe((data: any) => {
            this._proc = false;
            this._status = !!data.error;
            this.resultError = null;

            if (!this._status) {

                if (formData._estado != 'O') {
                    formData.secuencia = data.secuencia;
                }
                this.dialogRef.close({
                    data: formData
                });
            } else {
                this.resultError = data.message;
                this.openNotificationDanger(this.resultError)
            }
        });
    }

    onDelete(formData: NotificacionModel) {
        if (this.notificacionForm.valid) {
            let sheetRef = this._bottomSheet.open(AlertasComponent);
            sheetRef.afterDismissed().subscribe(data => {
                if (data.mensaje == "accept") {
                    this._proc = true;
                    formData = Object.assign(NotificacionModel.clone(this.originalNotificacion), formData);
                    this.notificacionService.deleteNotificacion(formData).subscribe((data: any) => {
                        this._proc = false;
                        this._status = !!data.error;
                        this.resultError = null;

                        if (!this._status) {
                            formData._estado = 'D';
                            this.dialogRef.close({
                                data: formData,
                                delete: true
                            });
                        } else {
                            this.resultError = data.message;
                            this.openNotificationDanger(this.resultError)
                        }
                    });
                };
            });
        };
    }
    openNotificationDanger(message: string, action?: string) {
        this._snackBar.open(message, action, {
            duration: 2000,
            panelClass: 'dangerSnackBar',
        });
    }

    getUser(user: string) {
        this.notificacionService.getById(user).subscribe(
            (resp: any) => {
                this.selectedNotificacion.userFrom = resp.nombre;
            }
        );
    }
}
