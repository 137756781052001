import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material';

export const STYLE_DANGER: string = 'dangerSnackBar';
export const STYLE_SUCESS: string = 'successSnackBar';
export const STYLE_INFO: string = 'infoSnackBar';

@Injectable({ providedIn: 'root' })
export default class NotificacionComponent {

    constructor(private _snackBar: MatSnackBar) {}

    dangerNotificacion(message: string, tiempo: number = 2000, action?: string) {
        this._snackBar.open(message, action, {
            duration: tiempo,
            panelClass: STYLE_DANGER,
        });
    }

    sucessNotificacion(message: string, tiempo: number = 2000, action?: string) {
        this._snackBar.open(message, action, {
            duration: tiempo,
            panelClass: STYLE_SUCESS,
        });
    }

    infoNotificacion(message: string, tiempo: number = 2000, action?: string) {
        this._snackBar.open(message, action, {
            duration: tiempo,
            panelClass: STYLE_SUCESS,
        });
    }
}