export class CambiarClaveModel {

    public username: string;
    public password: string;
    public passwordnew: string;

    public _secuencia: number;
    public _estado: string = 'N';
    public _id: string;
    public _v: number;
    public _relaciones: any;

    constructor(json: any = null) {
        this._relaciones = {};
        if (json !== null) {
            this.username = json.username;
            this.password = json.password;
            this.passwordnew = json.passwordnew;
        }
    }

    static clone(row: CambiarClaveModel): CambiarClaveModel {
        const rowCloned = Object.assign({}, row);
        delete rowCloned._secuencia;
        delete rowCloned._estado;
        delete rowCloned._id;
        delete rowCloned._v;
        delete rowCloned._relaciones;
        return rowCloned;
    }

    toClipboard(): string {
        let result = '';
        result += `\t${this.username}`;
        result += `\t${this.password}`;
        result += `\t${this.passwordnew}`;
        return result.substring(1);
    }

    fromClipboard(value: string): CambiarClaveModel {
        const result = value.split('\t');

        this.username =  String(<any>(result[0]));
        this.password =  String(<any>(result[1]));
        this.passwordnew =  String(<any>(result[2]));
        
        return this;
    }
}
