import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, tap, retry } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
const httpOptions = {
  observe: null,
  params: null,
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'username': '',
    'passord': '',
    'passwordnew': ''
  })
};
@Injectable({ providedIn: 'root' })
export class RecuperaraccesoService {
  private verificarUsuarioUrl = '';  // URL to web api
  public isLogin = false;
  public token = '';

  constructor(private http: HttpClient) {
    this.verificarUsuarioUrl = `${environment.dataServiceUrl}/Identity/Autenticacion`;
  }

  verificarUsuario(usuario: string): Observable<any> {
    const sUrl = `${this.verificarUsuarioUrl}/Recuperacion/Solicitar`;
    const httpOption = {
      headers: new HttpHeaders({
        'username': usuario
      })
    };
    return this.http.post<any>(sUrl, {}, httpOption).pipe(
      tap((resp: HttpResponse<any>) => {
        this.log(`login w/ id=${resp}`);
        return resp;
      }),
      catchError((error) => this.handleError('login', error))
    );
  }

  recuperarAcceso(usuario: string, method: number, origenApp: number): Observable<any> {
    const sUrl = `${this.verificarUsuarioUrl}/Recuperacion/`;
    console.log(usuario, method, origenApp);
    const httpOption = {
      headers: new HttpHeaders({
        'username': usuario,
        'metodo': method.toString(),
        'origen': origenApp.toString()
      })
    };
    return this.http.post<any>(sUrl, {}, httpOption).pipe(
      tap((resp: HttpResponse<any>) => {
        this.log(`login w/ id=${resp}`);
        return resp;
      }),
      catchError((error) => this.handleError('login', error))
    );
  }


  private handleError(operation = 'operation', result?: any) {
    // TODO: send the error to remote logging infrastructure
    console.log(result.error); // log to console instead
    // TODO: better job of transforming error for user consumption
    this.log(`${operation} failed: ${result.message}`);
    // Let the app keep running by returning an empty result.
    return of(result);
  }
  /** Log a INVCO_BienesService message with the MessageService */
  private log(message: string) {
    // this.messageService.add(`loginService: ${message}`);
    console.log(`LoginService: ${message}`);
  }

}
