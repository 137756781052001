export class ProcesosMultiplesReloadedModel {
    public secuencia: number;
    public codigoEmpresa: number;
    public codigoProceso: number;
    public nombreEvento: string;
    public fechaEvento: Date;
    public codigoRetorno: number;
    public descripcion: string;
    public usuario: string;
    public fechaNegocio: Date;

    public _secuencia: number;
    public _estado: string = 'N';
    public _id: string;
    public _v: number;
    public _relaciones: any;
}
