import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators, ValidationErrors } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar, MatBottomSheet } from '@angular/material';
import { Router, NavigationEnd, NavigationStart, NavigationCancel } from '@angular/router';

import { loginService } from '../login.service';
import { loginModel } from '../login.model';

@Component({
  templateUrl: './logindialog.dialog.html',
  styleUrls: ['./logindialog.dialog.css'],
  providers: [loginService]
})
export class LoginDialog {
  selectedLogin: loginModel;
  loginForm: FormGroup;
  _proc: boolean = false;
  _status: boolean = false;
  resultError: string = null;

  constructor(private builder: FormBuilder,
    private loginService: loginService,
    private _snackBar: MatSnackBar,
    private _bottomSheet: MatBottomSheet,
    private router: Router,
    public dialogRef: MatDialogRef<LoginDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    dialogRef.disableClose = true;
    this.selectedLogin = new loginModel();
  }
  ngOnInit() {
    this.loginForm = this.builder.group({
      'username': [this.selectedLogin.username, Validators.required],
      'password': [this.selectedLogin.password, Validators.required]
    }, {
      validators: (formGroup: FormGroup): ValidationErrors | null => {
        const data = formGroup.getRawValue();
        let validationErrors = {};

        return validationErrors;
      }
    });
  }

  onSubmit(formData: loginModel) {
    this._proc = true;
    if (this.loginForm.valid) {
      this.loginService.login(formData).subscribe((resp) => {
        this._proc = false;
        if (resp.status == 500) {
          this.resultError = resp['message'].substring(resp['message'].indexOf(':') + 2, resp['message'].length);
          this.openNotificationDanger(this.resultError);
        } else if (resp.status == 401 || resp.status == 412 || resp.status == 403 || resp.status == 404) {
          this.resultError = resp['message'];
          this.openNotificationDanger(this.resultError);
        } else {
          this._status = resp.body.status !== 200;
          this.resultError = null;

          if (this._status) {
            this.resultError = 'login error ' + resp.status;
          } else {
            sessionStorage.setItem('isLogin', 'true');
            this.dialogRef.close();
          }
        }
      });
    }
  }

  public cerrarSesion() {

    this.loginService.logout().subscribe((resp) => {
      console.log('logged out');
      sessionStorage.removeItem('token');
      sessionStorage.removeItem('isLogin');
      this.dialogRef.close();
      this.router.navigate(['']);
    });

  }

  openNotificationDanger(message: string, action?: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
      panelClass: 'dangerSnackBar',
    });
  }
}
