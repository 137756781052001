import { Component, Inject, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatBottomSheet } from '@angular/material';
import { AwsComponent } from 'src/app/components/aws.component';
//import { TipoDocumentoOriginacionModel } from './tipodocumentooriginacion.model';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/internal/operators/map';


@Component({
	templateUrl: './visualizardocumentossin.visual.html',
	// styleUrls: ['./visualizardocumentossin.table.css'],
})
export class VisualizarDocumentosSinVisual {
	//selectedTipoDocumento: TipoDocumentoOriginacionModel;
	tipoImpresion: number;
	tipoProceso: number;

	impresionDocumentosForm: FormGroup;
	isLoadingResults = true;
	rutaAws: string;

	_proc: boolean = false;
	_status: boolean = false;
	resultError: string = null;

	datos: any = {};

	@ViewChild('pdfViewerAutoLoad', { static: false }) pdfViewerAutoLoad;

	constructor(private awsComponent: AwsComponent,
		private http: HttpClient,
		private _bottomSheet: MatBottomSheet,
		public dialogRef: MatDialogRef<VisualizarDocumentosSinVisual>,
		@Inject(MAT_DIALOG_DATA) public data: any) {
		this.datos = data.selected;
	}

	ngOnInit() {


          let proc: any = `dialogo-procesos/${this.datos.rutaAws}`;
          let archivo = `/${this.datos.nombreAws}`;
          proc = proc.replace(archivo, "");
          proc = proc.replaceAll("/", ".");
          this.isLoadingResults = true;
					this.awsComponent.downloadFile(proc, archivo)
						.subscribe((data: any) => {
							this.downloadFile(data.url).subscribe((resp: any) => {
								this.pdfViewerAutoLoad.pdfSrc = resp;
								this.pdfViewerAutoLoad.refresh();
								this.isLoadingResults = false;
							});
						});
	}

	private downloadFile(url: string): any {
		return this.http.get(url, { responseType: 'blob' })
			.pipe(
				map((result: any) => {
					return result;
				})
			)
	}
}
