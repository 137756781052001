import { Component, OnInit, Inject } from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material';

@Component({
  selector: 'app-alertas',
  templateUrl: './alertas.component.html',
  styleUrls: ['./alertas.component.css']
})
export class AlertasComponent implements OnInit {

  titulo: string;

  constructor(private bottomSheetRef: MatBottomSheetRef<AlertasComponent>,
      @Inject(MAT_BOTTOM_SHEET_DATA) public data: any) { }

  ngOnInit() {
    this.titulo = this.data;
  }

  btnProceso(tipo: string): void {
    this.bottomSheetRef.dismiss({
      mensaje: tipo
    })
  }
}
