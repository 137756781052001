import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { EventSourcePolyfill } from 'ng-event-source';
import { MatSnackBar } from '@angular/material';
import * as Stomp from 'stompjs';
import * as SockJS from 'sockjs-client';
import { environment } from '../../../environments/environment';
import { SocketService } from '../../services/socket.service';
// import { ToastrService } from 'ngx-toastr';
import { Message } from '../../model/message';

declare var appComponent: any;

@Component({
  selector: 'notification-socket',
  templateUrl: './notification-socket.component.html',
  styleUrls: ['./notification-socket.component.css']
})
export class notificationSocketComponent implements OnInit {

  @Output() newMessage = new EventEmitter<boolean>();

  //private serverUrl = environment.dataServiceUrl + '/notificacion/socket/';
  isLoaded: boolean = false;
  isCustomSocketOpened = false;
  /*private stompClient;
  private form: FormGroup;
  private userForm: FormGroup;*/
  messages: Message[] = [];
  private messageResult: Message;
  constructor(/*private socketService: SocketService,*/
    // private toastr: ToastrService
    private _snackBar: MatSnackBar
  ) {
    this.ngOnInit();
  }

  ngOnInit() { this.conectar() }

  evtSource: any;

  conectar() {
    return;
    let url = `${environment.dataServiceUrl}/Mensajeria/NotificacionReactiva/obtener`;

    this.evtSource = new EventSourcePolyfill(url, {
      headers: {
        'Content-Type': 'text/event-stream',
        'Authorization': 'Bearer ' + window.sessionStorage.getItem("token")
      }
    });

    this.evtSource.onmessage = (e) => {
      if (JSON.parse(e.data).id === -999) {
        return
      }
      this.messageResult = new mensaje();
      this.messageResult.fromId = JSON.parse(e.data).remitente
      this.messageResult.message = JSON.parse(e.data).mensaje
      this.messageResult.toId = JSON.parse(e.data).destinatario

      this.messages.push(this.messageResult);
      this.newMessage.emit(true);
      this.openNotificationInfo(this.messageResult.message);

    }

    this.evtSource.onerror = (e) => {
      console.log('connection error');
      console.log(e);
      this.evtSource.close();
    }

    this.evtSource.onopen = (e) => {
      console.log('connection open');
      console.log(e);
    }
  }

  openNotificationInfo(message: string, action?: string) {
    this._snackBar.open(message, action, {
      duration: 3000,
      panelClass: 'infoSnackBar',
    });
  }

}
class mensaje implements Message {
  message: string
  fromId: string
  toId: string
}
