export class NotificacionModel {
    public secuencia: number;
    public estado: number;
    public usuarioFrom: string;
    public usuarioTo: string;
    public asunto: string;
    public mensaje: string;
    public tipo: number;
    public fechaCreacion: any;
    public fechaLectura: any;
    public userFrom:String;
    public topico:string;
    public proceso:number;
    public hecho:number;

    public _secuencia: number;
    public _estado: string = 'N';
    public _id: string;
    public _v: number;
    public _relaciones: any;

    constructor(json: any = null) {
        this._relaciones = {};
        if (json !== null) {
            this.secuencia = json.secuencia;
            this.estado = json.estado;
            this.usuarioFrom = json.usuarioFrom;
            this.usuarioTo = json.usuarioTo;
            this.asunto = json.asunto;
            this.mensaje = json.mensaje;
            this.tipo = json.tipo;
            this.fechaCreacion = json.fechaCreacion;
            this.fechaLectura = json.fechaLectura;
            this.topico = json.topico;
            this.proceso = json.proceso;
            this.hecho = json.hecho;
        }
    }

    static clone(row: NotificacionModel): NotificacionModel {
        const rowCloned = Object.assign({}, row);
        delete rowCloned._secuencia;
        delete rowCloned._estado;
        delete rowCloned._id;
        delete rowCloned._v;
        delete rowCloned._relaciones;
        delete rowCloned.userFrom;
        return rowCloned;
    }

    toClipboard(): string {
        let result = '';

        result += `\t${this.secuencia}`;
        result += `\t${this.estado}`;
        result += `\t${this.usuarioFrom}`;
        result += `\t${this.usuarioTo}`;
        result += `\t${this.asunto}`;
        result += `\t${this.mensaje}`;
        result += `\t${this.tipo}`;
        result += `\t${this.fechaCreacion}`;
        result += `\t${this.fechaLectura}`;
        result += `\t${this.topico}`;
        result += `\t${this.proceso}`;
        result += `\t${this.hecho}`;

        return result.substring(1);
    }

    fromClipboard(value: string): NotificacionModel {
        const result = value.split('\t');

        this.secuencia =  Number(<any>(result[0]));
        this.estado =  Number(<any>(result[1]));
        this.usuarioFrom =  String(<any>(result[2]));
        this.usuarioTo =  String(<any>(result[3]));
        this.asunto =  String(<any>(result[4]));
        this.mensaje =  String(<any>(result[5]));
        this.tipo =  Number(<any>(result[6]));
        this.fechaCreacion =  <Date>(<any>(result[7]));
        this.fechaLectura =  <Date>(<any>(result[8]));
        this.topico =  String(<any>(result[5]));
        this.proceso =  Number(<any>(result[6]));
        this.hecho =  Number(<any>(result[6]));

        return this;
    }
}
