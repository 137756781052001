import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, tap, retry } from 'rxjs/operators';

import { environment } from '../../../../environments/environment';
import { decla_insolvenciaModel } from './decla_insolvencia.model';
import {simulacion_decla_insolvenciaModel} from './simulacion_decla_insolvencia.model';

@Injectable({ providedIn: 'root' })
export class Decla_insolvenciaService {
  private insolvenciaUrl = '';  // URL to web api
  private fecha_webUrl = '';  // URL to web api

  constructor(private http: HttpClient) {
    this.insolvenciaUrl = `${environment.dataServiceUrl}/Cartera/FCartMarcarInsolvente`;
    this.fecha_webUrl = `${environment.dataServiceUrl}/Parametros/AdministracionDeFecha/FechaDialogo`;
  }

  get_fecha_dialogo(): Observable<any> {
    return this.http.get<decla_insolvenciaModel>(this.fecha_webUrl).pipe(
      retry(3),
      tap(() => this.log('fetched fecha_Dialogo_web')),
      catchError((error) => this.handleError('getfecha_Dialogo_web', error))
    );
  }
  simular(row: decla_insolvenciaModel): Observable<simulacion_decla_insolvenciaModel> {
    return this.http.post<decla_insolvenciaModel>(this.insolvenciaUrl, row).pipe(
      retry(3),
      tap((rrow: simulacion_decla_insolvenciaModel) => this.log(`simular Decla_insolvencia`)),
      catchError((error) => this.handleError('simular_Decla_insolvencia', error))
    );
  }

  // confirmar(tot: simulacion_decla_insolvenciaModel): Observable<any> {
  //   console.log('service confirmar: ', tot, simulacion_decla_insolvenciaModel.clone(tot),this.insolvenciaUrl);
  //   return this.http.put<simulacion_decla_insolvenciaModel>(this.insolvenciaUrl, simulacion_decla_insolvenciaModel.clone(tot)).pipe(
  //     retry(3),
  //     tap(_ => this.log(`confirmar Decla_insolvencia`)),
  //     catchError((error) => this.handleError('confirmar_Decla_insolvencia', error))
  //   );
  // }

  confirmar(row: simulacion_decla_insolvenciaModel): Observable<simulacion_decla_insolvenciaModel> {
    return this.http.put<simulacion_decla_insolvenciaModel>(this.insolvenciaUrl, simulacion_decla_insolvenciaModel.clone(row)).pipe(
        retry(3),
        tap(_ => this.log(`update Aseguradora credito=/${row.ldbNroCredito}`)),
        catchError((error) => this.handleError('updateAseguradora', error))
    );
}




  private handleError(operation = 'operation', result?: any) {

    // TODO: send the error to remote logging infrastructure
    console.error(result.error); // log to console instead

    // TODO: better job of transforming error for user consumption
    this.log(`${operation} failed: ${result.message}`);

    // Let the app keep running by returning an empty result.
    return of(result);
  }

  /** Log a CreditoService message with the MessageService */
  private log(message: string) {
    // this.messageService.add(`CreditoService: ${message}`);
    console.log(`CreditoService: ${message}`);
  }

}
