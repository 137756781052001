import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, ValidationErrors } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';

import { GestionAvanceProcesosReactiveService } from './gestionavanceprocesosreactive.service';
import { GestionAvanceProcesosReactiveModel } from './gestionavanceprocesosreactive.model';

@Component({
  templateUrl: './gestionavanceprocesosreactive.dialog.html',
  providers: [GestionAvanceProcesosReactiveService]
})
export class GestionAvanceProcesosReactiveDialog {
    selectedGestionAvanceProcesosReactive: GestionAvanceProcesosReactiveModel;

    gestionAvanceProcesosForm: FormGroup;

    _proc: boolean = false;
    _status: boolean = false;
    resultError: string = null;

    constructor(private builder: FormBuilder,
                private _snackBar: MatSnackBar,
                private gestionAvanceProcesosService: GestionAvanceProcesosReactiveService,
                public dialogRef: MatDialogRef<GestionAvanceProcesosReactiveDialog>,
                @Inject(MAT_DIALOG_DATA) public data: any) {

        this.dialogRef.disableClose = true;
        this.selectedGestionAvanceProcesosReactive = data.selected;
    }

    ngOnInit() {
        const disabledPKey = this.selectedGestionAvanceProcesosReactive._estado === 'O';

        this.gestionAvanceProcesosForm = this.builder.group({
            'nombreEvento': [ { value: this.selectedGestionAvanceProcesosReactive.nombreEvento, disabled: true } ],
            'fechaEvento': [ { value: new Date(this.selectedGestionAvanceProcesosReactive.fechaEvento), disabled: true } ],
            'codigoRetorno': [ { value: this.selectedGestionAvanceProcesosReactive.codigoRetorno, disabled: true } ],
            'descripcion': [ { value: this.selectedGestionAvanceProcesosReactive.descripcion, disabled: true } ],
            'usuario': [ { value: this.selectedGestionAvanceProcesosReactive.usuario, disabled: true } ],
            'fechaNegocio': [ { value: new Date(this.selectedGestionAvanceProcesosReactive.fechaNegocio), disabled: true } ],
            '_estado': [ { value: this.selectedGestionAvanceProcesosReactive._estado, disabled: true } ]
        }, {
            validators: (formGroup: FormGroup): ValidationErrors | null => {
                const data = formGroup.getRawValue();
                let validationErrors = { };
                return validationErrors;
            }
        });

        this.gestionAvanceProcesosForm.valueChanges.subscribe((data) => {
            this.gestionAvanceProcesosForm.patchValue({
            }, {emitEvent: false, onlySelf: true});
        });
    }
    
    openNotificationDanger(message: string, action?: string) {
       this._snackBar.open(message, action, {
           duration: 2000,
           panelClass: 'dangerSnackBar',
       });
    }
}
