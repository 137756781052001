import { Data } from "@angular/router";

export class MarcaFraudeModel {
    public faNroCredito: number;
    public faCodigoFraude: number;
    public faMotivo: string;
    public faFecha: string;
    // public fechaFraude: Date;

    public _estado: string = 'N';
    public _id: string;
    public _v: number;
    public _relaciones: any;

    constructor(json: any = null) {
        this._relaciones = {};
        if (json !== null) {
            this.faNroCredito = json.faNroCredito;
            this.faCodigoFraude = json.faCodigoFraude;
            this.faMotivo = json.faMotivo;
            // this.fechaFraude = json.fechaFraude;
        }
    }

    static clone(row: MarcaFraudeModel): MarcaFraudeModel {
        const rowCloned = Object.assign({}, row);
        //delete rowCloned [ 'capturaJustificacion_DddwCapturaJustificacion' ];
        delete rowCloned ['_estado'];
        delete rowCloned._estado;
        delete rowCloned._id;
        delete rowCloned._v;
        delete rowCloned._relaciones;
        return rowCloned;
    }
}
