export class dw_captura_pagoModel {
  public codEmp: number;
  public tipoLineaLin: number;
  public identificacion: number;
  public nroCredito: number;
  public anexoCredito: number;
  public fechaPago: Date;
  public formaPago: number = 1;
  public valorPago: number;
  public estadoPago: number = -1;
  public nroPagos: number = 0;
  public origenPago: string;
  public fechaPrepago: Date;
  public abonoA: number;
  public swPrepagar: number;
  public secuenciaDev: number;
  public nroPagoOrg: number;
  public codigoSucursal: number;
  public usuario: string;
  public nui: number;
  public fechaCancelacion: Date;
  public sucursalOrigen: number;
  public indRetrofecha: number;
  public fechaAnulacion: Date;
  public porcAbogadoAntes: number;
  public nroCupo: number;
  public nroCtaCxp: number;
  public fecCtaCxp: Date;
  public swPagoCheque: number;
  public calRiesgoAntes: string;
  public calTempAntes: string;
  public diasMoraAntes: number;
  public motivoAnulacion: string;
  public usuarioAnulacion: string;
  public proximaCuota: number;
  public orden: number;
  public swAutorizacion: number;
  public swRecalculo: number;
  public nroPagosCond: number;
  public indEstadoRetrofecha: number;
  public indDevCanje: number;
  public indDevCaja: number;
  public valorAModuloCxc: number;
  public ods: number;
  public fechaServidor: Date;
  public motivo: string;
  public vlrPeriodoGracia: number;
  public nroLoteLista: number;
  public fechaPagoLista: Date;
  public fechaRecibeLista: Date;
  public fechaRecibePago: Date;
  public tipoLista: number;
  public filtro: number;
  public lineaCreditoLin: number;
  public tipoPago: number;

  public _secuencia: number;
  public _estado: string = 'N';
  public _id: string;
  public _v: number;
  public _relaciones: any;

  constructor(json: any = null) {
    this._relaciones = {};
    if (json !== null) {
      this.codEmp = json.codEmp;
      this.tipoLineaLin = json.tipoLineaLin;
      this.identificacion = json.identificacion;
      this.nroCredito = json.nroCredito;
      this.anexoCredito = json.anexoCredito;
      this.fechaPago = json.fechaPago;
      this.formaPago = json.formaPago;
      this.valorPago = json.valorPago;
      this.estadoPago = json.estadoPago;
      this.nroPagos = json.nroPagos;
      this.origenPago = json.origenPago;
      this.fechaPrepago = json.fechaPrepago;
      this.abonoA = json.abonoA;
      this.swPrepagar = json.swPrepagar;
      this.secuenciaDev = json.secuenciaDev;
      this.nroPagoOrg = json.nroPagoOrg;
      this.codigoSucursal = json.codigoSucursal;
      this.usuario = json.usuario;
      this.nui = json.nui;
      this.fechaCancelacion = json.fechaCancelacion;
      this.sucursalOrigen = json.sucursalOrigen;
      this.indRetrofecha = json.indRetrofecha;
      this.fechaAnulacion = json.fechaAnulacion;
      this.porcAbogadoAntes = json.porcAbogadoAntes;
      this.nroCupo = json.nroCupo;
      this.nroCtaCxp = json.nroCtaCxp;
      this.fecCtaCxp = json.fecCtaCxp;
      this.swPagoCheque = json.swPagoCheque;
      this.calRiesgoAntes = json.calRiesgoAntes;
      this.calTempAntes = json.calTempAntes;
      this.diasMoraAntes = json.diasMoraAntes;
      this.motivoAnulacion = json.motivoAnulacion;
      this.usuarioAnulacion = json.usuarioAnulacion;
      this.proximaCuota = json.proximaCuota;
      this.orden = json.orden;
      this.swAutorizacion = json.swAutorizacion;
      this.swRecalculo = json.swRecalculo;
      this.nroPagosCond = json.nroPagosCond;
      this.indEstadoRetrofecha = json.indEstadoRetrofecha;
      this.indDevCanje = json.indDevCanje;
      this.indDevCaja = json.indDevCaja;
      this.valorAModuloCxc = json.valorAModuloCxc;
      this.ods = json.ods;
      this.fechaServidor = json.fechaServidor;
      this.motivo = json.motivo;
      this.vlrPeriodoGracia = json.vlrPeriodoGracia;
      this.nroLoteLista = json.nroLoteLista;
      this.fechaPagoLista = json.fechaPagoLista;
      this.fechaRecibeLista = json.fechaRecibeLista;
      this.fechaRecibePago = json.fechaRecibePago;
      this.tipoLista = json.tipoLista;
      this.filtro = json.filtro;
      this.lineaCreditoLin = json.lineaCreditoLin;
      this.tipoPago = json.tipoPago;
    }
  }

  static clone(row: dw_captura_pagoModel): dw_captura_pagoModel {
    const rowCloned = Object.assign({}, row);
    delete rowCloned._secuencia;
    delete rowCloned._estado;
    delete rowCloned._id;
    delete rowCloned._v;
    delete rowCloned._relaciones;
    return rowCloned;
  }

  toClipboard(): string {
    let result = '';

    result += `\t${this.codEmp}`;
    result += `\t${this.tipoLineaLin}`;
    result += `\t${this.identificacion}`;
    result += `\t${this.nroCredito}`;
    result += `\t${this.anexoCredito}`;
    result += `\t${this.fechaPago}`;
    result += `\t${this.formaPago}`;
    result += `\t${this.valorPago}`;
    result += `\t${this.estadoPago}`;
    result += `\t${this.nroPagos}`;
    result += `\t${this.origenPago}`;
    result += `\t${this.fechaPrepago}`;
    result += `\t${this.abonoA}`;
    result += `\t${this.swPrepagar}`;
    result += `\t${this.secuenciaDev}`;
    result += `\t${this.nroPagoOrg}`;
    result += `\t${this.codigoSucursal}`;
    result += `\t${this.usuario}`;
    result += `\t${this.nui}`;
    result += `\t${this.fechaCancelacion}`;
    result += `\t${this.sucursalOrigen}`;
    result += `\t${this.indRetrofecha}`;
    result += `\t${this.fechaAnulacion}`;
    result += `\t${this.porcAbogadoAntes}`;
    result += `\t${this.nroCupo}`;
    result += `\t${this.nroCtaCxp}`;
    result += `\t${this.fecCtaCxp}`;
    result += `\t${this.swPagoCheque}`;
    result += `\t${this.calRiesgoAntes}`;
    result += `\t${this.calTempAntes}`;
    result += `\t${this.diasMoraAntes}`;
    result += `\t${this.motivoAnulacion}`;
    result += `\t${this.usuarioAnulacion}`;
    result += `\t${this.proximaCuota}`;
    result += `\t${this.orden}`;
    result += `\t${this.swAutorizacion}`;
    result += `\t${this.swRecalculo}`;
    result += `\t${this.nroPagosCond}`;
    result += `\t${this.indEstadoRetrofecha}`;
    result += `\t${this.indDevCanje}`;
    result += `\t${this.indDevCaja}`;
    result += `\t${this.valorAModuloCxc}`;
    result += `\t${this.ods}`;
    result += `\t${this.fechaServidor}`;
    result += `\t${this.motivo}`;
    result += `\t${this.vlrPeriodoGracia}`;
    result += `\t${this.nroLoteLista}`;
    result += `\t${this.fechaPagoLista}`;
    result += `\t${this.fechaRecibeLista}`;
    result += `\t${this.fechaRecibePago}`;
    result += `\t${this.tipoLista}`;
    result += `\t${this.filtro}`;
    result += `\t${this.lineaCreditoLin}`;

    return result.substring(1);
  }

  fromClipboard(value: string): dw_captura_pagoModel {
    const result = value.split('\t');

    this.codEmp = Number(<any>(result[0]));
    this.tipoLineaLin = Number(<any>(result[1]));
    this.identificacion = Number(<any>(result[2]));
    this.nroCredito = Number(<any>(result[3]));
    this.anexoCredito = Number(<any>(result[4]));
    this.fechaPago = <Date>(<any>(result[5]));
    this.formaPago = Number(<any>(result[6]));
    this.valorPago = Number(<any>(result[7]));
    this.estadoPago = Number(<any>(result[8]));
    this.nroPagos = Number(<any>(result[9]));
    this.origenPago = String(<any>(result[10]));
    this.fechaPrepago = <Date>(<any>(result[11]));
    this.abonoA = Number(<any>(result[12]));
    this.swPrepagar = Number(<any>(result[13]));
    this.secuenciaDev = Number(<any>(result[14]));
    this.nroPagoOrg = Number(<any>(result[15]));
    this.codigoSucursal = Number(<any>(result[16]));
    this.usuario = String(<any>(result[17]));
    this.nui = Number(<any>(result[18]));
    this.fechaCancelacion = <Date>(<any>(result[19]));
    this.sucursalOrigen = Number(<any>(result[20]));
    this.indRetrofecha = Number(<any>(result[21]));
    this.fechaAnulacion = <Date>(<any>(result[22]));
    this.porcAbogadoAntes = Number(<any>(result[23]));
    this.nroCupo = Number(<any>(result[24]));
    this.nroCtaCxp = Number(<any>(result[25]));
    this.fecCtaCxp = <Date>(<any>(result[26]));
    this.swPagoCheque = Number(<any>(result[27]));
    this.calRiesgoAntes = String(<any>(result[28]));
    this.calTempAntes = String(<any>(result[29]));
    this.diasMoraAntes = Number(<any>(result[30]));
    this.motivoAnulacion = String(<any>(result[31]));
    this.usuarioAnulacion = String(<any>(result[32]));
    this.proximaCuota = Number(<any>(result[33]));
    this.orden = Number(<any>(result[34]));
    this.swAutorizacion = Number(<any>(result[35]));
    this.swRecalculo = Number(<any>(result[36]));
    this.nroPagosCond = Number(<any>(result[37]));
    this.indEstadoRetrofecha = Number(<any>(result[38]));
    this.indDevCanje = Number(<any>(result[39]));
    this.indDevCaja = Number(<any>(result[40]));
    this.valorAModuloCxc = Number(<any>(result[41]));
    this.ods = Number(<any>(result[42]));
    this.fechaServidor = <Date>(<any>(result[43]));
    this.motivo = String(<any>(result[44]));
    this.vlrPeriodoGracia = Number(<any>(result[45]));
    this.nroLoteLista = Number(<any>(result[46]));
    this.fechaPagoLista = <Date>(<any>(result[47]));
    this.fechaRecibeLista = <Date>(<any>(result[48]));
    this.fechaRecibePago = <Date>(<any>(result[49]));
    this.tipoLista = Number(<any>(result[50]));
    this.filtro = Number(<any>(result[51]));
    this.lineaCreditoLin = Number(<any>(result[52]));

    return this;
  }
}
