import { Component, Input, ViewChild, OnInit, OnDestroy, ComponentFactoryResolver, InjectionToken, QueryList } from '@angular/core';

import { ControlComponent } from './control.component';
import { DataWindowControlComponent } from './datawindowcontrol.component';
import { TransactionComponent } from './transaction.component';
import { DataWindowDirective } from './datawindow.directive';

declare var datawindows: Array<DataWindowComponent>
/* tslint:disable */
@Component({
    selector: 'datawindow',
    templateUrl: './datawindow.component.html'
})
export class DataWindowComponent extends ControlComponent implements OnInit, OnDestroy {
    settaborder(arg0: string, arg1: number) {
        throw new Error("Method not implemented.");
    }
    setwsobject(ws_1: any) {
        throw new Error("Method not implemented.");
    }
    TypeOf: string = "datawindow";

    public callbackselectrow: any = (row: any): void => { };

    private _instance: DataWindowControlComponent = null;
    private _dataobject: string = '';
    private _sqlselect: string = '';
    private _sqlca: TransactionComponent = null;

    get object() {
        return this._instance.object;
    }

    get data() {
        return this._instance.data;
    }

    set data(val: Array<any>) {
        this._instance.data = val;
    }

    @Input() set dataobject(value) {
        if (this._dataobject !== value) {
            this._dataobject = value;
            this.loadComponent();
        }
    }

    get dataobject(): string {
        return this._dataobject;
    }

    get retrieved(): boolean {
        return this._instance.retrieved;
    }

    set retrieved(val: boolean) {
        this._instance.retrieved = val;
    }

    public getDddw(name: string): any {
        return this._instance.getDddw(name);
    }

    @ViewChild(DataWindowDirective, { static: true }) public dwHost: DataWindowDirective;

    constructor(private componentFactoryResolver: ComponentFactoryResolver) {
        super();
        this.className = "datawindow";
    }

    ngOnInit() {
    }

    ngOnDestroy() {
        // clearInterval(this.interval);.
    }

    loadComponent() {
        const dwItem = datawindows[this.dataobject];
        console.log("this.componentFactoryResolver ", this.componentFactoryResolver, dwItem);
        if (dwItem) {
            this._instance = null;
            if (this.componentFactoryResolver) { // datawindow - visual 
                const componentFactory = this.componentFactoryResolver.resolveComponentFactory(dwItem);

                const viewContainerRef = this.dwHost.viewContainerRef;
                viewContainerRef.clear();

                const componentRef = viewContainerRef.createComponent(componentFactory);
                this._instance = <DataWindowControlComponent>componentRef.instance;

                this._instance.selectrow = this.callbackselectrow;
                this._instance.container = this;
                this._instance.componentRef = componentRef;
            } else {   // datastore - non-visual
                this._instance = new dwItem();
            }
        } else {
            console.error('datawindow component "' + this.dataobject + '" not found ');
        }
    }

    copy(): any {
        throw new Error("Method not implemented.");
    }
    cut(): any {
        throw new Error("Method not implemented.");
    }

    paste(): any {
        throw new Error("Method not implemented.");
    }

    undo(): any {
        throw new Error("Method not implemented.");
    }

    groupcalc(): any {
        throw new Error("Method not implemented.");
    }
    public title: string = "";
    sharedataoff(): any {
        throw new Error("Method not implemented.");
    }
    sharedata(arg0: any): any {
        throw new Error("Method not implemented.");
    }
    setposition(arg0: any, arg1: any, arg2: any): any {
        throw new Error("Method not implemented.");
    }
    public currentrow: number = null;

    public accepttext(): number {
        console.error("Datawindow - deletedcount: Not Implemented");
        return null;
    }

    public create(...args: Array<any>): any {
        console.error("Datawindow - create: Not Implemented");
        return null;
    }

    public deletedcount(): number {
        console.error("Datawindow - deletedcount: Not Implemented");
        return null;
    }

    public deleterow(arg1: number): number {
        console.error("Datawindow - deletetorow: Not Implemented");
        return null;
    }

    public describe(arg1: string): any {
        return this._instance.describe(arg1);
    }

    public filter(): any {
        console.error("Datawindow - filter: Not Implemented");
        return null;
    }

    public filteredcount(): number {
        console.error("Datawindow - filteredcount: Not Implemented");
        return null;
    }

    public find(arg1: string, from: number, to: number): number {
        var res = 0;
        if (this._instance) {
            res = this._instance.find(arg1, from, to);
        }
        return res;
    }

    public getcolumn(): any {
        console.error("Datawindow - getcolumn: Not Implemented");
        return null;
    }

    public getcolumnname(): string {
        console.error("Datawindow - getcolumnname: Not Implemented");
        return null;
    }

    public getchild(col: string, arg1: any): number {
        let oDddw = this._instance.getchild(col);
        arg1(oDddw);
        return oDddw ? 1 : 0;
    }

    public getfullstate(arg1: any): any {
        console.error("Datawindow - getfullstate: Not Implemented");
        return null;
    }

    public getitem(row: number, arg1: any): any {
        return this._instance.getitem(row, arg1);
    }

    public getitemdecimal(row: number, arg1: any): number {
        return this._instance.getitemdecimal(row, arg1);
    }

    public getitemdate(row: number, arg1: any): any {
        return this._instance.getitemdate(row, arg1);
    }

    public getitemdatetime(row: number, arg1: any): any {
        return this._instance.getitemdatetime(row, arg1);
    }

    public getitemnumber(row: number, arg1: any): number {
        return this._instance.getitemnumber(row, arg1);
    }

    public getitemstatus(...args: Array<any>): any {
        console.error("Datawindow - getitemstatus: Not Implemented");
        return null;
    }

    public getitemstring(...args: Array<any>): string {
        if (args.length > 2) {
            console.error("Datawindow - getitemstring: Not Implemented for " + args.length + ' arguments');
        }
        return this._instance.getitemstring(args[0], args[1]);
    }

    public getitemtime(row: number, arg1: any): any {
        return this._instance.getitemtime(row, arg1);
    }

    public getnextmodified(row: number, arg1: any): any {
        console.error("Datawindow - getnextmodified: Not Implemented");
        return null;
    }

    public getrow(): number {
        return this._instance.currentrow;
    }

    public getrowidfromrow(arg1: any): number {
        console.error("Datawindow - getrowidfromrow: Not Implemented");
        return null;
    }

    public getrowfromrowid(...args: Array<any>): number {
        console.error("Datawindow - getrowidfromrow: Not Implemented");
        return null;
    }

    public getsqlselect(): string {
        return this._sqlselect;
    }

    public getselectedrow(arg1: any): any {
        console.error("Datawindow - getselectedrow: Not Implemented");
        return null;
    }

    public gettext(): string {
        console.error("Datawindow - getrow: Not Implemented");
        return null;
    }

    public importfile(...args: Array<any>): any {
        console.error("Datawindow - importfile: Not Implemented");
        return null;
    }

    public importstring(...args: Array<any>): any {
        console.error("Datawindow - importstring: Not Implemented");
        return null;
    }

    public insertrow(row: number): any {
        row = this._instance.insertrow(row);

        return row;
    }

    public modify(arg1: string): any {
        return this._instance.modify(arg1);
    }

    public modifiedcount(): number {
        console.error("Datawindow - deletedcount: Not Implemented");
        return null;
    }

    public print(): any {
        console.error("Datawindow - print: Not Implemented");
        return null;
    }

    public reset(): any {
        return this._instance ? this._instance.reset() : -1;
    }

    public resetupdate(): any {
        console.error("Datawindow - resetupdate: Not Implemented");
        return null;
    }

    public retrieve(...args: Array<any>): any {
        if (args && args.length > 0) {
            console.error("Datawindow - With Arguments: '" + this.dataobject + "' Not Implemented");
        }
        return this._instance.retrieve(args);
    }

    public rowcount(): number {
        return this._instance ? this._instance.data.length : 0;
    }

    public rowscopy(startrow: number, endrow: number, copybuffer: any, targetdw: DataWindowComponent, beforerow: number, targetbuffer: any): number {
        return this._instance ? this._instance.rowscopy(startrow, endrow, copybuffer, targetdw._instance, beforerow, targetbuffer) : null;
    }

    public rowsdiscard(arg1: number, arg2: number, arg3: any): any {
        console.error("Datawindow - rowsdiscard: Not Implemented");
        return null;
    }

    public rowsmove(...args: Array<any>): any {
        console.error("Datawindow - RowsMove: Not Implemented");
        return null;
    }

    public saveas(...args: Array<any>): any {
        console.error("Datawindow - saveas: Not Implemented");
        return null;
    }

    public setnull(...args: Array<any>): any {
        console.error("Datawindow - saveas: Not Implemented");
        return null;
    }

    public selecttext(from: number, to: number): any {
        console.error("Datawindow - selecttext: Not Implemented");
        return null;
    }

    public scrolltorow(arg1: number): any {
        console.error("Datawindow - scrolltorow: Not Implemented");
        return null;
    }

    public scrollpriorrow(): any {
        console.error("Datawindow - scrollpriorrow: Not Implemented");
        return null;
    }

    public scrollnextrow(): any {
        console.error("Datawindow - scrollpriorrow: Not Implemented");
        return null;
    }

    public selectrow(arg1: number, arg2: boolean): any {
        console.error("Datawindow - selectrow: Not Implemented");
        return null;
    }

    public setcolumn(arg1: any): any {
        console.error("Datawindow - setcolumn: Not Implemented");
        return null;
    }

    public setfilter(arg1: string): any {
        console.error("Datawindow - setfilter: Not Implemented");
        return null;
    }

    public setfullstate(arg1: any): any {
        console.error("Datawindow - setfullstate: Not Implemented");
        return null;
    }

    public setitem(row: number, arg1: any, arg2: any): any {
        return this._instance.setitem(row, arg1, arg2)
    }

    public setitemstatus(...args: Array<any>): any {
        console.error("Datawindow - setitem: Not Implemented");
        return null;
    }

    public setrow(arg1: number): any {
        console.error("Datawindow - setrow: Not Implemented");
        return null;
    }

    public setrowfocusindicator(arg1: any): any {
        console.error("Datawindow - setrow: Not Implemented");
        return null;
    }

    public setsort(arg1: string): any {
        console.error("Datawindow - setsort: Not Implemented");
        return null;
    }

    public setsqlpreview(arg1: string): any {
        console.error("Datawindow - setsqlpreview: Not Implemented");
        return null;
    }

    public setsqlselect(arg1: string): any {
        return this._instance.setsqlselect(arg1);
    }

    public settext(arg1: string): any {
        console.error("Datawindow - setsort: Not Implemented");
        return null;
    }

    public settransaction(arg1: TransactionComponent): any {
        this._instance.settransaction(arg1);
        return 1;
    }

    public settransobject(arg1: TransactionComponent): any {
        this._instance.settransaction(arg1);
        return 1;
    }

    public sort(): any {
        console.error("Datawindow - sort: Not Implemented");
        return null;
    }

    public update(...args: Array<any>): any {
        console.error("Datawindow - update: Not Implemented");
        return null;
    }

    //evemts
    //public on_itemchanged();
    //public on_itemchanged(arg1: any);
    //public on_itemchanged(arg1: any, arg2: any);
    //public on_itemchanged(arg1: any, arg2: any, arg3: any);

    public on_itemchanged(...args: Array<any>): any {
        console.error("Datawindow - update: Not Implemented");
        return null;
    }

    public on_rowfocuschanged(arg1: number): any {
        console.error("Datawindow - update: Not Implemented");
        return null;
    }
}
