import {Component, Inject} from '@angular/core';
import {FormBuilder, FormGroup, Validators, FormControl, ValidationErrors} from '@angular/forms';
import {MatDialogRef, MAT_DIALOG_DATA, MatSnackBar, MatBottomSheet} from '@angular/material';
import {map, switchMap, startWith} from 'rxjs/operators';

import {dw_captura_pagoService} from './dw_captura_pago.service';
import {dw_captura_pagoModel} from './dw_captura_pago.model';
import {AlertasComponent} from 'src/app/components/alertas.component';
import { MAT_DATE_FORMATS, DateAdapter } from '@angular/material';
import { AppDateAdapter, APP_DATE_FORMATS } from 'src/app/components/appdateadapter.component';

@Component({
  templateUrl: './dw_captura_pago.dialog.html',
  // styleUrls: ['./dw_captura_pago.dialog.css'],
  providers: [dw_captura_pagoService,
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS }
  ]
})
export class dw_captura_pagoDialog {
  selecteddw_captura_pago: dw_captura_pagoModel;
  originaldw_captura_pago: dw_captura_pagoModel;

  filteredFormaPago_DddwPagoCarteraNuevo: Array<any> = [];
  filteredAbonoA_DwDddwFacturacion: Array<any> = [];

  dw_captura_pagoForm: FormGroup;

  _proc: boolean = false;
  _status: boolean = false;
  resultError: string = null;

  constructor(private builder: FormBuilder,
              private dw_captura_pagoService: dw_captura_pagoService,
              private _snackBar: MatSnackBar,
              private _bottomSheet: MatBottomSheet,
              public dialogRef: MatDialogRef<dw_captura_pagoDialog>,
              @Inject(MAT_DIALOG_DATA) public data: any) {

    this.selecteddw_captura_pago = data.selected;
    this.originaldw_captura_pago = data.original;

    if (this.selecteddw_captura_pago._estado === 'O') {

      /* this.dw_captura_pagoService.getByIdDddwPagoCarteraNuevo(this.selecteddw_captura_pago.formaPago)
         .subscribe((data) => this.onSelectFormaPago_DddwPagoCarteraNuevo(data));

       this.dw_captura_pagoService.getByIdDwDddwFacturacion(this.selecteddw_captura_pago.abonoA)
         .subscribe((data) => this.onSelectAbonoA_DwDddwFacturacion(data)); */

    }
  }

  ngOnInit() {
    const disabledPKey = this.originaldw_captura_pago._estado === 'O';

    this.dw_captura_pagoForm = this.builder.group({
      'codEmp': [{value: this.selecteddw_captura_pago.codEmp, disabled: true}],
      'tipoLineaLin': [{value: this.selecteddw_captura_pago.tipoLineaLin, disabled: true}],
      'identificacion': [{value: this.selecteddw_captura_pago.identificacion, disabled: true}],
      'nroCredito': [{value: this.selecteddw_captura_pago.nroCredito, disabled: true}],
      'anexoCredito': [{value: this.selecteddw_captura_pago.anexoCredito, disabled: true}],
      'fechaPago': [{value: new Date(this.selecteddw_captura_pago.fechaPago), disabled: true}],
      'formaPago': [{value: this.selecteddw_captura_pago.formaPago, disabled: true}],
      'valorPago': [{value: this.selecteddw_captura_pago.valorPago, disabled: true}],
      'estadoPago': [{value: this.selecteddw_captura_pago.estadoPago, disabled: true}],
      'nroPagos': [{value: this.selecteddw_captura_pago.nroPagos, disabled: true}],
      'origenPago': [{value: this.selecteddw_captura_pago.origenPago,  disabled: true}],
      'fechaPrepago': [{value: new Date(this.selecteddw_captura_pago.fechaPrepago), disabled: true}],
      'abonoA': [{value: this.selecteddw_captura_pago.abonoA, disabled: true}],
      'swPrepagar': [{value: this.selecteddw_captura_pago.swPrepagar, disabled: true}],
      'secuenciaDev': [{value: this.selecteddw_captura_pago.secuenciaDev, disabled: true}],
      'nroPagoOrg': [{value: this.selecteddw_captura_pago.nroPagoOrg, disabled: true}],
      'codigoSucursal': [{value: this.selecteddw_captura_pago.codigoSucursal, disabled: true}],
      'usuario': [{value: this.selecteddw_captura_pago.usuario,  disabled: true}],
      'nui': [{value: this.selecteddw_captura_pago.nui, disabled: true}],
      'fechaCancelacion': [{value: new Date(this.selecteddw_captura_pago.fechaCancelacion), disabled: true}],
      'sucursalOrigen': [{value: this.selecteddw_captura_pago.sucursalOrigen, disabled: true}],
      'indRetrofecha': [{value: this.selecteddw_captura_pago.indRetrofecha, disabled: true}],
      'fechaAnulacion': [{value: new Date(this.selecteddw_captura_pago.fechaAnulacion), disabled: true}],
      'porcAbogadoAntes': [{value: this.selecteddw_captura_pago.porcAbogadoAntes, disabled: true}],
      'nroCupo': [{value: this.selecteddw_captura_pago.nroCupo, disabled: true}],
      'nroCtaCxp': [{value: this.selecteddw_captura_pago.nroCtaCxp, disabled: true}],
      'fecCtaCxp': [{value: new Date(this.selecteddw_captura_pago.fecCtaCxp), disabled: true}],
      'swPagoCheque': [{value: this.selecteddw_captura_pago.swPagoCheque, disabled: true}],
      'calRiesgoAntes': [{value: this.selecteddw_captura_pago.calRiesgoAntes,  disabled: true}],
      'calTempAntes': [{value: this.selecteddw_captura_pago.calTempAntes,  disabled: true}],
      'diasMoraAntes': [{value: this.selecteddw_captura_pago.diasMoraAntes, disabled: true}],
      'motivoAnulacion': [{value: this.selecteddw_captura_pago.motivoAnulacion,  disabled: true}],
      'usuarioAnulacion': [{value: this.selecteddw_captura_pago.usuarioAnulacion,  disabled: true}],
      'proximaCuota': [{value: this.selecteddw_captura_pago.proximaCuota, disabled: true}],
      'orden': [{value: this.selecteddw_captura_pago.orden, disabled: true}],
      'swAutorizacion': [{value: this.selecteddw_captura_pago.swAutorizacion, disabled: true}],
      'swRecalculo': [{value: this.selecteddw_captura_pago.swRecalculo, disabled: true}],
      'nroPagosCond': [{value: this.selecteddw_captura_pago.nroPagosCond, disabled: true}],
      'indEstadoRetrofecha': [{value: this.selecteddw_captura_pago.indEstadoRetrofecha, disabled: true}],
      'indDevCanje': [{value: this.selecteddw_captura_pago.indDevCanje, disabled: true}],
      'indDevCaja': [{value: this.selecteddw_captura_pago.indDevCaja, disabled: true}],
      'valorAModuloCxc': [{value: this.selecteddw_captura_pago.valorAModuloCxc, disabled: true}],
      'ods': [{value: this.selecteddw_captura_pago.ods, disabled: true}],
      'fechaServidor': [{value: new Date(this.selecteddw_captura_pago.fechaServidor), disabled: true}],
      'motivo': [{value: this.selecteddw_captura_pago.motivo,  disabled: true}],
      'vlrPeriodoGracia': [{value: this.selecteddw_captura_pago.vlrPeriodoGracia, disabled: true}],
      'nroLoteLista': [{value: this.selecteddw_captura_pago.nroLoteLista, disabled: true}],
      'fechaPagoLista': [{value: new Date(this.selecteddw_captura_pago.fechaPagoLista), disabled: true}],
      'fechaRecibeLista': [{value: new Date(this.selecteddw_captura_pago.fechaRecibeLista), disabled: true}],
      'fechaRecibePago': [{value: new Date(this.selecteddw_captura_pago.fechaRecibePago), disabled: true}],
      'tipoLista': [{value: this.selecteddw_captura_pago.tipoLista, disabled: true}],
      'filtro': [{value: this.selecteddw_captura_pago.filtro, disabled: true}],
      'lineaCreditoLin': [{value: this.selecteddw_captura_pago.lineaCreditoLin, disabled: true}],
      'formaPago_DddwPagoCarteraNuevo': [{value: '',  disabled: true}],
      'abonoA_DwDddwFacturacion': [{value: '',  disabled: true}],
      '_estado': [{value:this.selecteddw_captura_pago._estado, disabled: true}]
    }, {
      validators: (formGroup: FormGroup): ValidationErrors | null => {
        const data = formGroup.getRawValue();
        let validationErrors = {};

        return validationErrors;
      }
    });

    /* this.dw_captura_pagoForm.valueChanges.subscribe((data) => {

       this.dw_captura_pagoForm.patchValue({}, {emitEvent: false, onlySelf: true});
     });

     this.dw_captura_pagoForm.controls.formaPago_DddwPagoCarteraNuevo.valueChanges
       .pipe(
         startWith(''),
         switchMap((data) => this.dw_captura_pagoService.filterDddwPagoCarteraNuevo(data))
       ).subscribe((data) => this.filteredFormaPago_DddwPagoCarteraNuevo = data.rows);

     this.dw_captura_pagoForm.controls.abonoA_DwDddwFacturacion.valueChanges
       .pipe(
         startWith(''),
         switchMap((data) => this.dw_captura_pagoService.filterDwDddwFacturacion(data))
       ).subscribe((data) => this.filteredAbonoA_DwDddwFacturacion = data.rows);*/
  }

  onSubmit(formData: dw_captura_pagoModel) {
    this._proc = true;
    if (this.dw_captura_pagoForm.valid) {
      formData = Object.assign(dw_captura_pagoModel.clone(this.originaldw_captura_pago), formData);
      this.dw_captura_pagoService.savedw_captura_pago(formData, this.originaldw_captura_pago).subscribe((data: any) => {
        this._proc = false;
        this._status = !!data.error;
        this.resultError = null;

        if (!this._status) {

          if (formData._estado != 'O') {
          }

          this.dialogRef.close({
            data: formData
          });
        } else {
          this.resultError = data.message;
          this.openNotificationDanger(this.resultError);
        }
      });

    }
  }

  onDelete(formData: dw_captura_pagoModel) {
    if (this.dw_captura_pagoForm.valid) {
      let sheetRef = this._bottomSheet.open(AlertasComponent);
      sheetRef.afterDismissed().subscribe(data => {
        if (data.mensaje == 'accept') {
          this._proc = true;
          formData = Object.assign(dw_captura_pagoModel.clone(this.originaldw_captura_pago), formData);
          this.dw_captura_pagoService.deletedw_captura_pago(formData).subscribe((data: any) => {
            this._proc = false;
            this._status = !!data.error;
            this.resultError = null;

            if (!this._status) {
              formData._estado = 'D';
              this.dialogRef.close({
                data: formData,
                delete: true
              });
            } else {
              this.resultError = data.message;
              this.openNotificationDanger(this.resultError);
            }
          });
        }
        ;
      });
    }
    ;
  }

  openNotificationDanger(message: string, action?: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
      panelClass: 'dangerSnackBar',
    });
  }

  onSelectFormaPago_DddwPagoCarteraNuevo(opt: any) {
    this.dw_captura_pagoForm.patchValue({
      formaPago: opt.formaPago,
      formaPago_DddwPagoCarteraNuevo: opt.descripcion
    });
  }

  onSelectAbonoA_DwDddwFacturacion(opt: any) {
    this.dw_captura_pagoForm.patchValue({
      abonoA: opt.codFac,
      abonoA_DwDddwFacturacion: opt.descripcion
    });
  }
}
