import { Component, OnInit, Inject } from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material';

@Component({
  selector: 'app-alertas-confirm',
  templateUrl: './alertasConfirm.component.html',
  styleUrls: ['./alertas.component.css']
})
export class AlertasConfirmComponent implements OnInit {

  tipoMensaje: number;
  descMensaje: string;
  texto: string;
  titulo: string;

  constructor(private bottomSheetRef: MatBottomSheetRef<AlertasConfirmComponent>,
      @Inject(MAT_BOTTOM_SHEET_DATA) public data: any) {
        switch (data.tipoMensaje) {
          case 1:  this.tipoMensaje = 1; this.descMensaje = 'alertas._mensajeConfirmacion'; break;
          case 2:  this.tipoMensaje = 2; this.descMensaje = 'alertas._mensajeConvenio'; break;
          case 3:  this.tipoMensaje = 3; this.descMensaje = 'alertas._mensajeDescargar';break;
          case 4:  this.tipoMensaje = 2; this.descMensaje = 'alertas._mensajeUsuario';break;
          case 5:  this.tipoMensaje = 2; this.descMensaje = 'alertas._mensajeFechas'; break;
          case 6:  this.tipoMensaje = 2; this.descMensaje = 'alertas._mensajePlanoSalida';break;
          case 7:  this.tipoMensaje = 2; this.descMensaje = 'alertas._mensajePlanoSalidaError'; break;
          case 8:  this.tipoMensaje = 2; this.descMensaje = 'alertas._mensajeCierreInconsistencia'; break;
          case 9:  this.tipoMensaje = 1; this.descMensaje = 'alertas._mensajeReasignacionProcesos'; break;
          case 10: this.tipoMensaje = 1; this.descMensaje = 'alertas._mensajeArchivoEnteExterno'; break;
          case 11: this.tipoMensaje = 1; this.descMensaje = 'alertas._mensajeCambioPlanPagos';break;
          case 12: this.tipoMensaje = 2; this.descMensaje = 'alertas._mensajeReabrirProceso'; break;
          case 13: this.tipoMensaje = 2; this.descMensaje = 'alertas._mensajeValidarIdentida';break;
          case 14: this.tipoMensaje = 2; this.descMensaje = 'alertas._mensajeClienteValidado';break;
          case 15: this.tipoMensaje = 2; this.descMensaje = 'alertas._mensajeProcesoFinalizado';break;
          case 16: this.tipoMensaje = 4; this.descMensaje = 'alertas._mensajeProcesoFinalizado';break;
          case 17: this.tipoMensaje = 1; this.descMensaje = 'alertas._mensajeProcesoMasivoPagos'; break;
          case 18: this.tipoMensaje = 2; this.descMensaje = 'alertas._mensajePeriodoPagaduria'; break;
          case 19: this.tipoMensaje = 2; this.descMensaje = 'alertas._mensajePeriodoPagConsulta'; break;
          case 20: this.tipoMensaje = 4; this.descMensaje = 'alertas._mensajeFinProcesoMasivoPagos';break;
          case 21: this.tipoMensaje = 2; this.descMensaje = 'alertas._mensajePeriodoPagFecha';break;
          case 22: this.tipoMensaje = 1; this.descMensaje = 'alertas._mensajeAnularPago'; break;
          case 23: this.tipoMensaje = 1; this.descMensaje = 'alertas._mensajeAplicarPago';break;
          case 24: this.tipoMensaje = 1; this.descMensaje = 'alertas._mensajeProcesoMasivoCierre';break;
          case 25: this.tipoMensaje = 5; this.descMensaje = 'alertas._mensajeDescargar';break;
          case 26: this.tipoMensaje = 1; this.descMensaje = 'alertas._mensajeDiasExpiracion'; break;
          case 27: this.tipoMensaje = 1; this.descMensaje = 'alertas._mensajeCancelacionMasivaFolios';break;
          case 28: this.tipoMensaje = 2; this.descMensaje = 'alertas._mensajeSinCompraCartera'; break;
          case 29: this.tipoMensaje = 4; this.descMensaje = 'alertas._mensajeSinCompraCarteraLiquidar'; break;
          case 30: this.tipoMensaje = 4; this.descMensaje = 'alertas._mensajeEnviarSTP';break;
          case 31: this.tipoMensaje = 4; this.descMensaje = 'alertas._mensajeEnviarPLANO';break;
          case 32: this.tipoMensaje = 2; this.descMensaje = 'alertas._mensajeValidarPreguntas'; break;
          case 33: this.tipoMensaje = 4; this.descMensaje = 'alertas._mensajeAutorizacion'; break;
          case 34: this.tipoMensaje = 1; this.descMensaje = 'alertas._mensajeAnalisisAprobación'; break;
          case 35: this.tipoMensaje = 4; this.descMensaje = 'alertas._mensajeCirculoCredito'; break;
          case 36: this.tipoMensaje = 4; this.descMensaje = 'alertas._mensajeValidaPLD';break;
          case 37: this.tipoMensaje = 4; this.descMensaje = 'alertas._mensajeReserva';break;
          case 38: this.tipoMensaje = 4; this.descMensaje = 'alertas._mensajeReenvioStp'; break;
          case 39: this.tipoMensaje = 4; this.descMensaje = 'alertas._mensajeGenerarPlanoBanxico';break;
          case 40: this.tipoMensaje = 1; this.descMensaje = 'alertas._mensajeValidarBanxico'; break;
          case 41: this.tipoMensaje = 4; this.descMensaje = 'alertas._mensajeValidarCorreoDesembolso';break;
          case 42: this.tipoMensaje = 1; this.descMensaje = 'alertas._mensajeCargaArchivo'; break;
          case 43: this.tipoMensaje = 1; this.descMensaje = 'alertas._mensajeSolicitudDocumentos'; break;
          case 44: this.tipoMensaje = 1; this.descMensaje = 'alertas._mensajeRecepcionFolios'; break;
          case 45: this.tipoMensaje = 2; this.descMensaje = 'alertas._mensajeGestionDocumentos'; break;
          case 46: this.tipoMensaje = 1; this.descMensaje = 'alertas._mensajeAnulacionFolios'; break;
          case 47: this.tipoMensaje = 1; this.descMensaje = 'alertas._mensajeAprobacionCampana'; break;
          case 48: this.tipoMensaje = 4; this.descMensaje = 'alertas._mensajeValidarAsocPlantilla';break;
          case 49: this.tipoMensaje = 4; this.descMensaje = 'alertas._mensajeValidarAprobacion';break;
          case 50: this.tipoMensaje = 1; this.descMensaje = 'alertas._mensajeClasificarCampana'; break;
          case 51: this.tipoMensaje = 1; this.descMensaje = 'alertas._mensajeProcesarCampana'; break;
          case 52: this.tipoMensaje = 1; this.descMensaje = 'alertas._mensajeAprobacionGeneracion'; break;
          case 53: this.tipoMensaje = 4; this.descMensaje = 'alertas._mensajeAprobarEnvioMasivo'; break;
          case 54: this.tipoMensaje = 4; this.descMensaje = 'alertas._mensajeAnularCampana'; break;
          case 55: this.tipoMensaje = 4; this.descMensaje = 'alertas._mensajeEnviarMensajeMasivo'; break;
          case 56: this.tipoMensaje = 4; this.descMensaje = 'alertas._mensajeNoProcesarCampana'; break;
          case 57: this.tipoMensaje = 1; this.descMensaje = 'alertas._mensajeValidarSinProcesarCampana'; break;
          case 58: this.tipoMensaje = 1; this.descMensaje = 'alertas._mensajeProcesadoScoreCampana'; break;
          case 59: this.tipoMensaje = 4; this.descMensaje = 'alertas._mensajeProcesadoScore'; break;
          case 60: this.tipoMensaje = 4; this.descMensaje = 'alertas._mensajeProcesadoCampana'; break;
          case 61: this.tipoMensaje = 4; this.descMensaje = 'alertas._mensajeAplicaFiltroCampana'; break;
          case 62: this.tipoMensaje = 4; this.descMensaje = 'alertas._mensajeAprobadoEnvioCampana'; break;
          case 63: this.tipoMensaje = 4; this.descMensaje = 'alertas._mensajeEjecutaEnvioCampana'; break;
          case 64: this.tipoMensaje = 4; this.descMensaje = 'alertas._mensajeAnulaCampana'; break;
          case 65: this.tipoMensaje = 1; this.descMensaje = 'alertas._mensajeEjecucionArchivoReserva'; break;
          case 66: this.tipoMensaje = 1; this.descMensaje = 'alertas._mensajeAsignarHerramientas'; break;
          case 67: this.tipoMensaje = 2; this.descMensaje = 'alertas._mensajeEstadoCuentaVal'; break;
          case 68: this.tipoMensaje = 2; this.descMensaje = 'alertas._mensajeEstadoCuentaAut'; break;
          case 69: this.tipoMensaje = 1; this.descMensaje = 'alertas._mensajeEstadoCuentaConfirmar'; break;
          case 70: this.tipoMensaje = 2; this.descMensaje = 'alertas._mensajeEstadoCuentaAnulado'; break;
          case 71: this.tipoMensaje = 1; this.descMensaje = 'alertas._mensajeEstadoCuentaProcesar'; break;
          case 72: this.tipoMensaje = 2; this.descMensaje = 'alertas._mensajeEstadoCuentaProc'; break;
          case 73: this.tipoMensaje = 2; this.descMensaje = 'alertas._mensajeEstadoCuentaPdf'; break;
          case 74: this.tipoMensaje = 1; this.descMensaje = 'alertas._mensajeEstadoCuentaGenerarPdf'; break;
          case 75: this.tipoMensaje = 2; this.descMensaje = 'alertas._mensajeEstadoCuentaCorreo'; break;
          case 76: this.tipoMensaje = 4; this.descMensaje = 'alertas._mensajePoliticasPendientes';break;
          case 77: this.tipoMensaje = 1; this.descMensaje = 'alertas._mensajeEstadoCuentaGenerarCorreo'; break;
          case 78: this.tipoMensaje = 1; this.descMensaje = 'alertas._mensajecambioCupo'; break;
          case 79: this.tipoMensaje = 1; this.descMensaje = 'alertas._mensajeBloqueoCarpeta'; break;
          case 80: this.tipoMensaje = 1; this.descMensaje = 'alertas._mensajeEliminarAsesor'; break;
          case 81: this.tipoMensaje = 2; this.descMensaje = 'alertas._mensajeAsesorEliminar'; break;
          case 82: this.tipoMensaje = 2; this.descMensaje = 'alertas._mensajeReservaWorkFlow'; break;
          case 83: this.tipoMensaje = 4; this.descMensaje = 'alertas._mensajeReservaWorkFlowMarcar'; break;
          case 84: this.tipoMensaje = 2; this.descMensaje = 'alertas._mensajeCarpetaTomadaParaAnalisis'; break;
          case 85: this.tipoMensaje = 2; this.descMensaje = 'alertas._mensajeCarpetaDebeTomarOferta'; break;
          case 86: this.tipoMensaje = 1; this.descMensaje = 'alertas._mensajeSeguroGuardarTasa'; break;
          case 87: this.tipoMensaje = 2; this.descMensaje = 'alertas._mensajeNoValidarIdentidadCobranza'; break;
          case 88: this.tipoMensaje = 4; this.descMensaje = 'alertas._mensajeValidarIdentidadCobranza'; break;
          case 89: this.tipoMensaje = 4; this.descMensaje = 'alertas._mensajeRechazarCarpeta'; break;
          case 90: this.tipoMensaje = 4; this.descMensaje = 'alertas._mensajeAprobarCirculo'; break;
          case 91: this.tipoMensaje = 2; this.descMensaje = 'alertas._mensajeAcceso'; break;
          case 92: this.tipoMensaje = 2; this.descMensaje = 'alertas._carpetaCondicionesBloqueantes'; break;
          case 93: this.tipoMensaje = 2; this.descMensaje = 'alertas._mensajePolizaFamiliares'; break;
          case 94: this.tipoMensaje = 2; this.descMensaje = 'alertas._mensajePolizaNoFamiliares'; break;
          case 95: this.tipoMensaje = 2; this.descMensaje = 'alertas._mensajePolizaBeneficiarios'; break;
          case 96: this.tipoMensaje = 2; this.descMensaje = 'alertas._mensajeParametriaFormatoPqrs'; break;
          case 97: this.tipoMensaje = 4; this.descMensaje = 'alertas._mensajeCompraCartera'; break;
          case 98: this.tipoMensaje = 2; this.descMensaje = 'alertas._mensajeCambiarSTP'; break;
          case 99: this.tipoMensaje = 2; this.descMensaje = 'alertas._mensajeCambiarCOMPRAS'; break;
          case 100: this.tipoMensaje = 2; this.descMensaje = 'alertas._mensajeAlertaInstalacion'; break;
          case 101: this.tipoMensaje = 4; this.descMensaje = 'alertas._mensajeConfirmarInstalacion'; break;
          case 102: this.tipoMensaje = 2; this.descMensaje = 'alertas._mensajeObligaInstalar'; break;
          case 103: this.tipoMensaje = 1; this.descMensaje = 'alertas._mensajeDeshaceInstalacion'; break;
          case 104: this.tipoMensaje = 2; this.descMensaje = 'alertas._mensajeValidarDesembolso'; break;
          case 105: this.tipoMensaje = 2; this.descMensaje = 'alertas._mensajeControlPoliticas'; break;
          case 106: this.tipoMensaje = 2; this.descMensaje = 'alertas._mensajeCalendarioEntregasPeriodo'; break;
          case 107: this.tipoMensaje = 1; this.descMensaje = 'alertas._mensajeMarcarProcesadoManuamente'; break;
          case 108: this.tipoMensaje = 4; this.descMensaje = 'alertas._mensajeMarcarProcesadoExito'; break;
          case 109: this.tipoMensaje = 2; this.descMensaje = 'alertas._mensajeDesembolsoControlPoliticas'; break;
          case 110: this.tipoMensaje = 2; this.descMensaje = 'alertas._mensajeSolicitudPoliticas'; break;
          case 111: this.tipoMensaje = 1; this.descMensaje = 'alertas._mensajeEnvioCierreDiario'; break;
          case 112: this.tipoMensaje = 2; this.descMensaje = 'alertas._mensajeIndRadicado'; break;
          case 113: this.tipoMensaje = 2; this.descMensaje = 'alertas._mensajeDocumentosReserva'; break;
          case 114: this.tipoMensaje = 2; this.descMensaje = 'alertas._mensajeCargarDocumentoSoporte'; break;
          case 115: this.tipoMensaje = 2; this.descMensaje = 'alertas._mensajeDocumentosInstalacion'; break;
          case 116: this.tipoMensaje = 2; this.descMensaje = 'alertas._mensajeAlertaTomada'; break;
          case 117: this.tipoMensaje = 2; this.descMensaje = 'alertas._mensajeAlertaDesMarcar'; break;
          case 118: this.tipoMensaje = 1; this.descMensaje = 'alertas._mensajeReporteClientes';break;
          case 119: this.tipoMensaje = 1; this.descMensaje = 'alertas._mensajeDocumentosDevuelto';break;
          case 120: this.tipoMensaje = 1; this.descMensaje = 'alertas._mensajeBloqueo';break;
          case 121: this.tipoMensaje = 2; this.descMensaje = 'alertas._mensajeDarBaja';break;
          case 122: this.tipoMensaje = 1; this.descMensaje = 'alertas._mensajeTraslado';break;
          case 123: this.tipoMensaje = 2; this.descMensaje = 'alertas._mensajeSolicitudCambioPuesto'; break;
          case 124: this.tipoMensaje = 2; this.descMensaje = 'alertas._mensajeProcesoCambioPuesto'; break;
          case 125: this.tipoMensaje = 2; this.descMensaje = 'alertas._mensajeSolicitudCambioFigura'; break;
          case 126: this.tipoMensaje = 2; this.descMensaje = 'alertas._mensajeProcesoCambioFigura'; break;
          case 127: this.tipoMensaje = 4; this.descMensaje = 'alertas._mensajeMarcarParaNoAplicar'; break;
          case 128: this.tipoMensaje = 1; this.descMensaje = 'alertas._mensajeSeguroNoaplicar'; break;
          case 129: this.tipoMensaje = 2; this.descMensaje = 'alertas._mensajeSolicitudTrasladoAsesor'; break;
          case 130: this.tipoMensaje = 2; this.descMensaje = 'alertas._mensajeProcesoTrasladoAsesor'; break;
          case 131: this.tipoMensaje = 2; this.descMensaje = 'alertas._mensajeImprimirFormatos'; break;
          case 132: this.tipoMensaje = 4; this.descMensaje = 'alertas._mensajeProcesoExitoso'; break;
          case 133: this.tipoMensaje = 1; this.descMensaje = 'alertas._mensajeDesBloqueoCarpeta'; break;
          case 134: this.tipoMensaje = 4; this.descMensaje = 'alertas._mensajeBloqueoQuebrando'; break;
          case 135: this.tipoMensaje = 4; this.descMensaje = 'alertas._mensajePqrCreado'; break;
          case 136: this.tipoMensaje = 2; this.descMensaje = 'alertas._mensajeValidacionPld'; break;
          case 137: this.tipoMensaje = 4; this.descMensaje = 'alertas._mensajeDeseaSalirMasivoPagos';break;
          case 138: this.tipoMensaje = 1; this.descMensaje = 'alertas._mensajeReportePagos';break;
          case 139: this.tipoMensaje = 2; this.descMensaje = 'alertas._mensajeFolioNoCancelado';break;
          case 140: this.tipoMensaje = 2; this.descMensaje = 'alertas._mensajeBaja';break;
          case 141: this.tipoMensaje = 2; this.descMensaje = 'alertas._mensajeBajaError';break;
          case 142: this.tipoMensaje = 1; this.descMensaje = 'alertas._mensajeErrorProcesarPagos'; break;
          case 143: this.tipoMensaje = 1; this.descMensaje = 'alertas._mensajeCambioRFC'; break;
          case 144: this.tipoMensaje = 1; this.descMensaje = 'alertas._mensajeFinalizarRegistroGuion'; break;
          case 145: this.tipoMensaje = 1; this.descMensaje = 'alertas._mensajeGuardarCambiosRegistroGuion'; break;
          case 146: this.tipoMensaje = 2; this.descMensaje = 'alertas._mensajeNoGeneracionCarta'; break;
          case 147: this.tipoMensaje = 1; this.descMensaje = 'alertas._mensajeMarcarPagoExitoso'; break;
          case 148: this.tipoMensaje = 2; this.descMensaje = 'alertas._mensajeValidacionPldDocsFase2'; break;
          case 149: this.tipoMensaje = 2; this.descMensaje = 'alertas._mensajeValidacionPldFase2'; break;
          case 150: this.tipoMensaje = 2; this.descMensaje = 'alertas._mensajeValidacionDocsFase2'; break;
          case 151: this.tipoMensaje = 2; this.descMensaje = 'alertas._mensajeAutorizacionPeriodoGracia'; break;
          case 152: this.tipoMensaje = 1; this.descMensaje = 'alertas._mensajeValidacionGirarReembolso'; break;
          case 153: this.tipoMensaje = 1; this.descMensaje = 'alertas._mensajeValidacionAnularReembolso'; break;
          case 154: this.tipoMensaje = 1; this.descMensaje = 'alertas._mensajeValidacionCierreMasivoSolicitudes'; break;
          case 155: this.tipoMensaje = 1; this.descMensaje = 'alertas._mensajeNuevaCuentaSinArchivo'; break;
          case 156: this.tipoMensaje = 1; this.descMensaje = 'alertas._mensajeEjecucionFecInstMasivo'; break;
          case 157:  this.tipoMensaje = 1; this.descMensaje = 'alertas._mensajeConfirmacionCambioConvenio'; break;
          case 158: this.tipoMensaje = 1; this.descMensaje = 'alertas._mensajeAnulaCxp'; break;
          case 159: this.tipoMensaje = 6; this.descMensaje = 'alertas._mensajeCreoCxp'; break;
          case 160: this.tipoMensaje = 2; this.descMensaje = 'alertas._mensajeValidarIdentificacionOficial';break;
          case 161:  this.tipoMensaje = 1; this.descMensaje = 'alertas._mensajeConfirmacionCondonacionImss'; break;
          case 162:  this.tipoMensaje = 1; this.descMensaje = 'alertas._mensajeFondeadorAlta'; break;
          case 163:  this.tipoMensaje = 1; this.descMensaje = 'alertas._mensajeFondeadorBaja'; break;
          case 164: this.tipoMensaje = 2; this.descMensaje = 'alertas._mensajePolizaEstado'; break;
          case 165: this.tipoMensaje = 1; this.descMensaje = 'alertas._mensajePolizaCancelar'; break;
          default: break;
        }
      }

  ngOnInit() {
    this.titulo = this.data;
    this.texto = this.data.texto;
  }

  btnProceso(tipo: string): void {
    this.bottomSheetRef.dismiss({
      mensaje: tipo
    })
  }
}
