import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar, MatBottomSheet } from '@angular/material';

import { CambiarClaveService } from './cambiarclave.service';
import { CambiarClaveModel } from './cambiarclave.model';

@Component({
    templateUrl: './cambiarclave.dialog.html',
    // styleUrls: ['./cambiarclave.dialog.css'],
    providers: [CambiarClaveService]
})
export class CambiarClaveDialog {
    user: CambiarClaveModel;
    cambioClaveForm: FormGroup;
    _proc: boolean = false;
    _status: boolean = false;
    resultError: string = null;
    caracteresEspeciales = "_+!@$%^&(){}:;<>*.|=-";
    
    constructor(private builder: FormBuilder,
        private clasesLineaCreditoService: CambiarClaveService,
        private _snackBar: MatSnackBar,
        private _bottomSheet: MatBottomSheet,
        public dialogRef: MatDialogRef<CambiarClaveDialog>,
        @Inject(MAT_DIALOG_DATA) public data: any) {
        this.user = data;
    }
    ngOnInit() {    
        this.cambioClaveForm = this.builder.group({
            'username': [{ value: this.user.username, disabled: true }, [Validators.required]],
            'password': ['', [Validators.required]],
            'passwordnew': ['', [
                Validators.required,
                Validators.pattern(/(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[_+!@$%^&(){}:;<>*.|=-]).{8,15}/),
            ]
            ],
            'confirmpasswordnew': ['', [Validators.required]],
        }, {
            validator: this.checkPasswords
        });

        this.cambioClaveForm.valueChanges.subscribe((data) => {
            this.cambioClaveForm.patchValue({
            }, { emitEvent: false, onlySelf: true });
        });
    }
    checkPasswords(group: FormGroup) { // here we have the 'passwords' group
        let pass = group.get('passwordnew').value;
        let confirmPass = group.get('confirmpasswordnew').value;

        return pass === confirmPass ? null : { notSame: true }
    }
    onSubmit(formData: CambiarClaveModel) {
        this._proc = true;
        if (this.cambioClaveForm.valid) {
            formData = Object.assign(CambiarClaveModel.clone(this.user), formData);
            this.clasesLineaCreditoService.cambioClave(formData).subscribe((data: any) => {
                this._proc = false;
                this._status = !!data.error;
                this.resultError = null;
                if (!this._status) {
                    this.dialogRef.close({
                        data: formData
                    });
                } else {
                    this.resultError = data.message;
                    this.openNotificationDanger(this.resultError)
                }
            });

        }
    }
    openNotificationDanger(message: string, action?: string) {
        this._snackBar.open(message, action, {
            duration: 2000,
            panelClass: 'dangerSnackBar',
        });
    }
}
