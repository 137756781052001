import { Component, ViewChild, AfterViewInit, Input, Output, EventEmitter, ElementRef, Inject } from '@angular/core';
import { MatPaginator, MatSort, PageEvent } from '@angular/material';
import { merge, Observable, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar, MatBottomSheet, MatDialog } from '@angular/material';
import { dw_captura_pagoDialog } from './dw_captura_pago.dialog';
import { dw_captura_pagoService } from './dw_captura_pago.service';
import { dw_captura_pagoModel } from './dw_captura_pago.model';
import Utils from '../../../services/utilidades';

export const CONDITIONS_LIST = [
  { value: 'like', label: 'Contains' },
  { value: 'not like', label: 'Not Contains' },
  { value: '=', label: 'Is equal' },
  { value: '<>', label: 'Is not equal' }
];

export const CONDITIONS_LIST_NUMBER = [
  { value: '>', label: 'Greater Than' },
  { value: '>=', label: 'Greater or Equal' },
  { value: '<', label: 'Less Than' },
  { value: '<=', label: 'Less or Equal' },
  { value: '=', label: 'Is equal' },
  { value: '<>', label: 'Is not equal' }
];

@Component({
  selector: 'dw-captura-pago-table',
  templateUrl: './dw_captura_pago.table.html',
  // styleUrls: ['./dw_captura_pago.table.css'],
  providers: [dw_captura_pagoService]
})
export class dw_captura_pagoTable implements AfterViewInit {

  DddwPagoCarteraNuevo: any[] = [];
  DwDddwFacturacion: any[] = [];
  rows: dw_captura_pagoModel[] = [];
  originals: dw_captura_pagoModel[] = [];
  selectedRow: dw_captura_pagoModel;
  selectedIndex: number = 0;
  originalRow: dw_captura_pagoModel;
  // url = `${environment.dataServiceUrl}/Cartera/dw_captura_pago`;


  public displayedColumns: string[] = ['ind_retrofecha', 'fecha_pago', 'valor_pago', 'forma_pago', 'tipo_pago', 'estado_pago', 'fecha_pago_lista', 'fecha_recibe_pago',
    'fecha_prepago', 'nro_pagos', 'motivo_anulacion', 'fecha_cancelacion', 'abono_a', 'sw_prepagar', 'nro_credito', 'sw_pago_cheque',
    'porc_abogado_antes', 'cal_riesgo_antes',  'dias_mora_antes', 'identificacion', 'linea_credito_lin', 'tipo_linea_lin', 'cod_emp',
    'nui', 'sucursal_origen', 'codigo_sucursal', 'usuario', 'usuario_anulacion' , 'fecha_anulacion', 'nro_lote_lista'
    /* 'cal_temp_antes', 'orden',  'proxima_cuota','sw_autorizacion', 'sw_recalculo', 'nro_pagos_cond', 'ind_estado_retrofecha', 'ind_dev_canje',
    'ind_dev_caja', 'valor_a_modulo_cxc', 'ods', 'fecha_servidor',  'vlr_periodo_gracia',  'fecha_recibe_lista','fecha_recibe_pago',
    'tipo_lista', 'filtro', 'origen_pago', 'anexo_credito',  'nro_cupo', 'secuencia_dev', 'nro_pago_org',*/
  ];

  public estadoPagoArray: any[] = [
    { code: 1, desc: 'Pendiente' },
    { code: 2, desc: ' Aplicado' },
    { code: 4, desc: 'Anulado Masivo'},
    { code: 5, desc: ' Anulado' },
    { code: 8, desc: ' Reversado' },
    { code: 9, desc: ' Masivo No Aplicado' },
    { code: 47, desc: ' Re estructuración' },
    { code: 48, desc: ' Desembolso Adicional'}
  ];

  public tipoPagoArray: any[] = [
    { code: 0, desc: 'Efectivo' },
    { code: 1, desc: 'Cheque' },
    { code: 2, desc: 'Transferencia' },
    { code: 3, desc: 'Transferencia Electronica' }
  ];

  public FormaAplicarArray: any[] = [
    { code: 0, desc: 'N/A' },
    { code: 1, desc: 'Reducir plazo' },
    { code: 2, desc: 'Abono Cuotas' },
    { code: 3, desc: 'Reducir Cuota' },
    { code: 4, desc: 'Pago total' },
    { code: 5, desc: 'ICR/AIS' }
  ];

  public calificacionArray: any[] = [
    { code: '1', desc: 'A' },
    { code: '2', desc: 'B' },
    { code: '3', desc: 'C' },
    { code: '4', desc: 'D' },
    { code: '5', desc: 'E' },
    { code: '6', desc: 'K' }
  ];

  public conditionsList = CONDITIONS_LIST;
  public conditionsListNumber = CONDITIONS_LIST_NUMBER;
  public searchValue: any = {};
  public searchCondition: any = {};
  public _pageSize = 10;

  public formaPagoList = [];

  filter = {
    column: '',
    condition: '',
    value: ''
  };

  resultsLength = 0;
  isLoadingResults = true;
  isRateLimitReached = false;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  _proc: boolean = false;
  _status: boolean = false;
  resultError: string = null;

  numCredito: number;
  utils = Utils;

  constructor(public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private _bottomSheet: MatBottomSheet,
    private dw_captura_pagoService: dw_captura_pagoService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.numCredito = data.nroCredito;
  }

  ngAfterViewInit() {
    /* this.dw_captura_pagoService.getAllDddwPagoCarteraNuevo().subscribe((data: any) => {
       this.DddwPagoCarteraNuevo = [...data];
     });

     this.dw_captura_pagoService.getAllDwDddwFacturacion().subscribe((data: any) => {
       this.DwDddwFacturacion = [...data];
     });*/

    // If the user changes the sort order, reset back to the first page.
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    this.dw_captura_pagoService.getFormaPago().subscribe((rst)=>{
      console.log('conceptos fac: ', rst);
      this.formaPagoList = rst;
    });

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          let sortExpr = '';
          if (this.sort.active) {
            sortExpr = `${this.sort.active} ${this.sort.direction}`;
          }
          /*     return this.carteraServ.getList(      this.filter,
               this.paginator,
               this.sort,
               this.url + 'Recuperar/' + '1/' + '2038547/' + '37238955',
               'dw_captura_pago');
           }),*/
          return this.dw_captura_pagoService.getdw_captura_pagoList(
            this.filter,
            this.paginator,
            this.sort,
            this.data
          );
        }),
        map((data: any) => {
          // Flip flag to show that loading has finished.
          this.isLoadingResults = false;
          this.isRateLimitReached = false;
          this.resultsLength = data.count;
          return data.rows;
        }),
        catchError(() => {
          this.isLoadingResults = false;
          // Catch if the API has reached its rate limit. Return empty data.
          this.isRateLimitReached = true;
          return observableOf([]);
        })
      ).subscribe((data: any) => {
        this.rows = [...data];
        this.originals = data;
      });
  }

  onAdd(): void {
    this.selectedRow = new dw_captura_pagoModel();
    this.originalRow = new dw_captura_pagoModel();
    this.selectedIndex = 0;

    this.openDialog();
  }

  onEdit(): void {
    if (this.selectedIndex >= 0) {
      this.selectedRow._estado = 'O';
      this.openDialog();
    }
  }

  onRefresh() {
    this.filter.column = '';
    this.filter.value = '';
    this.filter.condition = '';

    this.ngAfterViewInit();
  }

  onChangePage() {
    this.ngAfterViewInit();
  }

  onSelect(event: Event, row: dw_captura_pagoModel, index: number) {
    this.selectedRow = row;
    this.selectedIndex = index;
    this.originalRow = this.originals[index];
  }

  onSelectAndEdit(event, row: dw_captura_pagoModel, index: number) {
    this.selectedRow = row;
    this.selectedRow._estado = 'O';
    this.selectedIndex = index;
    this.originalRow = this.originals[index];
    this.originalRow._estado = 'O';

    this.openDialog();
  }

  onApplyFilter(e: Event, columnDB: string, columnModel: string) {
    this.filter.column = columnDB;
    this.filter.condition = this.searchCondition[columnModel];
    this.filter.value = this.searchValue[columnModel];

    let evt = new PageEvent();
    evt.pageIndex = 0;
    this.paginator.page.emit(evt);
  }

  onClearColumn(columnModel: string) {
    this.searchValue[columnModel] = '';
    this.searchCondition[columnModel] = '';
    this.onRefresh();
  }

  getFormaPago(formaPago: number){
    let result = this.formaPagoList.find(estado => estado.formaPago === formaPago) || {};
    return result.descripcion || '';
  }

  getEstadoPago(code: number) {
    let result = this.estadoPagoArray.find(estado => estado.code === code) || {};
    return result.desc || '';
  }

  getFormaAplicar(code) {
    let result = this.FormaAplicarArray.find(estado => estado.code === code) || {};
    return result.desc || '';
  }

  getCalificacion(codCalf) {
    let result = this.calificacionArray.find(estado => estado.code === codCalf) || {};
    return result.desc || '';
  }

  onExport() {
    const newPaginator = this.paginator.pageSize;
    this.paginator.pageSize = this.resultsLength;
    this.dw_captura_pagoService.getdw_captura_pagoList(this.filter,
      this.paginator,
      this.sort,
      this.data).subscribe((data)=>{
        this.paginator.pageSize = newPaginator;
        this.originals = data.rows;
        this.utils.onExport('ConsultaPagos' + this.numCredito.toString(), this.originals, [], this._bottomSheet);
    });


  }

  onTotals(data: any) {
    Object.assign(this.selectedRow, data);
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(dw_captura_pagoDialog, {
      data: {
        selected: this.selectedRow,
        original: this.originalRow
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        Object.assign(this.selectedRow, result.data);
        switch (this.selectedRow._estado) {
          case 'N':
            this.selectedRow._estado = 'O';
            this.selectedIndex = this.rows.length;
            this.rows.push(this.selectedRow);
            this.rows = [...this.rows];
            this.originals.push(this.selectedRow);
            this.originalRow = this.selectedRow;
            this.resultsLength = this.rows.length;
            break;
          case 'D':
            this.rows.splice(this.selectedIndex, 1);
            this.originals.splice(this.selectedIndex, 1);
            this.rows = [...this.rows];
            this.selectedIndex = -1;
            this.selectedRow = null;
            break;
        }
      }
    });
  }

  openNotificationDanger(message: string, action?: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
      panelClass: 'dangerSnackBar',
    });
  }

  getChildDddwPagoCarteraNuevo(id: number) {
    let d = this.DddwPagoCarteraNuevo.find(val => val.formaPago === id) || {};
    return d.descripcion || '';
  }

  getChildDwDddwFacturacion(id: number) {
    let d = this.DwDddwFacturacion.find(val => val.codFac === id) || {};
    return d.descripcion || '';
  }

  getTipoPago(code: number) {
    let result = this.tipoPagoArray.find(estado => estado.code === code) || {};
    return result.desc || '';
  }
}
