import { Injectable } from '@angular/core';
import { CurrencyPipe, DatePipe, DecimalPipe } from '@angular/common';

@Injectable({ providedIn: 'root' })
export class TableService {
    constructor(
        private currencyPipe: CurrencyPipe,
        private datePipe: DatePipe,
        private decimalPipe: DecimalPipe
        ) {}

    setCurrencyPipe(val: any) {
        return this.currencyPipe.transform(val, '$');
    }
    setDatePipe(val: Date, dateFormat?: string) {
        let format = 'dd/MM/yyyy';
        if (dateFormat) {
            format = dateFormat;
        }
        return this.datePipe.transform(val, format);
    }
    setDecimalPipe(val: number, decimalFormat?: string) {
      let format = '';
        if (decimalFormat) {
            format = decimalFormat;
        }
        return this.decimalPipe.transform(val, format);
    }
}
