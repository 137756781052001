import { DetallesPagosTable } from './../detallespagos/detallespagos.table';
import { Component, ViewChild, AfterViewInit, Inject } from '@angular/core';
import { MatPaginator, MatSort, PageEvent, MatDialog, MatSnackBar, MAT_DIALOG_DATA, MatDialogRef, MatBottomSheet } from '@angular/material';
import { merge, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { AlertasConfirmComponent } from 'src/app/components/alertasConfirm.component';
import * as moment from 'moment';

import { BlockUI, NgBlockUI } from 'ng-block-ui';

import { ChartDataSets, ChartOptions, ChartPoint, ChartType } from 'chart.js';

import { Label, Color } from 'ng2-charts';
import { ProcesosMultiplesReloadedService } from './procesosmultiplesreloaded.service';
import { GestionAvanceProcesosMultiplesDialog } from '../gestionavanceprocesosmultiples/gestionavanceprocesosmultiples.dialog';
import { GestionAvanceProcesosMultiplesService } from '../gestionavanceprocesosmultiples/gestionavanceprocesosmultiples.service';
import { ProcesosMultiplesReloadedModel } from './procesosmultiplesreloaded.model';

declare var CONDITIONS_LIST: any;
declare var CONDITIONS_LIST_NUMBER: any;
declare var appComponent: any;

@Component({
  selector: 'gestionavanceprocesos-table',
  templateUrl: './procesosmultiplesreloaded.table.html',
  styleUrls: ['./procesosmultiplesreloaded.table.css'],
  providers: [ProcesosMultiplesReloadedService]
})
export class ProcesosMultiplesReloadedTable implements AfterViewInit  {
    rows: any[] = [];
    selectedRow: ProcesosMultiplesReloadedModel;
    selectedIndex: number = 0;

    barra = true;

    idInterval: any;
    progreso: number = 0;
    procesosRealizados: number = 0;
    totalProcesos: number = 0;
    complete: boolean = false;
    mensajeRespuesta: string = '';
    verGrafica1 = false;

    pagosSegundos = 0;
    registroTotal = 0;
    procesosTotal = 0;

    intervalSingle:any;
    progressSingle = 0;

    totalPagos = 0;
    aplicados = 0;
    pendientes = 0;
    errados = 0;
    avance = 0;
    avancenum=0;

    //VARIABLES GRAFICAS
    public barChartOptions: ChartOptions = { responsive: true,
      scales: {
        yAxes: [
            {
                ticks: {
                    min: 0,
                    max: 50
                }
            }
        ]
      }
    };

    public barChartOptions1: ChartOptions = { responsive: true,
    scales: {
        yAxes: [
            {
                ticks: {
                    min: 0,
                    max: 50
                }
            }
        ]
      }
    };

    public barChartTypeHorizontalBar: ChartType = 'horizontalBar';
    public lineChartType = 'line';
    public lineChartType1 = 'line';
    public barChartType: ChartType = 'bar';
    public barChartLabels: Label[];
    public barChartLabels1: Label[];
    public barChartLegend = true;
    public barChartPlugins = [];
    public barChartLegend1 = true;
    public barChartPlugins1 = [];

        // VARIABLES PARA DATA DE GRAFICAS
    public numeroProcesosEjecutados: any = [];
    public fecha: number[] | ChartPoint[];
    public segTiempoProcXRegistro: any = [];

    public data1: any = [];

    public totalRegistros: any = [];
    public totalProcesosFecha: any;
    public verGrafica = false;

        // DATA PARA GRAFICAS
    public tittle: string = '';
    public barChartDataProcesosEjecutados:  ChartDataSets[] = [];
    public barChartDataTotalRegistros: ChartDataSets[] = [];
    public barChartDataSegTiempoRegistro:  ChartDataSets[] = [];
    public barChartDataTiempoxRegistros:  ChartDataSets[] = [];

    //TEST1
    public barChartTest1:  ChartDataSets[] = [];

    //'codigo_proceso'
    public displayedColumns: string[] = [ 'avance', 'nombre_evento', 'codigo_retorno', 'usuario', 'total','aplicados','pendientes','errados','procesamientosegundo','pagossegundos'];

    public conditionsList = CONDITIONS_LIST;
    public conditionsListNumber = CONDITIONS_LIST_NUMBER;
    public searchValue: any = {};
    public searchCondition: any = {};
    public _pageSize = 10;

    filter = {
      column: '',
      condition: '',
      value: ''
    };

    selectedColumn = {
      name: '',
      dbName: '',
      type: '',
      sign: '',
      filter: []
    };

    filtro = {
      idProceso: 0,
      codEmpresa: 0,
      lista: []
    };

    resultsLength = 0;
    isLoadingResults = false;
    isRateLimitReached = false;

    numeroProceso: number = 0;
    fechaHoraActual: Date;
    sgProceso: number = 0;
    mnProceso: number = 0;
    hrProceso: number = 0;

    intervalId: any;
    procesoFin: any = [];

    @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
    @ViewChild(MatSort, {static: false}) sort: MatSort;
    @BlockUI() blockUI: NgBlockUI;

    _proc: boolean = false;
    _status: boolean = false;
    resultError: string = null;

    list: any = [];
    single: any;
    seleccionados: any;

    constructor(public dialog: MatDialog,
                private _snackBar: MatSnackBar,
                private _bottomSheet: MatBottomSheet,
                private gestionAvanceProcesosService: ProcesosMultiplesReloadedService,
                public dialogRef: MatDialogRef<ProcesosMultiplesReloadedTable>,
                @Inject(MAT_DIALOG_DATA) public data: any) {

        this.dialogRef.disableClose = true;
        this.seleccionados = data.seleccionados;
        this.filtro.idProceso = data.numeroProceso;
        this.filtro.lista = data.lista;
        this.single = data.single;
        this.numeroProceso = data.numeroProceso;
        this.filtro.codEmpresa = Number(appComponent.usuario.mpr);
        this.fechaHoraActual = new Date();
    }

    ngAfterViewInit() {
      this.onCalcularAvance(this.filtro);
        this.idInterval = setInterval(() => {
          this.onCabeceraProceso(this.filtro.idProceso);
          this.onDetalleProceso(this.filtro.idProceso);
        }, 5000);
    }


    onCalcularAvanceSingle(filtro:any){
      this.list = [];
      this.gestionAvanceProcesosService.getAvanceProcesosAll(filtro).subscribe((data: any) => {
        this._status = !!data.error;
        this.resultError = null;

        if (!this._status) {
            this.progressSingle = ((data.rows[0].registroActual / data.rows[0].totalRegistros) * 100);

            this.list.push({
              codigoProceso: data.rows[0].codigoProceso,
              procesoRealizados: data.rows[0].registroActual,
              totalProceso: data.rows[0].totalRegistros,
              progreso: ((data.rows[0].registroActual / data.rows[0].totalRegistros) * 100),
              mensajeRespuesta: data.rows[0].mensajeActual
            });

            this.procesosTotal =  data.rows[0].registroActual;
            this.registroTotal =  data.rows[0].totalRegistros;
            this.progreso =  (( this.procesosTotal / this.registroTotal ) * 100);

        } else {
          this.resultError = data.message;
        };
      });
    }

    onDetalleProceso(proceso:any) {
      this.gestionAvanceProcesosService.getDetalleProceso(proceso).subscribe((data: any) => {
        this._status = !!data.error;
        this.resultError = null;

        if (!this._status) {
          this.rows = [...data];          
          this.onTiempoProceso();
        };

      });
    }

    onTiempoProceso() {
      let hrInicial = this.rows[0].hrini;
      let hrFinal = this.rows[0].hrfin;

      if (hrInicial !== undefined && hrInicial !== null && hrFinal !== undefined && hrFinal !== null) {
        this.rows.forEach(reg => {
          let hrIni = moment(reg.hrini);
          let hrFin = moment(reg.hrfin);
          if (hrIni.isBefore(hrInicial)) { hrInicial = hrIni };
          if (hrFin.isAfter(hrFinal)) { hrFinal = hrFin };
        });

        let hrIni = moment(hrInicial);
        let hrFin = moment(hrFinal);
        let diff = hrFin.diff(hrIni)/1000;
        let sg = 0;
        let mn = 0;
        let hr = 0;

        if (diff >= 60) {
            sg = diff%60;
            let m = (diff - sg)/60;

            if (m >= 60) {
                mn = m%60;
                hr = (m - mn)/60;

            } else {
                mn = m;
            };

        } else {
            sg = diff;
        };

        this.sgProceso = sg;
        this.mnProceso = mn;
        this.hrProceso = hr;

      } else {
        this.sgProceso = 0;
        this.mnProceso = 0;
        this.hrProceso = 0;
      };
    }

    verAplicados(){
      const dialogRef = this.dialog.open(DetallesPagosTable, {
        data: {
          numeroProceso: this.filtro.idProceso,
          aplicados: 1,
          pendientes: 0,
          errados: 0
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result.complete) {
          this.rows = [];
        };
      });
    }

    verPendientes(){
      const dialogRef = this.dialog.open(DetallesPagosTable, {
        data: {
          numeroProceso: this.filtro.idProceso,
          aplicados: 0,
          pendientes: 1,
          errados: 0
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result.complete) {
          this.rows = [];
        };
      });
    }

    verErrados(){
      const dialogRef = this.dialog.open(DetallesPagosTable, {
        data: {
          numeroProceso: this.filtro.idProceso,
          aplicados: 0,
          pendientes: 0,
          errados: 1
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result.complete) {
          this.rows = [];
        };
      });
    }

    onCabeceraProceso(proceso:any) {
        this.gestionAvanceProcesosService.getCabeceraProceso(proceso).subscribe((data: any) => {
          this._status = !!data.error;
          this.resultError = null;

          if (!this._status) {
            this.totalPagos = data[0].total;
            this.aplicados = data[0].aplicados;
            this.errados = data[0].errados;
            this.pendientes = data[0].pendientes;
            this.avance = data[0].avance;
            this.pagosSegundos = data[0].pagosegs;
            this.avancenum = ( (this.aplicados + this.errados) / this.totalPagos  )*100;

          if (this.aplicados == this.totalPagos || (this.aplicados + this.errados) == this.totalPagos) {
            clearInterval(this.idInterval);
            this.complete = true;

            let sheetRef = this._bottomSheet.open(AlertasConfirmComponent, {
              data: { tipoMensaje: 20 }
            });

            sheetRef.afterDismissed().subscribe(dataDismised => {
              if (dataDismised.mensaje === 'accept') {
                this.closeFinally();
              };
            })
            
          };

          } else {
            this.resultError = data.message;
          };

        });
    }

    onCalcularAvance(filtro:any) {
      this.gestionAvanceProcesosService.getAvanceProcesosAll(filtro).subscribe((data: any) => {

        this._status = !!data.error;
        this.resultError = null;

        if (!this._status) {
          if (data.rows[0].totalRegistros == 0) {
            clearInterval(this.idInterval);

            let sheetRef = this._bottomSheet.open(AlertasConfirmComponent, {
              data: { tipoMensaje: 17 }
            });

            sheetRef.afterDismissed().subscribe(dataDismised => {
              if (dataDismised.mensaje === 'accept') {
                this.onCloseDialog();
              };
            })
          };

        } else {
          this.resultError = data.message;
          clearInterval(this.idInterval);

          const sheetRef = this._bottomSheet.open(AlertasConfirmComponent, {
            data: { tipoMensaje: 20 }
          });

          sheetRef.afterDismissed().subscribe(dataDismised => {
            if (dataDismised.mensaje === 'accept') {
              this.onCloseDialog();
            };
          })
        };

      });
    }

    onConsultarLog(codigoProceso:any) {

        this.filtro.idProceso = codigoProceso;

        // If the user changes the sort order, reset back to the first page.
        this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

        merge(this.sort.sortChange, this.paginator.page)
          .pipe(
            startWith({}),
            switchMap(() => {
              let sortExpr = '';
              if (this.sort.active) {
                sortExpr = `${this.sort.active} ${this.sort.direction}`;
              }
              return this.gestionAvanceProcesosService.getConsultaLogAvanceProcesosList(
                    this.filtro,
                    this.filter,
                    this.paginator,
                    this.sort);
            }),
            map((data: any) => {
              // Flip flag to show that loading has finished.
              this.isRateLimitReached = false;
              this.resultsLength = data.count;
              return data.rows;
            }),
            catchError(() => {
              // Catch if the API has reached its rate limit. Return empty data.
              this.isRateLimitReached = true;
              return observableOf([]);
            })
          ).subscribe((data: any) => {
            this.rows = [...data];
          });
    }

    closeFinally(){
      this.dialogRef.close({
        complete: this.complete
      });
    }

    onCloseDialog() {

      clearInterval(this.idInterval);

      const sheetRef = this._bottomSheet.open(AlertasConfirmComponent, {
        data: { tipoMensaje: 137 }
      });
      sheetRef.afterDismissed().subscribe(dataDismised => {
        if (dataDismised.mensaje === 'accept') {
          //this.onCloseDialog();
          this.dialogRef.close({
            complete: this.complete
          });
        }
      })
    }

    onRefresh() {
      this.filter.column = '';
      this.filter.value = '';
      this.filter.condition = '';

      // this.onConsultarLog();
    }

    onChangePage() {
      //this.onConsultarLog();
    }

    onSelect(event: Event, row: ProcesosMultiplesReloadedModel, index: number) {
        this.selectedRow = row;
        this.selectedIndex = index;
    }

    onSelectColumn(name: string, columnType: string, dbName: string, sign: string) {
      this.selectedColumn.name = name;
      this.selectedColumn.dbName = dbName;
      this.selectedColumn.type = columnType;
      this.selectedColumn.sign = sign;
      this.selectedColumn.filter = (columnType === 'number') ? CONDITIONS_LIST_NUMBER : CONDITIONS_LIST;
    }

    onSelectAndEdit(event, row: ProcesosMultiplesReloadedModel, index: number) {
      this.selectedRow = row;
      this.selectedRow._estado = 'O';
      this.selectedIndex = index;

      this.openDialog();
    }

    onApplyFilter(e: Event) {
      this.filter.column = this.selectedColumn.dbName;
      this.filter.condition = this.searchCondition[this.selectedColumn.name];
      this.filter.value = this.searchValue[this.selectedColumn.name];

      let evt = new PageEvent();
      evt.pageIndex = 0;
      this.paginator.page.emit(evt);
    }

    onClearColumn(e: Event) {
      this.searchValue[this.selectedColumn.name] = '';
      this.searchCondition[this.selectedColumn.name] = '';
      this.onRefresh();
    }

    onExportCsv(e: Event) { }

    onTotals(data: any) {
      Object.assign(this.selectedRow, data);
    }

    openDialog(): void {
      const dialogRef = this.dialog.open(GestionAvanceProcesosMultiplesDialog, {
        data: {
          selected: this.selectedRow
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          Object.assign(this.selectedRow, result.data);
          switch (this.selectedRow._estado )
          {
            case 'N':
              this.selectedRow._estado = 'O';
              this.selectedIndex = this.rows.length;
              this.rows.push(this.selectedRow);
              this.rows = [...this.rows];
              this.resultsLength = this.rows.length;
              break;
            case 'D':
              this.rows.splice(this.selectedIndex, 1);
              this.rows = [...this.rows];
              this.selectedIndex = -1;
              this.selectedRow = null;
              break;
          }
        }
      });
    }

    openNotificationDanger(message: string, action?: string) {
      this._snackBar.open(message, action, {
          duration: 2000,
          panelClass: 'dangerSnackBar',
      });
    }

    getUniqueListBy(arr, key) {
      return [...new Map(arr.map(item => [item[key], item])).values()]
    }

}
