import { Component, ViewChild, AfterViewInit, Input, Output, EventEmitter, ElementRef, Inject } from '@angular/core';
import { MatPaginator, MatSort, PageEvent } from '@angular/material';
import { merge, Observable, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar, MatBottomSheet, MatDialog } from '@angular/material';
import { DocumentoRegeneradoCarteraService } from './documentoregeneradocartera.service';
import Utils from './../../../services/utilidades';
import * as PB from 'src/app/components/powerbuilder.functions';
import { DocumentoRegeneradoCarteraVisual } from './tipodocumentoregeneradocartera.visual'
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { DocumentosDownDialog } from '../documentodesembolso/viewdoctos.dialog';
import { AwsComponent } from 'src/app/components/aws.component';
declare var CONDITIONS_LIST: any;
declare var CONDITIONS_LIST_NUMBER: any;
declare var appComponent: any;

@Component({
	selector: 'documentoregeneradocartera-table',
	templateUrl: './documentoregeneradocartera.table.html',
	styleUrls: ['./documentoregeneradocartera.table.css'],
	providers: [DocumentoRegeneradoCarteraService]
})
export class DocumentoRegeneradoCarteraTable implements AfterViewInit {
	PBF = PB;

	DwMotivoBloqueo: any[] = [];
	DwEnteRegula: any[] = [];
	rows: any[] = [];
	originals: any[] = [];
	DwDocEstaticos: any[] = [];
	selectedRow: any ={};
	selectedIndex: number = 0;
	originalRow: any;
  numeroProceso: string = 'Sin Asociar';
  lineaCredito: any;

	public displayedColumns: string[] = ['nro_lote','cod_plantilla', 'documento', 'envio_correo','fecha_creacion', 'usuario_creacion'];

	public conditionsList = CONDITIONS_LIST;
	public conditionsListNumber = CONDITIONS_LIST_NUMBER;
	public searchValue: any = {};
	public searchCondition: any = {};
	public _pageSize = 10;

	filter = {
		column: '',
		condition: '',
		value: ''
	};

	selectedColumn = {
		name: '',
		dbName: '',
		type: '',
		sign: '',
		filter: []
	};

	resultsLength = 0;
	isLoadingResults = true;
	isRateLimitReached = false;

	@ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
	@ViewChild(MatSort, { static: false }) sort: MatSort;
	@BlockUI() blockUI: NgBlockUI;

	_proc: boolean = false;
	_status: boolean = false;
	resultError: string = null;
	title: string = '';
	codigoPagaduria: number;
  nroLote: number;
	codigoCarpeta: string;
	correoCliente: any = {};
  principal: boolean = false;
  volver: boolean = false;
  despachos: boolean = false;
  consultaRegenerado: boolean =  false;

	constructor(public dialog: MatDialog,
		private _snackBar: MatSnackBar,
		private _bottomSheet: MatBottomSheet,
		private documentoDesembolsoService: DocumentoRegeneradoCarteraService,
    private awsComponent: AwsComponent,
		public dialogRef: MatDialogRef<DocumentoRegeneradoCarteraTable>,
		@Inject(MAT_DIALOG_DATA) public data: any) {
		this.codigoCarpeta = data.selected ? data.selected.codigoCarpeta : data.nroCredito;
    this.lineaCredito = data.lineaCredito;
    this.principal = !data.selected;
    if(this.principal){
      this.despachos = data.despachos;
      this.volver = true;
    }

		this.dialogRef.disableClose = true;
	}

	ngAfterViewInit() {
    this.controlConsultaRegenerados();
		// If the user changes the sort order, reset back to the first page.
		this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

		merge(this.sort.sortChange, this.paginator.page)
			.pipe(
				startWith({}),
				switchMap(() => {
					this.isLoadingResults = true;
					let sortExpr = '';
					if (this.sort.active) {
						sortExpr = `${this.sort.active} ${this.sort.direction}`;
					}
					return this.documentoDesembolsoService.getDocumentoRegeneradoCarteraList(
						this.filter,
						this.paginator,
						this.sort,
            this.codigoCarpeta,
            this.principal);
				}),
				map((data: any) => {
					// Flip flag to show that loading has finished.
					this.isLoadingResults = false;
					this.isRateLimitReached = false;
					this.resultsLength = data.count;
					return data.rows;
				}),
				catchError(() => {
					this.isLoadingResults = false;
					// Catch if the API has reached its rate limit. Return empty data.
					this.isRateLimitReached = true;
					return observableOf([]);
				})
			).subscribe((data: any) => {
        this.rows = [...data];
        if(this.principal){
          this.documentoDesembolsoService.cartaFiniquito(this.codigoCarpeta).subscribe(data => {
            this._status = !!data.error;
            this.resultError = null;
            if (!this._status && data.message != "Cannot read properties of null (reading 'status')") {
                let rowsCopy = this.rows;
                rowsCopy.push(data);
                this.resultsLength = this.resultsLength+1;
                this.rows = [...rowsCopy];
            }
          });
        }
				this.originals = data;
				this.correo();
			});
	}

	onAdd(): void {
		this.selectedRow =  {};
		this.originalRow = {};
		this.selectedIndex = 0;

		this.openDialog();
	}

	onEdit(): void {
		if (this.selectedIndex >= 0) {
			this.selectedRow._estado = 'O';
			this.openDialog();
		}
	}

	onRefresh() {
		this.filter.column = '';
		this.filter.value = '';
		this.filter.condition = '';

		this.ngAfterViewInit();
	}

	onChangePage() {
		this.ngAfterViewInit();
	}

	onSelect(event: Event, row: any, index: number) {
		this.selectedRow = row;
		this.selectedIndex = index;
		this.originalRow = this.originals[index];
	}

	onSelectAndEdit(event, row: any, index: number) {
		this.selectedRow = row;
		this.selectedRow._estado = 'O';
		this.selectedIndex = index;
		this.originalRow = this.originals[index];
		this.originalRow._estado = 'O';

		this.openDialog();
	}

	onSelectColumn(e: Event, name: string, columnType: string, dbName: string, sign: string) {
		this.selectedColumn.name = name;
		this.selectedColumn.dbName = dbName;
		this.selectedColumn.type = columnType;
		this.selectedColumn.sign = sign;
		this.selectedColumn.filter = (columnType === 'number') ? CONDITIONS_LIST_NUMBER : CONDITIONS_LIST;
	}

	onApplyFilter(e: Event) {
		this.filter.column = this.selectedColumn.dbName;
		this.filter.condition = this.searchCondition[this.selectedColumn.name];
		this.filter.value = this.searchValue[this.selectedColumn.name];

		let evt = new PageEvent();
		evt.pageIndex = 0;
		this.paginator.page.emit(evt);
	}

	onClearColumn(e: Event) {
		this.searchValue[this.selectedColumn.name] = '';
		this.searchCondition[this.selectedColumn.name] = '';
		this.onRefresh();
	}

	onTotals(data: any) {
		Object.assign(this.selectedRow, data);
	}

	openDialog(): void {

	}

	openNotificationDanger(message: string, action?: string) {
		this._snackBar.open(message, action, {
			duration: 2000,
			panelClass: 'dangerSnackBar',
		});
	}

	getChildDwMotivoBloqueo(row: any) {
		//let d = this.DwMotivoBloqueo.find(val => val.secuencia === id) || {};

		if(row.anexo == 0){
			let d = this.DwMotivoBloqueo.find(val => val.secuencia === row.codPlantilla) || {};
			return d.nombre || '';
		}else{
			let d = this.DwDocEstaticos.find(val => val.secuencia === row.codPlantilla) || {};
			return d.nombreAnexo || '';
		}
	}

	getChildDwEnteRegula(id: number) {
		let d = this.DwEnteRegula.find(val => val.codigoPagaduria === id) || {};
		return d.nombrePagaduria || '';
	}

	openNotificationSuccess(message: string, action?: string) {
        this._snackBar.open(message, action, {
            duration: 2000,
            panelClass: 'successSnackBar',
        });
        this.dialogRef.close();
	}

	onTitle(trans: any) {
		this.title = trans
	}

	onExport() {
		Utils.onExport(`${this.title}`, this.originals, [], this._bottomSheet);
	}

	viewPdf(row: any) {
		this.dialog.open(DocumentoRegeneradoCarteraVisual, {
			data: {
				selected: row
			}
		});
	}

	sendMail() {
		this.documentoDesembolsoService.envioCorreoDesembolso(Number(this.codigoCarpeta)).subscribe(resp => {
			this._proc = false;
			this._status = !!resp.error;
			this.resultError = null;

			if (!this._status) {
				this.openNotificationSuccess('Documentos enviados');
			} else {
				this.resultError = resp.message.mensaje || resp.message;
				this.openNotificationDanger(this.resultError);
			};
		});
	}

	correo() {
		this.documentoDesembolsoService.correoDesembolso(Number(this.codigoCarpeta)).subscribe(
			(resp: any) => {
				this.correoCliente = resp;
			}
		);
	}

  enviarCorreo(row: any){

    let obj: any = {};
    obj.nombreFormato = row.nombre;
    obj.nroCredito = this.codigoCarpeta;
    obj.numeroProceso = this.numeroProceso;
    obj.rutaProceso = row.rutaAws;
    if (Object.keys(this.correoCliente).length == 0) {
      this.openNotificationDanger("Aviso!, correo no valido.");

    } else {
    obj.correo = this.correoCliente.correo;
      this.documentoDesembolsoService.envioEstadoDesembolso(obj).subscribe(
      (resp: any) => {
        this._proc = false;
        this._status = !!resp.error;
        this.resultError = null;

        if (!this._status) {
          this.openNotificationSuccess('Correo enviado correctamente');
        } else {
          this.resultError = resp.message.mensaje;
          this.openNotificationDanger(this.resultError)
		    };

      }
    );
   }
  }
  viewFiles() {
		let data = {
			codigoCarpeta: this.codigoCarpeta,
			lineaCredito: this.lineaCredito,
      despachos: this.despachos
		}
		this.dialog.open(DocumentosDownDialog, {
			data: data
		});
	}

  controlConsultaRegenerados() {
    this.documentoDesembolsoService.getPasos('w_regeneracion_documentos_wfc', 'ue_consultar').subscribe(data => {
      if (data.rows.length > 0) {
        this.consultaRegenerado = true;
      }
    });
  }

  volverConsulta(){
    this.principal = false;
    this.ngAfterViewInit();
  }
  volverPrincipal(){
    this.principal = true;
    this.ngAfterViewInit();
  }

  onConsultaPld() {
    if (!this._proc) {
      this._proc = true
      let procesoPld = `dialogo-procesos.cargue.workflow.consultapld.${this.codigoCarpeta}.input`;
      this.documentoDesembolsoService.consultaLogPdl(procesoPld).subscribe(data => {
        this._status = !!data.error;
        this.resultError = null;

        if (!this._status) {
          if (data.count > 0) {
            this.onDescargarPld(data.rows[0]);
          } else {
            this.openNotificationDanger('No hay documentos en el log de consulta pld para descargar.');
          };

        } else {
          this._proc = false
          this.resultError = data.message.mensaje;
          this.openNotificationDanger(this.resultError);
        };

      })
    };
  }

  onDescargarPld(row: any){
    let proceso = `dialogo-procesos.cargue.workflow.consultapld.${this.codigoCarpeta}.input`;
    let urlRuta = `cargue/workflow/consultapld/${this.codigoCarpeta}/input/`;

    let archivo = row.ruta.replace(urlRuta, "") ;
    this.awsComponent.downloadFile(proceso, archivo)
      .subscribe((data: any) => {
      this.awsComponent.download_file(data.url).subscribe((file: Blob) => {
        var url = window.URL.createObjectURL(file);
        var anchor = document.createElement("a");
        anchor.download = row.nombreArchivo;
        anchor.href = url;
        anchor.click();
        this._proc = false;
      });
    });
    }

}
